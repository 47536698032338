/** @jsx jsx */
import { transparentize } from '@theme-ui/color';
import { jsx } from 'theme-ui';

const Square = ({ active, interactive, width, children, ...rest }) => (
  <div
    sx={{
      bg: interactive
        ? active
          ? 'white'
          : transparentize('white', 0.75)
        : 'white',
      borderRadius: 6,
      borderColor: interactive ? (active ? 'primary' : 'muted') : 'muted',
      borderWidth: 2,
      borderStyle: 'solid',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      transition: 'all 400ms',
      '&:hover': {
        bg: transparentize('white', 0.15),
        borderColor: transparentize('secondary', 0.25),
        transition: 'all 400ms',
      },
      width: width,
      maxHeight: width,
    }}
    {...rest}
  >
    {children}
  </div>
);

export default Square;
