/** @jsx jsx */
import { jsx } from 'theme-ui';
import React, { useState } from 'react';

import { Modal, ModalTitle, ModalContent, ModalFooter } from './';

import TemplateGallery from '../Gallery/TemplateGallery';
import templates from '../Gallery/templates';
import { SheetSizeOptions } from '../../../constants';
import { Select } from '../Input';
import { userSuggestTemplateState } from '../../recoil';
import { useRecoilState, useSetRecoilState } from 'recoil';

export const TemplateModal = (props) => {
  const [selectedItem, setSelectedItem] = useState(templates[0]);
  const [sheetSize, setSheetSize] = useState(SheetSizeOptions.LETTER);
  return (
    <Modal
      open={props.open}
      onClose={() => props.setOpen && props.setOpen(false)}
    >
      {({ onClose }) => (
        <>
          <ModalTitle title="Select a Template">
            <Select
              options={SheetSizeOptions}
              value={sheetSize}
              onChange={(e) => {
                setSheetSize(e.target.value);
              }}
            >
              Select Source Paper Size
            </Select>
          </ModalTitle>

          <ModalContent>
            <TemplateGallery
              sheetSize={sheetSize}
              onSelectItem={setSelectedItem}
              selectedItem={selectedItem}
            ></TemplateGallery>
          </ModalContent>
          <ModalFooter
            onClose={onClose}
            onCommit={() => {
              props.onSelectItem(selectedItem);
              onClose();
            }}
          ></ModalFooter>
        </>
      )}
    </Modal>
  );
};

const TemplateDialog = (props) => {
  const [userSuggestTemplate, setUserSuggestTemplate] = useRecoilState(
    userSuggestTemplateState
  );
  return (
    <>
      <div onClick={() => setUserSuggestTemplate(true)}>{props.children}</div>
      <TemplateModal
        open={userSuggestTemplate}
        setOpen={setUserSuggestTemplate}
        {...props}
      ></TemplateModal>
    </>
  );
};

export default TemplateDialog;
