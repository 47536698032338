import React from 'react';
import styled from 'styled-components';

const Helmet = (
  <link
    href="https://fonts.googleapis.com/css?family=Montserrat:500,700&display=swap"
    rel="stylesheet"
  />
);

const PageContent = styled.div`
  flex: auto;
  margin-top: ${(props) => props.padding}pt;
  margin-right: ${(props) =>
    props.isRotated
      ? props.pageNumberingEnabled
        ? props.padding * 2
        : props.padding
      : props.padding}pt;
  margin-bottom: ${(props) =>
    props.isRotated
      ? props.padding
      : props.pageNumberingEnabled
      ? props.padding * 2
      : props.padding}pt;
  margin-left: ${(props) => props.padding}pt;
`;

const PageNumber = styled.div`
  font-family: 'Montserrat', sans-serif;
  color: rgba(0, 0, 0, 0.6);
  font-size: 12pt;
  font-weight: 400;
  flex: 0 0;
  text-align: ${(props) => (props.hasAlternateAlign ? 'left' : 'right')};
  transform: ${(props) => (props.isRotated ? 'rotate(180deg)' : 'initial')};
`;

const StyledPageMeta = styled.div`
  font-family: 'Montserrat', sans-serif;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  padding: 16pt;
  flex-direction: column;
  justify-content: space-between;
`;

const PageMeta = ({
  pageTitle,
  pageNumber,
  padding,
  isRotated,
  hasAlternateAlign,
  pageNumberingEnabled,
}) => (
  <StyledPageMeta>
    {pageTitle}
    {pageNumberingEnabled && (
      <PageNumber
        padding={padding}
        isRotated={isRotated}
        hasAlternateAlign={hasAlternateAlign}
      >
        {pageNumber}
      </PageNumber>
    )}
  </StyledPageMeta>
);

export const Components = {
  Helmet,
  PageMeta,
  PageContent,
};

export default Components;
