import React from 'react';
import Gallery from '.';
import { BindingModes, SheetSizeDimensions } from '../../../constants';
import { filterByOptions } from '../../../utils/notebook';
import { calculatePageSize } from '../../../utils/page';
import { sizeDescription } from '../../../utils/thumbnail';
import Thumbnail from '../Thumbnail';
import GalleryItem from './GalleryItem';

const ThumbnailGallery = ({
  sheetSize,
  pagesPerSheet,
  pageOrientation,
  cutOrientation,
  selectedItem,
  onSelectItem,
  itemWidth = 260,
}) => {
  let items = filterByOptions(
    sheetSize,
    pagesPerSheet,
    pageOrientation,
    cutOrientation
  );

  return (
    <Gallery>
      {items.map((item, index) => {
        let isSelected =
          selectedItem &&
          item.sheetSize === selectedItem.sheetSize &&
          item.pageOrientation === selectedItem.pageOrientation &&
          item.pagesPerSheet === selectedItem.pagesPerSheet &&
          item.cutOrientation === selectedItem.cutOrientation;

        let dims = calculatePageSize(
          item.sheetSize,
          item.pageOrientation,
          item.pagesPerSheet,
          item.cutOrientation
        );

        return (
          <GalleryItem
            key={index}
            title={sizeDescription(item)}
            description={`${dims.width} \u00D7 ${dims.height} ${dims.units}`}
            width={itemWidth}
            onClick={() => (onSelectItem ? onSelectItem(item) : null)}
            selected={isSelected}
          >
            <Thumbnail
              bindingMode={BindingModes.NONE}
              sheetSize={item.sheetSize}
              pagesPerSheet={item.pagesPerSheet}
              pageOrientation={item.pageOrientation}
              bindingEdge={item.bindingEdge}
              cutOrientation={item.cutOrientation}
              active={isSelected}
            ></Thumbnail>
          </GalleryItem>
        );
      })}
    </Gallery>
  );
};

export default ThumbnailGallery;
