import React from 'react';
import { BindingModes } from '../../../../constants';

function ThumbnailLegalHalfPortrait({
  bindingMode,
  color = '#000',
  colorOpacity = '0.02',
  bindingColor = '#F25546',
  strokeColorOpacity = '0.2',
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="321"
      height="322"
      viewBox="0 0 321 322"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g transform="translate(107.5 96.5)">
          <path
            fill={color}
            fillOpacity={colorOpacity}
            stroke={color}
            strokeOpacity={strokeColorOpacity}
            strokeWidth="2"
            d="M106 0L106 129 0 129 0 0z"
          ></path>
          <g fill={bindingColor} transform="translate(-8)">
            {bindingMode === BindingModes.SIGNATURE && (
              <g>
                <path d="M8 11.286H16V13.286H8z"></path>
                <path d="M8 88.071H16V90.071H8z"></path>
                <path d="M8 25.107H16V27.107H8z"></path>
                <path d="M8 101.893H16V103.893H8z"></path>
                <path d="M8 38.929H16V40.929H8z"></path>
                <path d="M8 115.714H16V117.714H8z"></path>
              </g>
            )}
            {bindingMode === BindingModes.DISC && (
              <g>
                <rect width="16" height="4" x="0" y="85.536" rx="2"></rect>
                <rect width="16" height="4" x="0" y="70.179" rx="2"></rect>
                <rect width="16" height="4" x="0" y="54.821" rx="2"></rect>
                <rect width="16" height="4" x="0" y="39.464" rx="2"></rect>
                <rect width="16" height="4" x="0" y="24.107" rx="2"></rect>
                <rect width="16" height="4" x="0" y="116.25" rx="2"></rect>
                <rect width="16" height="4" x="0" y="100.893" rx="2"></rect>
                <rect width="16" height="4" x="0" y="8.75" rx="2"></rect>
              </g>
            )}
            {bindingMode === BindingModes.JAPANESE && (
              <g>
                <path d="M8 9.75H16V11.75H8z"></path>
                <path d="M8 45.071H16V47.071H8z"></path>
                <path d="M8 80.393H16V82.393H8z"></path>
                <path d="M8 115.714H16V117.714H8z"></path>
                <path d="M13.857 0H15.857V129H13.857z"></path>
              </g>
            )}
            {bindingMode === BindingModes.SADDLE && (
              <g>
                <path d="M7.071 3.071H10.071V125.928H7.071z"></path>
              </g>
            )}
            {bindingMode === BindingModes.SPIRAL && (
              <g>
                <path d="M4.571 124.929H11.428V126.929H4.571z"></path>
                <path d="M4.571 118.786H11.428V120.786H4.571z"></path>
                <path d="M4.571 112.643H11.428V114.643H4.571z"></path>
                <path d="M4.571 106.5H11.428V108.5H4.571z"></path>
                <path d="M4.571 100.357H11.428V102.357H4.571z"></path>
                <path d="M4.571 94.214H11.428V96.214H4.571z"></path>
                <path d="M4.571 88.071H11.428V90.071H4.571z"></path>
                <path d="M4.571 81.929H11.428V83.929H4.571z"></path>
                <path d="M4.571 75.786H11.428V77.786H4.571z"></path>
                <path d="M4.571 69.643H11.428V71.643H4.571z"></path>
                <path d="M4.571 63.5H11.428V65.5H4.571z"></path>
                <path d="M4.571 57.357H11.428V59.357H4.571z"></path>
                <path d="M4.571 51.214H11.428V53.214H4.571z"></path>
                <path d="M4.571 45.071H11.428V47.071H4.571z"></path>
                <path d="M4.571 38.929H11.428V40.929H4.571z"></path>
                <path d="M4.571 32.786H11.428V34.786H4.571z"></path>
                <path d="M4.571 26.643H11.428V28.643H4.571z"></path>
                <path d="M4.571 20.5H11.428V22.5H4.571z"></path>
                <path d="M4.571 14.357H11.428V16.357H4.571z"></path>
                <path d="M4.571 8.214H11.428V10.214H4.571z"></path>
                <path d="M4.571 2.071H11.428V4.071H4.571z"></path>
              </g>
            )}
          </g>
        </g>
      </g>
    </svg>
  );
}

export default ThumbnailLegalHalfPortrait;
