/** @jsx jsx */
import { jsx, Styled } from 'theme-ui';
import React from 'react';

import { Input } from 'theme-ui';

const Field = ({
  name,
  type,
  value,
  disabled,
  defaultValue,
  onChange,
  onBlur,
  hint,
  ...rest
}) => (
  <>
    <Input
      defaultValue={defaultValue}
      type={type}
      name={name}
      value={value}
      disabled={disabled}
      onBlur={onBlur}
      onChange={onChange}
      {...rest}
    />
    <aside
      sx={{
        variant: 'text.hint',
      }}
    >
      {hint}
    </aside>
  </>
);

export default Field;
