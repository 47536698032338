/** @jsx jsx */
import { jsx, Styled } from 'theme-ui';
import React from 'react';

import { darken } from '@theme-ui/color';

export default ({ title, children }) => (
  <div
    sx={{
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      justifyContent: 'stretch',
    }}
  >
    <Styled.h1
      sx={{
        bg: 'darkGreen',
        color: 'white',
        fontSize: ['1rem', '1rem', '1rem', '1rem'],
        px: '1rem',
        py: '0.75rem',
        m: 0,
        flexGrow: '0',
      }}
    >
      {title}
    </Styled.h1>
    <div
      sx={{
        maxWidth: '250px',
        bg: darken('highlight', 0.06),
        px: '1rem',
        py: '2rem',
        flex: '1',
        overflow: 'auto',
        overscrollBehavior: 'contain',
      }}
    >
      {children}
    </div>
  </div>
);
