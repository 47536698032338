import {
  SystemBindingModes,
  PatternOptions,
  SystemBindingModeMap,
} from '../constants';

const getSystemBindingMode = (bindingMode) => SystemBindingModeMap[bindingMode];

// TODO: make this an object instead :()
const getBasicBookDescriptorObj = (
  pattern,
  isCustomPattern,
  color,
  hasFlyleaf,
  pageCount,
  bindingMode,
  // pagesPerSheet, // TODO: determine whether this is needed for BindingMode == NONE

  opacity
) => {
  const bindingModeSystem = getSystemBindingMode(bindingMode);

  if (bindingModeSystem === SystemBindingModes.NONE) {
    return [
      {
        count: 1, // pagesPerSheet,
        pattern,
        customPattern: false,
        color,
        opacity,
      },
    ];
  }

  return hasFlyleaf
    ? [
        {
          count: bindingModeSystem === SystemBindingModes.DISC ? 1 : 2,
          pattern: PatternOptions.NONE,
        },
        {
          count:
            pageCount - (bindingModeSystem === SystemBindingModes.DISC ? 2 : 4),
          pattern: pattern,
          customPattern: isCustomPattern,
          color: color,
          opacity,
        },
        {
          count: bindingModeSystem === SystemBindingModes.DISC ? 1 : 2,
          pattern: PatternOptions.NONE,
        },
      ]
    : [
        {
          count: pageCount,
          pattern: pattern,
          customPattern: isCustomPattern,
          color: color,
          opacity,
        },
      ];
};

export { getBasicBookDescriptorObj, getSystemBindingMode };
