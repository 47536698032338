/** @jsx jsx */
import { jsx, Styled } from 'theme-ui';
import React from 'react';

const InputContainer = ({ title, children }) => (
  <div
    sx={{
      display: 'block',
      mb: '1.5rem',
    }}
  >
    <Styled.h3
      sx={{
        color: 'primary',
        fontSize: ['1rem', '1rem', '1rem', '1rem'],
      }}
    >
      {title}
    </Styled.h3>

    {children}
  </div>
);

export default InputContainer;
