import React from 'react';
import Gallery from '.';
import {
  PatternDescriptions,
  PatternDetails,
  PatternOptions,
} from '../../../constants';
import PatternTile from '../Sidebar/PatternTile';
import GalleryItem from './GalleryItem';

const PatternGallery = ({
  color,
  itemWidth = 260,
  magnification = 200,
  onSelectItem,
  selectedItem,
}) => {
  return (
    <Gallery>
      {Object.keys(PatternOptions).map((key, index) => {
        let pattern = PatternOptions[key];

        let isSelected = selectedItem && pattern === selectedItem.pattern;

        return (
          <GalleryItem
            key={index}
            title={`${PatternDescriptions[pattern]}`}
            description={`${PatternDetails[pattern]}`}
            width={itemWidth}
            onClick={() =>
              onSelectItem ? onSelectItem({ pattern, color }) : null
            }
            selected={isSelected}
          >
            <PatternTile
              color={color}
              pattern={pattern}
              magnification={magnification}
            ></PatternTile>
          </GalleryItem>
        );
      })}
    </Gallery>
  );
};

export default PatternGallery;
