/** @jsx jsx */
import { Button, jsx, Styled, ThemeProvider } from 'theme-ui';
import React from 'react';

import { ReactComponent as Hero } from './images/notebooks.svg';
import { ReactComponent as HeroMobile } from './images/notebooks-square.svg';

import { ReactComponent as InstructFold } from './images/instruct-fold.svg';
import { ReactComponent as InstructLoose } from './images/instruct-loose.svg';
import { ReactComponent as InstructCut } from './images/instruct-cut.svg';
import { ReactComponent as InstructFoldSignature } from './images/instruct-fold-signature.svg';

import { ReactComponent as Logo } from './images/logo.svg';

import ogImage from './images/og-image.png';

import Seo from './Seo';
import SubscribeForm from './SubscribeForm';
import Pattern from '../components/PatternFactory';
import { PatternOptions } from '../constants';
import { Link } from 'react-router-dom';

import theme from '../theme-ui/landing';

const SectionTextContainer = ({ isFull, rightAlign, children }) => {
  const base = {
    margin: '0 auto',
    display: 'flex',
    alignItems: ['initial', 'initial', 'center'],
    justifyContent: 'center',
  };
  const container = {
    ...base,
    gridColumn: rightAlign ? 'right' : 'left',
    textAlign: 'left',
  };

  const fullContainer = {
    ...base,
    gridColumn: 'left / right-end',
    textAlign: 'center',
    alignItems: 'flex-start',
    flexDirection: 'column',
  };

  return <div sx={isFull ? fullContainer : container}>{children}</div>;
};

const LandingPageSection = ({ sxx, children }) => {
  return (
    <section
      sx={{
        minHeight: ['initial', 'initial', 'initial', 'initial'],
        p: ['1.25rem', '1.25rem', 0],
        pt: ['10rem', '10rem', '10rem', '10rem'],
        display: ['flex', null, 'grid'],
        gridTemplateColumns:
          '[left] calc(12%*3) [left-end] 1.5% [left-margin] calc(12.5%*2) [right-margin] 1.5% [right] calc(12%*3) [right-end]',
        ...sxx,
      }}
    >
      {children}
    </section>
  );
};

const PatternBorder = ({ children }) => (
  <div
    sx={{
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: 'lightgray',
      background: 'white',
      borderRadius: 9999,
      height: 160,
      width: 160,
      svg: {
        clipPath: 'polygon(50% 0, 100% 50%, 50% 100%, 0 50%);',
        transform: 'scale(2)',
        width: 160,
        height: 160,
      },
    }}
  >
    {children}
  </div>
);

const swatchWidths = [200, 200, 140, 200];

const LandingPage = ({}) => (
  <ThemeProvider theme={theme}>
    <div
      sx={{
        '*::-moz-selection': {
          background: 'var(--theme-ui-colors-green,#0e9594)',
          color: 'white',
        },
        '*::selection': {
          background: 'var(--theme-ui-colors-green,#0e9594)',
          color: 'white',
        },
      }}
    >
      <Seo title="Folio Forge" image={ogImage} />
      <div
        sx={{
          margin: ['0 auto'],
          pb: ['12rem'],
          maxWidth: [null, '40em', '60em', '80em'],
          svg: {
            height: '100%',
            width: '100%',
          },
        }}
      >
        <LandingPageSection sxx={{ flexDirection: 'column', mt: [0, '1em'] }}>
          <SectionTextContainer isFull={true}>
            <div>
              <div
                sx={{
                  mt: '4rem',
                  svg: { height: swatchWidths, width: swatchWidths },
                }}
              >
                <Logo />
              </div>
              <Styled.h1
                sx={{
                  color: 'darkGreen',
                  fontSize: ['36px', '48px', '56px', '64px'],
                  mt: ['1em', null, '0.75em', null],
                }}
              >
                Folio Forge
              </Styled.h1>
              <div
                sx={{
                  color: 'text',
                  variant: 'styles.h2',
                  fontWeight: 300,
                  mt: '1rem',
                }}
              >
                <div>Create custom, page-numbered notebook paper.</div>
                <div>Print, and it's ready to bind.</div>
              </div>

              <div
                sx={{
                  mt: '2rem',
                }}
              >
                <Link to="/app">
                  <Button variant="primary">Launch Web App (BETA)</Button>
                </Link>
                <aside
                  sx={{
                    mt: '1rem',
                    lineHeight: '1.25',
                  }}
                >
                  Requires desktop or laptop. <br></br> Chrome highly
                  recommended.
                </aside>
              </div>
            </div>
          </SectionTextContainer>
        </LandingPageSection>

        <LandingPageSection>
          <Hero
            sx={{
              display: ['none', 'none', 'block', 'block'],
              gridColumn: 'left / right-end',
            }}
          ></Hero>
          <HeroMobile sx={{ display: ['block', 'block', 'none', 'none'] }} />
        </LandingPageSection>

        <LandingPageSection
          sxx={{ flexDirection: ['column', 'column', null, null] }}
        >
          <SectionTextContainer isFull={true}>
            <div
              sx={{
                width: '100%',
                marginBottom: '2em',
              }}
            >
              <Styled.h2 sx={{ color: 'darkRed' }}>
                Fully Customizable
              </Styled.h2>
              <Styled.p>Choose from a growing library of patterns.</Styled.p>
            </div>

            <div
              sx={{
                // height: ['initial', 'initial', '50vh'],
                // alignSelf: ['initial', 'initial', 'center'],
                display: ['initial', 'flex', 'flex'],
                gridColumn: ['initial', 'initial', 'left / right-end'],
                gridRow: '1',
                flexWrap: ['initial', 'wrap', 'wrap'],
                justifyContent: 'space-around',
                width: ['100%'],
                figure: {
                  maxWidth: ['initial', '50vw'],

                  mt: ['1rem', '1rem', 'initial', 'initial'],
                  ml: ['auto', 'auto', 'initial', 'initial'],
                  mr: ['auto', 'auto', '2rem', '2rem'],
                  mb: ['3rem', '3rem', '3rem', '3rem'],
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                },
                figcaption: {
                  variant: 'styles.h3',
                  mb: ['1em'],
                  maxWidth: swatchWidths,
                  textAlign: 'center',
                },
                svg: {
                  height: '100px',
                  width: '100px',
                  transform: 'scale(2)',
                },
              }}
            >
              <figure>
                <figcaption>General use</figcaption>
                <Pattern
                  name={PatternOptions.GRID_EUROPEAN}
                  width={swatchWidths}
                ></Pattern>
              </figure>
              <figure>
                <figcaption>Isometric drawing</figcaption>
                <Pattern
                  name={PatternOptions.LINE_ISOMETRIC}
                  width={swatchWidths}
                />
              </figure>
              <figure>
                <figcaption>Interface design</figcaption>
                <Pattern
                  name={PatternOptions.GRID_SKETCHER}
                  width={swatchWidths}
                />
              </figure>
              <figure>
                <figcaption>Writing</figcaption>
                <Pattern name={PatternOptions.LINE} width={swatchWidths} />
              </figure>
              <figure>
                <figcaption>Graphing</figcaption>
                <Pattern
                  name={PatternOptions.LINE_GRAPH}
                  width={swatchWidths}
                />
              </figure>
            </div>
          </SectionTextContainer>
        </LandingPageSection>

        <LandingPageSection
          sxx={{ flexDirection: ['column', 'column', null, null] }}
        >
          <SectionTextContainer>
            <div>
              <Styled.h2 sx={{ color: 'red' }}>Not just filler</Styled.h2>
              <Styled.p>
                Folio Forge makes your workflow easy: just print and bind.
              </Styled.p>
              <Styled.h3>Supports variety of binding methods</Styled.h3>
              <Styled.ul>
                <Styled.li>
                  <Styled.strong>Loose leaf</Styled.strong>: spiral, coil, wire,
                  perfect...
                </Styled.li>
                <Styled.li>
                  <Styled.strong>Signature</Styled.strong>: Coptic, secret
                  Belgian, French Styled.
                </Styled.li>
                <Styled.li>
                  <Styled.strong>Saddle stitch</Styled.strong>
                </Styled.li>
                <Styled.li>
                  Or, print patterns onto loose{' '}
                  <Styled.strong>unbound sheets</Styled.strong>!
                </Styled.li>
              </Styled.ul>
              <Styled.h3>Numbered pages done right</Styled.h3>
              <Styled.p>
                Numbered pages print in just the right order no matter your
                binding type.
              </Styled.p>
              <Styled.p>Can output 2-up or 1-up layouts.</Styled.p>
              <Styled.p>Supports using sheet sizes up to A4 or Legal.</Styled.p>
            </div>
          </SectionTextContainer>

          <div
            sx={{
              height: ['initial', 'initial', '50vh'],
              alignSelf: ['initial', 'initial', 'center'],
              display: ['initial', 'initial', 'flex'],
              gridColumn: ['initial', 'initial', 'left-end / right-end'],
              flexWrap: ['initial', 'initial', 'wrap'],
              justifyContent: 'space-around',
              figure: {
                maxWidth: ['initial', '50vw'],
                m: ['1rem auto 3rem', '1rem auto 3rem', 'initial', 'initial'],
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              },
              figcaption: {
                variant: 'styles.h4',
                color: 'darkGreen',
                mb: ['1em'],
              },
              svg: {
                height: 'initial',
              },
            }}
          >
            <figure>
              <figcaption>1-up loose leaf</figcaption>
              <InstructLoose />
            </figure>
            <figure>
              <figcaption>2-up loose leaf</figcaption>
              <InstructCut />
            </figure>
            <figure>
              <figcaption>Signature binding</figcaption>
              <InstructFoldSignature />
            </figure>
            <figure>
              <figcaption>Saddle stitch</figcaption>
              <InstructFold />
            </figure>
          </div>
        </LandingPageSection>

        <LandingPageSection>
          <SectionTextContainer isFull={true}>
            <div>
              <Styled.h2 sx={{ color: 'green' }}>
                Sign up to receive updates!
              </Styled.h2>
              <SubscribeForm></SubscribeForm>
            </div>
          </SectionTextContainer>
        </LandingPageSection>
      </div>
    </div>
  </ThemeProvider>
);

export default LandingPage;
