import {
  PagesPerSheet,
  BindingEdge,
  PageOrientation,
  BindingModes,
  SheetsPerSignature,
} from '../../../constants';

import { getSystemBindingMode } from '../../../utils/book';

function transform(arr, positionFn) {
  let result = Array.from({ length: arr.length }, (item, i) => null);

  arr.forEach((item, i) => {
    result[positionFn(i)] = item;
  });

  return result;
}

function setPageNumbers(placeholders, options) {
  const { pagesPerSheet, bindingEdge, pageOrientation } = options;

  switch (pagesPerSheet) {
    case PagesPerSheet.ONE:
      return bundle(placeholders, options);
    case PagesPerSheet.TWO:
      if (
        pageOrientation === PageOrientation.LANDSCAPE ||
        bindingEdge === BindingEdge.TOP
      ) {
        placeholders = transform(
          placeholders,
          (
            i // i => [0, 2, 4, 6, 1, 3, 5, 7][i]
          ) =>
            i < placeholders.length / 2
              ? i * 2
              : (i % (placeholders.length / 2)) * 2 + 1
        );
      } else if (
        bindingEdge === BindingEdge.LEFT ||
        bindingEdge === BindingEdge.RIGHT
      ) {
        placeholders = transform(
          placeholders,
          (
            i // i => [0, 2, 4, 6, 7, 5, 3, 1][i] // but will be transformed by bundle() to effectively i => [0, 3, 4, 7, 6, 5, 2, 1][i]
          ) =>
            i < placeholders.length / 2
              ? i * 2
              : placeholders.length - ((i % (placeholders.length / 2)) * 2 + 1)
        );
      }
      return bundle(placeholders, options);
    default:
      return bundle(placeholders, options);
  }
}

/**
 * Puts page numbers into arrays, one array per Sheet.
 * @param  {[type]} pageNumbers [description]
 * @param  {[type]} options     [description]
 * @return {[type]}             [description]
 */
function bundle(pageNumbers, options) {
  const bundles = pageNumbers.length / options.pagesPerSheet; // should have no remainder

  const { pagesPerSheet, pageOrientation, bindingEdge } = options;

  let bundle = [];
  for (let i = 0; i < bundles; i++) {
    let chunk = pageNumbers.slice(pagesPerSheet * i, pagesPerSheet * (i + 1));
    if (pageOrientation === PageOrientation.PORTRAIT) {
      if (
        bindingEdge === BindingEdge.LEFT ||
        bindingEdge === BindingEdge.RIGHT
      ) {
        if (i % 2) {
          chunk = chunk.reverse();
        }
      }
    }
    bundle.push(chunk);
  }
  return bundle;
}

function derivePlaceholders(pages, pagesWithPadding) {
  return Array.from({ length: pagesWithPadding }, (_, i) =>
    i < pages ? i + 1 : null
  );
}

/**
 * Assumes
 * - What is printed first ends up at the BOTTOM of the stack
 * - Printers are double sided
 */
export default class Paginator {
  #pages = [];
  #pageCount = 0;

  constructor(pageCount, opt) {
    let options = Object.assign(
      {
        pageOrientation: PageOrientation.PORTRAIT,
        bindingEdge: BindingEdge.TOP,
      },
      opt || {}
    );

    opt.bindingMode = getSystemBindingMode(opt.bindingMode);

    this.#pageCount = pageCount;

    switch (opt.bindingMode) {
      case BindingModes.DISC:
        this.#pages = this.handleDiscBinding(pageCount, options);
        break;
      case BindingModes.SIGNATURE:
        options.pagesPerSheet = PagesPerSheet.TWO;

        this.#pages = this.handleSignatureBinding(pageCount, options);
        break;
      default:
        this.#pages = this.handleDiscBinding(pageCount, options);
        break;
    }
  }

  get pages() {
    return this.#pages;
  }

  get count() {
    return this.#pageCount;
  }

  handleDiscBinding(pages, opt) {
    let options = Object.assign(
      {
        pagesPerSheet: PagesPerSheet.ONE,
      },
      opt || {}
    );

    const { pagesPerSheet, bindingEdge } = options;

    let result;

    if (pagesPerSheet === PagesPerSheet.TWO) {
      const sides = 2; // two sides per sheet of paper

      const multiplier = pagesPerSheet * sides;

      const pagesWithPadding = Math.ceil(pages / multiplier) * multiplier;
      let placeholders = derivePlaceholders(pages, pagesWithPadding);

      result = setPageNumbers(placeholders, options);

      if (bindingEdge === BindingEdge.RIGHT) {
        if (pages >= pagesPerSheet) {
          result = result.reverse();
        }
      }
    } else {
      result = Array.from({ length: pages }, (_, i) => i + 1).map((item) => [
        item,
      ]);
    }

    return result;
  }

  handleSignatureBinding(pages, opt) {
    let options = Object.assign(
      {
        sheetsPerSignature: SheetsPerSignature.FOUR,
      },
      opt || {}
    );

    const { pagesPerSheet, sheetsPerSignature, bindingEdge } = options;

    let sides = 2; // two sides per sheet of paper

    const multiplier = pagesPerSheet * sides * sheetsPerSignature;

    const pagesWithPadding = Math.ceil(pages / multiplier) * multiplier;
    let placeholders = derivePlaceholders(pages, pagesWithPadding);

    let bundles = [];
    for (let i = 0; i < Math.floor(pagesWithPadding / multiplier); i++) {
      let bundle = setPageNumbers(
        placeholders.slice(multiplier * i, multiplier * (i + 1)),
        options
      );
      bundles.push(bundle.reverse());
    }

    let result = bundles.reduce((acc, item) => {
      return acc.concat(item);
    }, []);

    // RIGHT is the same as LEFT but with each page swapped
    if (bindingEdge === BindingEdge.RIGHT) {
      result = result.reduce((acc, item) => {
        acc.push(item.reverse());
        return acc;
      }, []);
    }

    return result;
  }
}
