import React from 'react';
import styled from 'styled-components';

import { Helmet } from 'react-helmet';

import { PageStyles } from '../../../constants';

// todo: should I do dependency injection?
import PageStyle1 from './style1';
import PageStyle2 from './style2';
import PageStyle3 from './style3';

export const pageStyles = {
  [PageStyles.INSIDE]: PageStyle1,
  [PageStyles.OUTSIDE_BOTTOM]: PageStyle2,
  [PageStyles.OUTSIDE_TOP]: PageStyle3,
};

const StyledPage = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  background: white;

  /* TODO: just need to implement a gutter perhaps*/
  writing-mode: ${(props) => (props.isRotated ? 'vertical-lr' : 'initial')};
  @media print {
  }
`;

const PageTitle = styled.div`
  color: rgba(0, 0, 0, 0.3);
  font-size: 10pt;
  text-transform: uppercase;
  flex: 0 0;
  background: rgba(255, 255, 255, 1);
  padding: 8px;
  vertical-align: middle;
  line-height: 8px;
  padding-top: 0.65rem;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4pt;
  margin-top: ${(props) => props.padding / 2}pt;
  margin-right: ${(props) => props.padding}pt;
  margin-left: ${(props) => props.padding}pt;
  visibility: ${(props) => (props.showTitle ? 'initial' : 'hidden')};
  transform: ${(props) => (props.isRotated ? 'rotate(180deg)' : 'initial')};
`;

const PageFactory = ({
  pageStyle,
  titleLabel,
  pageNumber,
  padding = 16,
  showTitle,
  isRotated,
  hasAlternateAlign,
  customPattern,
  pageNumberingEnabled,
  children,
}) => {
  if (!pageStyles[pageStyle]) {
    console.error('Page Style', pageStyle, 'not found');
  }
  let PageHelmet = pageStyles[pageStyle].Helmet;
  let PageMeta = pageStyles[pageStyle].PageMeta;
  let PageContent = pageStyles[pageStyle].PageContent;

  let paddingObj = {
    top: pageNumberingEnabled ? padding * 3 : padding,
    left: padding,
    right: padding,
    bottom: padding,
  };

  return (
    <StyledPage isRotated={isRotated}>
      <Helmet>{PageHelmet}</Helmet>
      <PageMeta
        pageTitle={
          <PageTitle
            padding={padding}
            showTitle={showTitle}
            isRotated={isRotated}
          >
            {titleLabel || '\u00A0'}
          </PageTitle>
        }
        pageNumber={pageNumber}
        padding={padding}
        isRotated={isRotated}
        hasAlternateAlign={hasAlternateAlign}
        pageNumberingEnabled={pageNumberingEnabled}
      ></PageMeta>
      <PageContent
        paddingTop={isRotated ? paddingObj.right : paddingObj.top}
        paddingLeft={isRotated ? paddingObj.top : paddingObj.left}
        paddingRight={isRotated ? paddingObj.bottom : paddingObj.right}
        paddingBottom={isRotated ? paddingObj.right : paddingObj.bottom}
        padding={padding}
        isRotated={isRotated}
        customPattern={customPattern}
        pageNumberingEnabled={pageNumberingEnabled}
      >
        {children}
      </PageContent>
    </StyledPage>
  );

  return;
};

export default PageFactory;
