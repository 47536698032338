import React from 'react';
import { useParams } from 'react-router';

import { RecoilRoot } from 'recoil';

import Main from './components/Main';

import theme from '../theme-ui';
import { ThemeProvider } from 'theme-ui';

const AppMain = ({}) => {
  return (
    <ThemeProvider theme={theme}>
      <Main></Main>
    </ThemeProvider>
  );
};

export default AppMain;
