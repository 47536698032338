import React from 'react';
import { BindingModes } from '../../../../constants';

function ThumbnailA4FullPortrait({
  bindingMode,
  color = '#000',
  colorOpacity = '0.02',
  bindingColor = '#F25546',
  strokeColorOpacity = '0.2',
}) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 321 322">
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g transform="translate(98 72)">
          <path
            fill={color}
            fillOpacity={colorOpacity}
            stroke={color}
            strokeOpacity={strokeColorOpacity}
            strokeWidth="2"
            d="M0 0H125V178H0z"
          ></path>
          <g fill={bindingColor} transform="translate(-8)">
            {bindingMode === BindingModes.SIGNATURE && (
              <g>
                <path d="M8 15.952H16V17.951999999999998H8z"></path>
                <path d="M8 121.905H16V123.905H8z"></path>
                <path d="M8 35.024H16V37.024H8z"></path>
                <path d="M8 140.976H16V142.976H8z"></path>
                <path d="M8 54.095H16V56.095H8z"></path>
                <path d="M8 160.048H16V162.048H8z"></path>
              </g>
            )}
            {bindingMode === BindingModes.DISC && (
              <g>
                <rect width="16" height="4" x="0" y="163.286" rx="2"></rect>
                <rect width="16" height="4" x="0" y="146.333" rx="2"></rect>
                <rect width="16" height="4" x="0" y="129.381" rx="2"></rect>
                <rect width="16" height="4" x="0" y="112.429" rx="2"></rect>
                <rect width="16" height="4" x="0" y="95.476" rx="2"></rect>
                <rect width="16" height="4" x="0" y="78.524" rx="2"></rect>
                <rect width="16" height="4" x="0" y="61.571" rx="2"></rect>
                <rect width="16" height="4" x="0" y="44.619" rx="2"></rect>
                <rect width="16" height="4" x="0" y="27.667" rx="2"></rect>
                <rect width="16" height="4" x="0" y="10.714" rx="2"></rect>
              </g>
            )}
            {bindingMode === BindingModes.JAPANESE && (
              <g>
                <path d="M8 13.833H16V15.833H8z"></path>
                <path d="M8 62.571H16V64.571H8z"></path>
                <path d="M8 111.31H16V113.31H8z"></path>
                <path d="M8 160.048H16V162.048H8z"></path>
                <path d="M13.857 0H15.857V178H13.857z"></path>
              </g>
            )}
            {bindingMode === BindingModes.SADDLE && (
              <g>
                <path d="M7.071 4.238H10.071V173.762H7.071z"></path>
              </g>
            )}
            {bindingMode === BindingModes.SPIRAL && (
              <g>
                <path d="M4.571 172.762H11.428V174.762H4.571z"></path>
                <path d="M4.571 164.286H11.428V166.286H4.571z"></path>
                <path d="M4.571 155.81H11.428V157.81H4.571z"></path>
                <path d="M4.571 147.333H11.428V149.333H4.571z"></path>
                <path d="M4.571 138.857H11.428V140.857H4.571z"></path>
                <path d="M4.571 130.381H11.428V132.381H4.571z"></path>
                <path d="M4.571 121.905H11.428V123.905H4.571z"></path>
                <path d="M4.571 113.429H11.428V115.429H4.571z"></path>
                <path d="M4.571 104.952H11.428V106.952H4.571z"></path>
                <path d="M4.571 96.476H11.428V98.476H4.571z"></path>
                <path d="M4.571 88H11.428V90H4.571z"></path>
                <path d="M4.571 79.524H11.428V81.524H4.571z"></path>
                <path d="M4.571 71.048H11.428V73.048H4.571z"></path>
                <path d="M4.571 62.571H11.428V64.571H4.571z"></path>
                <path d="M4.571 54.095H11.428V56.095H4.571z"></path>
                <path d="M4.571 45.619H11.428V47.619H4.571z"></path>
                <path d="M4.571 37.143H11.428V39.143H4.571z"></path>
                <path d="M4.571 28.667H11.428V30.667H4.571z"></path>
                <path d="M4.571 20.19H11.428V22.19H4.571z"></path>
                <path d="M4.571 11.714H11.428V13.714H4.571z"></path>
                <path d="M4.571 3.238H11.428V5.2379999999999995H4.571z"></path>
              </g>
            )}
          </g>
        </g>
      </g>
    </svg>
  );
}

export default ThumbnailA4FullPortrait;
