import React from 'react';
import { BindingModes } from '../../../../constants';

function ThumbnailA4TallLandscape({
  bindingMode,
  color = '#000',
  colorOpacity = '0.02',
  bindingColor = '#F25546',
  strokeColorOpacity = '0.2',
}) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 321 322">
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g transform="translate(71.5 129.75)">
          <path
            fill={color}
            fillOpacity={colorOpacity}
            stroke={color}
            strokeOpacity={strokeColorOpacity}
            strokeWidth="2"
            d="M57.75 -57.75H120.25V120.25H57.75z"
            transform="rotate(90 89 31.25)"
          ></path>
          <g fill={bindingColor} transform="translate(-8)">
            {bindingMode === BindingModes.SIGNATURE && (
              <g>
                <path d="M8 4.905H16V6.905H8z"></path>
                <path d="M8 41.81H16V43.81H8z"></path>
                <path d="M8 11.548H16V13.548H8z"></path>
                <path d="M8 48.452H16V50.452H8z"></path>
                <path d="M8 18.19H16V20.19H8z"></path>
                <path d="M8 55.095H16V57.095H8z"></path>
              </g>
            )}

            {bindingMode === BindingModes.DISC && (
              <g>
                <rect width="16" height="4" x="0" y="54.833" rx="2"></rect>
                <rect width="16" height="4" x="0" y="44.5" rx="2"></rect>
                <rect width="16" height="4" x="0" y="34.167" rx="2"></rect>
                <rect width="16" height="4" x="0" y="23.833" rx="2"></rect>
                <rect width="16" height="4" x="0" y="13.5" rx="2"></rect>
                <rect width="16" height="4" x="0" y="3.167" rx="2"></rect>
              </g>
            )}
            {bindingMode === BindingModes.JAPANESE && (
              <g>
                <path d="M8 4.167H16V6.167H8z"></path>
                <path d="M8 21.143H16V23.143H8z"></path>
                <path d="M8 38.119H16V40.119H8z"></path>
                <path d="M8 55.095H16V57.095H8z"></path>
                <path d="M13.857 0H15.857V62H13.857z"></path>
              </g>
            )}
            {bindingMode === BindingModes.SADDLE && (
              <g>
                <path d="M7.071 1.476H10.071V60.524H7.071z"></path>
              </g>
            )}

            {bindingMode === BindingModes.SPIRAL && (
              <g>
                <path d="M4.571 59.524H11.428V61.524H4.571z"></path>
                <path d="M4.571 53.619H11.428V55.619H4.571z"></path>
                <path d="M4.571 47.714H11.428V49.714H4.571z"></path>
                <path d="M4.571 41.81H11.428V43.81H4.571z"></path>
                <path d="M4.571 35.905H11.428V37.905H4.571z"></path>
                <path d="M4.571 30H11.428V32H4.571z"></path>
                <path d="M4.571 24.095H11.428V26.095H4.571z"></path>
                <path d="M4.571 18.19H11.428V20.19H4.571z"></path>
                <path d="M4.571 12.286H11.428V14.286H4.571z"></path>
                <path d="M4.571 6.381H11.428V8.381H4.571z"></path>
                <path d="M4.571 0.476H11.428V2.476H4.571z"></path>
              </g>
            )}
          </g>
        </g>
      </g>
    </svg>
  );
}

export default ThumbnailA4TallLandscape;
