/** @jsx jsx */
import { jsx, Label, Checkbox as C } from 'theme-ui';
import React from 'react';

const Checkbox = ({ defaultChecked, checked, children, hint, onChange }) => {
  return (
    <>
      <Label>
        <C
          defaultChecked={defaultChecked}
          checked={checked}
          onChange={onChange}
        ></C>{' '}
        {children}
      </Label>
      {hint && (
        <aside
          sx={{
            variant: 'text.hint',
          }}
        >
          {hint}
        </aside>
      )}
    </>
  );
};

export default Checkbox;
