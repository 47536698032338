/** @jsx jsx */
import { jsx, Label, Styled } from 'theme-ui';
import React from 'react';

import { Slider as S } from 'theme-ui';

function toTitleCase(str) {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

// TODO: needs to add ability to change the value of this. Input event handling etc.
const Slider = ({ title, min, max, step, children, value, onChange }) => {
  return (
    <>
      {title && <Label>{title}</Label>}
      <S min={min} max={max} step={step} value={value} onChange={onChange}></S>
      {children}
    </>
  );
};

export default Slider;
