/** @jsx jsx */
import { jsx } from 'theme-ui';
import React from 'react';

import { PatternUrls } from '../../../components/PatternFactory';
import { ColorOptions, PatternOptions } from '../../../constants';

const PatternTile = ({
  color = ColorOptions.BLACK,
  pattern,
  magnification = 200,
}) => {
  let patternUrl = PatternUrls[pattern];

  return (
    <div
      sx={{
        // backgroundColor: 'red',
        backgroundColor: (theme) => {
          return pattern !== PatternOptions.NONE
            ? theme.colors.pattern[color]
            : 'transparent';
        },
        maskImage: patternUrl && `url(${patternUrl})`,
        maskSize: `${magnification}%`, // TODO: find out a better way to display. like maybe zoom in on hover.
        // teaches how to make it square https://spin.atomicobject.com/2015/07/14/css-responsive-square/
        width: '100%',
        '&:after': {
          content: '""',
          display: 'block',
          paddingBottom: '100%',
        },
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
      }}
    >
      {!patternUrl && '(none)'}
    </div>
  );
};

export default PatternTile;
