/** @jsx jsx */
import React from 'react';
import { jsx, Styled } from 'theme-ui';
import MailchimpSubscribe from 'react-mailchimp-subscribe';

const url =
  '//app.us1.list-manage.com/subscribe/post?u=45d0a3097ff79a871412634f4&id=426b438a09';

const subscriptionMessage = {
  variant: 'aside',
  mb: '0.5em',
  maxWidth: '30em',
};

const SubscribeForm = () => (
  <MailchimpSubscribe
    url={url}
    render={({ subscribe, status, message }) => {
      return (
        <div
          sx={{
            input: {
              // variant: 'forms.input', // TODO: not sure why variants are not working
              border: '1px solid lightgray',
              padding: 1,
              borderRadius: 4,
              variant: 'styles.root',
              width: '16em',
              fontSize: '1em',
              marginRight: '1em',
            },
            button: {
              // variant: 'buttons.primary', // TODO: not sure why variants are not working
              fontFamily: 'body',
              fontWeight: 700,
              backgroundColor: 'darkGreen',
              border: 'transparent',
              height: '2rem',
              borderRadius: '4px',
              padding: '0.2rem 0.75rem',
              textAlign: 'center',
              cursor: 'pointer',
              fontWeight: '600',
              color: 'white',
              transition: 'all 400ms',
              '&:hover': {
                backgroundColor: 'primary',
                transition: 'all 400ms',
              },
            },
            div: {
              ...subscriptionMessage,
            },
          }}
        >
          <div sx={{ p: '1em' }}>
            <MailchimpSubscribe
              url={url}
              onSubmitted={(formData) => subscribe(formData)}
            />
          </div>
          <aside sx={{ variant: 'aside', maxWidth: '30rem' }}>
            The contact information you provide will solely be used to contact
            you regarding updates to this application. We will never spam you or
            share your info.
          </aside>
        </div>
      );
    }}
  />
);

export default SubscribeForm;
