/** @jsx jsx */
import { jsx } from 'theme-ui';

import { ModalTitle as MT } from '@mattjennings/react-modal';
import { Text } from '@theme-ui/components';

const ModalTitle = ({ title, children }) => (
  <MT>
    <div
      sx={{
        display: 'flex',
        flexDirection: 'column',
        pb: '1rem',
      }}
    >
      <Text
        sx={{
          fontSize: '1.5em',
          fontWeight: '700',
          mb: '1rem',
        }}
      >
        {title}
      </Text>

      <div
        sx={{
          display: 'flex',
          width: '100%',
        }}
      >
        {children}
      </div>
    </div>
  </MT>
);

export default ModalTitle;
