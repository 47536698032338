import React from 'react';
import { Styled } from 'theme-ui';
import { calculateSheets } from '../../../utils/page';
import { pluralize, capitalize } from 'humanize-plus';

export const calculateMessage = ({ pages, pagesPerSheet, sheetSize }) => {
  const sheetCount = calculateSheets(pages, pagesPerSheet);
  return (
    <Styled.p>
      Your notebook will use{' '}
      <Styled.strong>
        {sheetCount} {pluralize(sheetCount, 'sheet')}
      </Styled.strong>{' '}
      of <Styled.strong>{capitalize(sheetSize)}-size</Styled.strong> paper,
      assuming double-sided printing.
    </Styled.p>
  );
};
