import {
  BindingEdge,
  CutOrientation,
  Location,
  PageOrientation,
  PagesPerSheet,
  PreviewLeafType,
  SheetSizeDimensions,
} from '../constants';

export const shouldRotatePrint = (pageOrientation, pagesPerSheet) =>
  (pageOrientation === PageOrientation.LANDSCAPE &&
    pagesPerSheet == PagesPerSheet.ONE) ||
  (pageOrientation === PageOrientation.PORTRAIT &&
    pagesPerSheet == PagesPerSheet.TWO);

export const shouldHaveAlternateAlign = (bindingEdge, pageNumber) =>
  (pageNumber % 2 === 0 && bindingEdge === BindingEdge.LEFT) ||
  (pageNumber % 2 !== 0 && bindingEdge === BindingEdge.RIGHT)
    ? true
    : false;

const FLYLEAF_COUNT = 2;

export const flyleafPageNumber = (rawPageNumber, pageCount) => {
  const flyleafPageLimit = pageCount - FLYLEAF_COUNT - 1;
  let flyleafPageNumber = rawPageNumber - FLYLEAF_COUNT;
  return flyleafPageNumber <= 0 || flyleafPageNumber >= flyleafPageLimit
    ? null
    : flyleafPageNumber;
};

export const calculateSheets = (pages, pagesPerSheet) =>
  pagesPerSheet === PagesPerSheet.TWO
    ? Math.ceil(pages / (2 * parseInt(PagesPerSheet.TWO)))
    : Math.ceil(pages / 2);

export const calculatePageSize = (
  sheetSize,
  pageOrientation,
  pagesPerSheet,
  cutOrientation = CutOrientation.SHORT
) => {
  let long, short;

  const sheetDimensions = SheetSizeDimensions[sheetSize];

  long = sheetDimensions.height;
  short = sheetDimensions.width;

  switch (pagesPerSheet) {
    case PagesPerSheet.ONE:
      return {
        width: pageOrientation === PageOrientation.PORTRAIT ? short : long,
        height: pageOrientation === PageOrientation.PORTRAIT ? long : short,
        units: sheetDimensions.units,
      };

    case PagesPerSheet.TWO:
      if (cutOrientation === CutOrientation.SHORT) {
        return {
          width:
            pageOrientation === PageOrientation.PORTRAIT ? long / 2 : short,
          height:
            pageOrientation === PageOrientation.PORTRAIT ? short : long / 2,
          units: sheetDimensions.units,
        };
      } else {
        return {
          width:
            pageOrientation === PageOrientation.PORTRAIT ? short / 2 : long,
          height:
            pageOrientation === PageOrientation.PORTRAIT ? long : short / 2,
          units: sheetDimensions.units,
        };
      }

    default:
      break;
  }
};

export default {
  shouldRotatePrint,
  shouldHaveAlternateAlign,
};
