import { BindingEdge, Location, PreviewLeafType } from '../../../constants';
import { getBasicBookDescriptorObj } from '../../../utils/book';
import { shouldHaveAlternateAlign } from '../../../utils/page';
import { BookDescriptor } from '../../core';

const addFlyleaf = (book) => {
  book.push({
    type: PreviewLeafType.FLYLEAF,
  });
  book.push({
    type: PreviewLeafType.FLYLEAF,
  });
};

const addCover = (book, location, flyleaf) => {
  if (location === Location.FRONT) {
    book.push({
      type: PreviewLeafType.OUTER_COVER,
      location,
    });
    book.push({
      type: PreviewLeafType.INNER_COVER,
      location,
    });

    if (flyleaf) {
      addFlyleaf(book);
    }
  } else {
    if (flyleaf) {
      addFlyleaf(book);
    }

    book.push({
      type: PreviewLeafType.INNER_COVER,
      location,
    });

    book.push({
      type: PreviewLeafType.OUTER_COVER,
      location,
    });
  }
};

/**
 *
 * @param {*} config
 * @param {*} pageNumber
 * @param {*} bookDescriptor Always assumes a basic book descriptor
 * @returns
 */
const page = (config, pageNumber, bookDescriptor) => {
  const { pattern, bindingEdge } = config;

  // always assumes a basic book descriptor
  return {
    type: PreviewLeafType.PAGE,
    // pattern: bookDescriptor.patterns[pageNumber], // TODO: fix bug where the pageNumber used in bookDescriptor doesn't match pageNumber coming in from the slider. Reduce page count to 1, then increase to 4 and see how the pages don't get their pattern applied properly.
    pattern,
    pageNumber,
    hasAlternateAlign: shouldHaveAlternateAlign(bindingEdge, pageNumber),
  };
};

export const adjustPagesForFlyleaf = (pages, flyleaf) =>
  pages - (flyleaf ? 4 : 0);

const addPages = (book, config, bookDescriptor) => {
  const { pages, flyleaf } = config;

  for (let index = 0; index < adjustPagesForFlyleaf(pages, flyleaf); index++) {
    let pageNumber = index + 1;

    book.push(page(config, pageNumber, bookDescriptor));
  }
};

const addVoid = (book) => {
  book.push(VOID);
};

const VOID = {
  type: PreviewLeafType.VOID,
};

/**
 * Always assumes basic book descriptor
 */
export default class MiniBookModel {
  #bookPages = [];
  #config = {};
  #page1Offset = -1;
  #pageCount = -1;
  #bookDescriptor = null;

  constructor(config) {
    this.#config = config;
    this.__initialize(config);
  }

  __initialize = () => {
    const { flyleaf, pattern, color, pages, bindingMode, isCustomPattern } =
      this.#config;
    this.#bookPages = [];

    this.#bookDescriptor = new BookDescriptor(
      getBasicBookDescriptorObj(
        pattern,
        isCustomPattern,
        color,
        flyleaf,
        pages,
        bindingMode
      )
    );

    // create the conceptual pages
    addVoid(this.#bookPages);
    addCover(this.#bookPages, Location.FRONT, flyleaf);

    this.#page1Offset = this.#bookPages.length; // remember the offset for later use
    this.#pageCount = pages;

    addPages(this.#bookPages, this.#config, this.#bookDescriptor);
    addCover(this.#bookPages, Location.BACK, flyleaf);
    addVoid(this.#bookPages);
  };

  /**
   * Returns the spread with the requested the page number.
   * If requesting negative number:
   * - 0: optional flyleaf back
   * - -1: optional flyleaf front
   * - -2: inner cover
   * - -3: outer cover
   * - -4: void
   *
   * If no flyleaf:
   * - 0: inner cover
   * - -1: outer cover
   * - -2: void
   * @param {*} pageNumber
   * @returns
   */
  spread(pageNumber) {
    const pageIndex = pageNumber + this.#page1Offset - 1;

    return this.spreadByIndex(pageIndex, pageNumber);
  }

  spreadByIndex(pageIndex, pageNumber) {
    let spread;
    const { bindingEdge } = this.#config;

    // validation: is there a smarter way?
    if (pageNumber < this.min) {
      spread = [
        this.#bookPages[this.#page1Offset],
        this.#bookPages[this.#page1Offset + 1],
      ];
    } else if (pageNumber > this.max) {
      spread = [
        this.#bookPages[this.#bookPages.length - 2],
        this.#bookPages[this.#bookPages.length - 1],
      ];
    } else if (pageNumber % 2 === 0) {
      spread = [this.#bookPages[pageIndex], this.#bookPages[pageIndex + 1]];
    } else {
      spread = [this.#bookPages[pageIndex - 1], this.#bookPages[pageIndex]];
    }

    return bindingEdge === BindingEdge.RIGHT ? spread.reverse() : spread;
  }

  set pageCount(pageCount) {
    this.#config.pageCount = pageCount;
    this.__initialize();
  }

  get min() {
    return this.#page1Offset * -1 + 1;
  }

  get max() {
    return this.#pageCount + (this.#config.flyleaf ? 0 : 2); // 2 for back cover
  }

  isActualPage(pageNumber) {
    return (
      pageNumber >= 0 &&
      pageNumber <= this.#pageCount - (this.#config.flyleaf ? 4 : 0)
    );
  }
}
