import { atom, selector } from 'recoil';
import defaultValues, {
  SystemBindingModes,
  SystemBindingModeMap,
} from '../../constants';

import { recoilPersist } from 'recoil-persist';

const LOCALSTORAGE = 'folio-forge';

const { persistAtom } = recoilPersist({
  key: LOCALSTORAGE,
});

// TODO: to make system more backward-compatible: https://recoiljs.org/docs/guides/atom-effects/#backward-compatibility

export const pagesState = atom({
  key: 'pages',
  default: defaultValues['pages'],
  effects_UNSTABLE: [persistAtom],
});

export const currentPageState = atom({
  key: 'currentPage',
  default: 1,
  effects_UNSTABLE: [persistAtom],
});

export const sheetSizeState = atom({
  key: 'sheetSize',
  default: defaultValues['sheetSize'],
  effects_UNSTABLE: [persistAtom],
});

export const pagesPerSheetState = atom({
  key: 'pagesPerSheet',
  default: defaultValues['pagesPerSheet'],
  effects_UNSTABLE: [persistAtom],
});

export const pageOrientationState = atom({
  key: 'pageOrientation',
  default: defaultValues['pageOrientation'],
  effects_UNSTABLE: [persistAtom],
});

export const bindingEdgeState = atom({
  key: 'bindingEdge',
  default: defaultValues['bindingEdge'],
  effects_UNSTABLE: [persistAtom],
});

export const cutOrientationState = atom({
  key: 'cutOrientation',
  default: defaultValues['cutOrientation'],
  effects_UNSTABLE: [persistAtom],
});

export const bindingModeState = atom({
  key: 'bindingMode',
  default: defaultValues['bindingMode'],
  effects_UNSTABLE: [persistAtom],
});

export const systemBindingMode = selector({
  key: 'systemBindingMode',
  get: ({ get }) => {
    const bindingMode = get(bindingModeState);

    return bindingMode
      ? SystemBindingModeMap[bindingMode]
      : SystemBindingModes.NONE;
  },
});

export const patternState = atom({
  key: 'pattern',
  default: defaultValues['pattern'],
  effects_UNSTABLE: [persistAtom],
});

export const colorState = atom({
  key: 'color',
  default: defaultValues['color'],
  effects_UNSTABLE: [persistAtom],
});

export const opacityState = atom({
  key: 'opacity',
  default: defaultValues['opacity'],
  effects_UNSTABLE: [persistAtom],
});

export const pageNumberingEnabledState = atom({
  key: 'pageNumberingEnabled',
  default: defaultValues['pageNumberingEnabled'],
  effects_UNSTABLE: [persistAtom],
});

export const pageStyleState = atom({
  key: 'pageStyle',
  default: defaultValues['pageStyle'],
  effects_UNSTABLE: [persistAtom],
});

export const flyleafState = atom({
  key: 'flyleaf',
  default: defaultValues['flyleaf'],
  effects_UNSTABLE: [persistAtom],
});

export const userSuggestTemplateState = atom({
  key: 'userSuggestTemplate',
  default: false,
});

export const userGreetingState = atom({
  key: 'userGreeting',
  default: false,
  effects_UNSTABLE: [persistAtom],
});

// TODO: gather up all UI states
export const selectedTabState = atom({
  key: 'selectedTab',
  default: 0,
  effects_UNSTABLE: [persistAtom],
});

export const showPrintPreviewState = atom({
  key: 'showPrintPreview',
  default: false,
});

export const sheetsPerSignatureState = atom({
  key: 'sheetsPerSignature',
  default: defaultValues['sheetsPerSignature'],
});
