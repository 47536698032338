/** @jsx jsx */
import { jsx } from 'theme-ui';
import React, { useState } from 'react';

import { Modal, ModalTitle, ModalContent, ModalFooter } from './';

import ThumbnailGallery from '../Gallery/ThumbnailGallery';
import Select from '../Input/Select';
import {
  CutOrientation,
  NullOption,
  PageOrientation,
  PagesPerSheet,
  SheetSizeOptions,
} from '../../../constants';

import experimentalFeatures from '../../experimentalFeatures';

const NotebookDialog = (props) => {
  const [open, setOpen] = useState(props.open);
  const [sheetSize, setSheetSize] = useState(NullOption);
  const [pagesPerSheet, setPagesPerSheet] = useState(NullOption);
  const [pageOrientation, setPageOrientation] = useState(NullOption);
  const [cutOrientation, setCutOrientation] = useState(NullOption);

  const [selectedItem, setSelectedItem] = useState(props.selectedItem);

  const rippleSetPagesPerSheet = (pagesPerSheetValue) => {
    setPagesPerSheet(pagesPerSheetValue);

    if (
      pagesPerSheetValue === PagesPerSheet.ONE ||
      pagesPerSheetValue === NullOption
    ) {
      setCutOrientation(NullOption);
    }
  };

  const rippleSetCutOrientation = (cutOrientationValue) => {
    setCutOrientation(cutOrientationValue);

    if (
      cutOrientationValue === CutOrientation.LONG ||
      cutOrientationValue === CutOrientation.SHORT
    ) {
      setPagesPerSheet(PagesPerSheet.TWO);
    }
  };

  return (
    <>
      <div onClick={() => setOpen(true)}>{props.children}</div>
      <Modal open={open} onClose={() => setOpen(false)}>
        {({ onClose }) => (
          <>
            <ModalTitle title="Select Notebook Size">
              <Select
                options={SheetSizeOptions}
                value={sheetSize}
                onChange={(e) => {
                  setSheetSize(e.target.value);
                }}
                nullOption="Any"
              >
                Filter by Source Paper Size
              </Select>
              <Select
                options={PagesPerSheet}
                value={pagesPerSheet}
                onChange={(e) => {
                  console.log('pages', e.target.value);
                  rippleSetPagesPerSheet(e.target.value);
                }}
                nullOption="Any"
              >
                Pages per Sheet
              </Select>
              <Select
                options={PageOrientation}
                value={pageOrientation}
                onChange={(e) => setPageOrientation(e.target.value)}
                nullOption="Any"
              >
                Page Orientation
              </Select>
              {experimentalFeatures.enableLongCutOrientation && (
                <Select
                  options={CutOrientation}
                  value={cutOrientation}
                  onChange={(e) => rippleSetCutOrientation(e.target.value)}
                  nullOption="Any"
                  disabled={pagesPerSheet === PagesPerSheet.ONE}
                >
                  Cut Orientation
                </Select>
              )}
            </ModalTitle>
            <ModalContent>
              <ThumbnailGallery
                sheetSize={sheetSize}
                pagesPerSheet={pagesPerSheet}
                pageOrientation={pageOrientation}
                cutOrientation={cutOrientation}
                onSelectItem={setSelectedItem}
                selectedItem={selectedItem}
              ></ThumbnailGallery>
            </ModalContent>
            <ModalFooter
              onClose={onClose}
              onCommit={() => {
                props.onSelectItem(selectedItem);
                onClose();
              }}
            ></ModalFooter>
          </>
        )}
      </Modal>
    </>
  );
};
export default NotebookDialog;
