import React from 'react';
import { BindingModes } from '../../../../constants';

function ThumbnailLetterHalfLandscape({
  bindingMode,
  color = '#000',
  colorOpacity = '0.02',
  bindingColor = '#F25546',
  strokeColorOpacity = '0.2',
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="321"
      height="322"
      viewBox="0 0 321 322"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g transform="translate(93 109)">
          <path
            fill={color}
            fillOpacity={colorOpacity}
            stroke={color}
            strokeOpacity={strokeColorOpacity}
            strokeWidth="2"
            d="M106.5 -21L106.5 107 23.5 107 23.5 -21z"
            transform="rotate(90 65 43)"
          ></path>
          <g fill={bindingColor} transform="translate(-7 .5)">
            {bindingMode === BindingModes.SIGNATURE && (
              <g>
                <path d="M7 7.095H14V9.094999999999999H7z"></path>
                <path d="M7 57.69H14V59.69H7z"></path>
                <path d="M7 16.202H14V18.202H7z"></path>
                <path d="M7 66.798H14V68.798H7z"></path>
                <path d="M7 25.31H14V27.31H7z"></path>
                <path d="M7 75.905H14V77.905H7z"></path>
              </g>
            )}
            {bindingMode === BindingModes.DISC && (
              <g>
                <rect width="14" height="4" x="0" y="75.917" rx="2"></rect>
                <rect width="14" height="4" x="0" y="61.75" rx="2"></rect>
                <rect width="14" height="4" x="0" y="47.583" rx="2"></rect>
                <rect width="14" height="4" x="0" y="33.417" rx="2"></rect>
                <rect width="14" height="4" x="0" y="19.25" rx="2"></rect>
                <rect width="14" height="4" x="0" y="5.083" rx="2"></rect>
              </g>
            )}
            {bindingMode === BindingModes.JAPANESE && (
              <g>
                <path d="M7 6.083H14V8.083H7z"></path>
                <path d="M7 29.357H14V31.357H7z"></path>
                <path d="M7 52.631H14V54.631H7z"></path>
                <path d="M7 75.905H14V77.905H7z"></path>
                <path d="M12 0H14V85H12z"></path>
              </g>
            )}
            {bindingMode === BindingModes.SADDLE && (
              <g>
                <path d="M6 2.024H9V82.976H6z"></path>
              </g>
            )}
            {bindingMode === BindingModes.SPIRAL && (
              <g>
                <path d="M4 81.976H10V83.976H4z"></path>
                <path d="M4 73.881H10V75.881H4z"></path>
                <path d="M4 65.786H10V67.786H4z"></path>
                <path d="M4 57.69H10V59.69H4z"></path>
                <path d="M4 49.595H10V51.595H4z"></path>
                <path d="M4 41.5H10V43.5H4z"></path>
                <path d="M4 33.405H10V35.405H4z"></path>
                <path d="M4 25.31H10V27.31H4z"></path>
                <path d="M4 17.214H10V19.214H4z"></path>
                <path d="M4 9.119H10V11.119H4z"></path>
                <path d="M4 1.024H10V3.024H4z"></path>
              </g>
            )}
          </g>
        </g>
      </g>
    </svg>
  );
}

export default ThumbnailLetterHalfLandscape;
