import React from 'react';
import { BindingModes } from '../../../../constants';

function ThumbnailLegalTallLandscape({
  bindingMode,
  color = '#000',
  colorOpacity = '0.02',
  bindingColor = '#F25546',
  strokeColorOpacity = '0.2',
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="321"
      height="322"
      viewBox="0 0 321 322"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g transform="translate(54.5 128.75)">
          <path
            fill={color}
            fillOpacity={colorOpacity}
            stroke={color}
            strokeOpacity={strokeColorOpacity}
            strokeWidth="2"
            d="M212 0L212 64.5 0 64.5 0 0z"
          ></path>
          <g fill={bindingColor} transform="translate(-8)">
            {bindingMode === BindingModes.SIGNATURE && (
              <g>
                <path d="M8 5.143H16V7.143H8z"></path>
                <path d="M8 43.536H16V45.536H8z"></path>
                <path d="M8 12.054H16V14.054H8z"></path>
                <path d="M8 50.446H16V52.446H8z"></path>
                <path d="M8 18.964H16V20.964H8z"></path>
                <path d="M8 57.357H16V59.357H8z"></path>
              </g>
            )}
            {bindingMode === BindingModes.DISC && (
              <g>
                <rect width="16" height="4" x="0" y="57.125" rx="2"></rect>
                <rect width="16" height="4" x="0" y="46.375" rx="2"></rect>
                <rect width="16" height="4" x="0" y="35.625" rx="2"></rect>
                <rect width="16" height="4" x="0" y="24.875" rx="2"></rect>
                <rect width="16" height="4" x="0" y="14.125" rx="2"></rect>
                <rect width="16" height="4" x="0" y="3.375" rx="2"></rect>
              </g>
            )}

            {bindingMode === BindingModes.JAPANESE && (
              <g>
                <path d="M8 4.375H16V6.375H8z"></path>
                <path d="M8 22.036H16V24.036H8z"></path>
                <path d="M8 39.696H16V41.696H8z"></path>
                <path d="M8 57.357H16V59.357H8z"></path>
                <path d="M13.857 0H15.857V64.5H13.857z"></path>
              </g>
            )}
            {bindingMode === BindingModes.SADDLE && (
              <g>
                <path d="M7.071 1.536H10.071V62.965H7.071z"></path>
              </g>
            )}
            {bindingMode === BindingModes.SPIRAL && (
              <g>
                <path d="M4.571 61.964H11.428V63.964H4.571z"></path>
                <path d="M4.571 55.821H11.428V57.821H4.571z"></path>
                <path d="M4.571 49.679H11.428V51.679H4.571z"></path>
                <path d="M4.571 43.536H11.428V45.536H4.571z"></path>
                <path d="M4.571 37.393H11.428V39.393H4.571z"></path>
                <path d="M4.571 31.25H11.428V33.25H4.571z"></path>
                <path d="M4.571 25.107H11.428V27.107H4.571z"></path>
                <path d="M4.571 18.964H11.428V20.964H4.571z"></path>
                <path d="M4.571 12.821H11.428V14.821H4.571z"></path>
                <path d="M4.571 6.679H11.428V8.679H4.571z"></path>
                <path d="M4.571 0.536H11.428V2.536H4.571z"></path>
              </g>
            )}
          </g>
        </g>
      </g>
    </svg>
  );
}

export default ThumbnailLegalTallLandscape;
