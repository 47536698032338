import React from 'react';
import { BindingModes } from '../../../../constants';

function ThumbnailLegalTallPortrait({
  bindingMode,
  color = '#000',
  colorOpacity = '0.02',
  bindingColor = '#F25546',
  strokeColorOpacity = '0.2',
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="321"
      height="322"
      viewBox="0 0 321 322"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g transform="translate(128.25 55)">
          <path
            fill={color}
            fillOpacity={colorOpacity}
            stroke={color}
            strokeOpacity={strokeColorOpacity}
            strokeWidth="2"
            d="M-7.10542736e-15 2.84217094e-14L64.5 2.84217094e-14 64.5 212 -7.10542736e-15 212 -7.10542736e-15 38.3335366z"
          ></path>
          <g fill={bindingColor} transform="translate(-8)">
            {bindingMode === BindingModes.SIGNATURE && (
              <g>
                <path d="M8 19.19H16V21.19H8z"></path>
                <path d="M8 145.381H16V147.381H8z"></path>
                <path d="M8 41.905H16V43.905H8z"></path>
                <path d="M8 168.095H16V170.095H8z"></path>
                <path d="M8 64.619H16V66.619H8z"></path>
                <path d="M8 190.81H16V192.81H8z"></path>
              </g>
            )}
            {bindingMode === BindingModes.DISC && (
              <g>
                <rect width="16" height="4" x="0" y="194.857" rx="2"></rect>
                <rect width="16" height="4" x="0" y="174.667" rx="2"></rect>
                <rect width="16" height="4" x="0" y="154.476" rx="2"></rect>
                <rect width="16" height="4" x="0" y="134.286" rx="2"></rect>
                <rect width="16" height="4" x="0" y="114.095" rx="2"></rect>
                <rect width="16" height="4" x="0" y="93.905" rx="2"></rect>
                <rect width="16" height="4" x="0" y="73.714" rx="2"></rect>
                <rect width="16" height="4" x="0" y="53.524" rx="2"></rect>
                <rect width="16" height="4" x="0" y="33.333" rx="2"></rect>
                <rect width="16" height="4" x="0" y="13.143" rx="2"></rect>
              </g>
            )}
            {bindingMode === BindingModes.JAPANESE && (
              <g>
                <path d="M8 16.667H16V18.667H8z"></path>
                <path d="M8 74.714H16V76.714H8z"></path>
                <path d="M8 132.762H16V134.762H8z"></path>
                <path d="M8 190.81H16V192.81H8z"></path>
                <path d="M13.857 0H15.857V212H13.857z"></path>
              </g>
            )}
            {bindingMode === BindingModes.SADDLE && (
              <g>
                <path d="M7.071 5.048H10.071V206.953H7.071z"></path>
              </g>
            )}
            {bindingMode === BindingModes.SPIRAL && (
              <g>
                <path d="M4.571 205.952H11.428V207.952H4.571z"></path>
                <path d="M4.571 195.857H11.428V197.857H4.571z"></path>
                <path d="M4.571 185.762H11.428V187.762H4.571z"></path>
                <path d="M4.571 175.667H11.428V177.667H4.571z"></path>
                <path d="M4.571 165.571H11.428V167.571H4.571z"></path>
                <path d="M4.571 155.476H11.428V157.476H4.571z"></path>
                <path d="M4.571 145.381H11.428V147.381H4.571z"></path>
                <path d="M4.571 135.286H11.428V137.286H4.571z"></path>
                <path d="M4.571 125.19H11.428V127.19H4.571z"></path>
                <path d="M4.571 115.095H11.428V117.095H4.571z"></path>
                <path d="M4.571 105H11.428V107H4.571z"></path>
                <path d="M4.571 94.905H11.428V96.905H4.571z"></path>
                <path d="M4.571 84.81H11.428V86.81H4.571z"></path>
                <path d="M4.571 74.714H11.428V76.714H4.571z"></path>
                <path d="M4.571 64.619H11.428V66.619H4.571z"></path>
                <path d="M4.571 54.524H11.428V56.524H4.571z"></path>
                <path d="M4.571 44.429H11.428V46.429H4.571z"></path>
                <path d="M4.571 34.333H11.428V36.333H4.571z"></path>
                <path d="M4.571 24.238H11.428V26.238H4.571z"></path>
                <path d="M4.571 14.143H11.428V16.143H4.571z"></path>
                <path d="M4.571 4.048H11.428V6.048H4.571z"></path>
              </g>
            )}
          </g>
        </g>
      </g>
    </svg>
  );
}

export default ThumbnailLegalTallPortrait;
