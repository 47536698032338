/** @jsx jsx */
import { Button, jsx, ThemeProvider } from 'theme-ui';
import React from 'react';

import { useRecoilValue } from 'recoil';
import PrintInstructions from '../components/PrintInstructions';
import {
  bindingModeState,
  colorState,
  pageOrientationState,
  pagesPerSheetState,
  pagesState,
  sheetSizeState,
  sheetsPerSignatureState,
} from '../recoil';

import { ReactComponent as Logo } from '../images/logo.svg';
import Link from '../components/Input/Link';
import Icon from '../components/IconFactory';

import theme from '../../theme-ui';

export const PrintInstructionsViewInner = ({
  pages,
  bindingMode,
  sheetSize,
  pageOrientation,
  pagesPerSheet,
  color,
  sheetsPerSignature,
}) => (
  <div
    sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
      width: '100%',
      bg: 'white',
    }}
  >
    <div
      sx={{
        maxWidth: '40rem',
        borderColor: 'primary',
        borderWidth: 1,
        borderStyle: 'solid',
        p: '2rem',
        backgroundColor: 'white',
      }}
    >
      <div
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'baseline',
          variant: 'printHide',
        }}
      >
        <Link
          to="/app"
          sx={{
            mb: '1rem',
            ml: -27,
          }}
        >
          <Icon name="chevron-left"></Icon> Back to Folio Forge
        </Link>
        <Button sx={{ variant: 'primary' }} onClick={() => window.print()}>
          <Icon name="print" color="white"></Icon> Print
        </Button>
      </div>
      <div
        sx={{
          p: '2rem',
          margin: '0 auto',
          display: 'flex',
          justifyContent: 'center',
          maxWidth: 100, // TODO: why should I need this?
        }}
      >
        <Logo />
      </div>

      <PrintInstructions
        {...{
          pages,
          bindingMode,
          sheetSize,
          pageOrientation,
          pagesPerSheet,
          color,
          sheetsPerSignature,
        }}
      ></PrintInstructions>
    </div>
  </div>
);

const PrintInstructionsView = () => {
  const pages = useRecoilValue(pagesState);
  const bindingMode = useRecoilValue(bindingModeState);

  const sheetSize = useRecoilValue(sheetSizeState);
  const pageOrientation = useRecoilValue(pageOrientationState);
  const pagesPerSheet = useRecoilValue(pagesPerSheetState);

  const color = useRecoilValue(colorState);
  const sheetsPerSignature = useRecoilValue(sheetsPerSignatureState);

  return (
    <ThemeProvider theme={theme}>
      <PrintInstructionsViewInner
        {...{
          pages,
          bindingMode,
          sheetSize,
          pageOrientation,
          pagesPerSheet,
          color,
          sheetsPerSignature,
        }}
      ></PrintInstructionsViewInner>
    </ThemeProvider>
  );
};

export default PrintInstructionsView;
