/** @jsx jsx */
import { jsx, Styled, IconButton, useThemeUI } from 'theme-ui';

import React from 'react';

import MiniPage, { StyledBlankMiniPage, VoidPage } from './MiniPage';

import { BindingEdge, PreviewLeafType, Location } from '../../../constants';

import RingsOverlay from './RingsOverlay';

import MiniBookNav from './MiniBookNav';
import Icon from '../IconFactory';

const MiniBookSpreadStyle = ({
  showToScale,
  bindingEdge,
  showShadow,
  children,
}) => (
  <Styled.div
    sx={{
      display: 'flex',
      flexDirection: bindingEdge === BindingEdge.TOP ? 'column' : 'row',
      transform: showToScale ? 'scale(0.50)' : 'none',
      boxShadow: showShadow
        ? '0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23)'
        : 'none',
      alignSelf: 'center',
    }}
  >
    {children}
  </Styled.div>
);

const getPage = (pageInfo, props) => {
  const { height, width, pageStyle, color, opacity, pageNumberingEnabled } =
    props;

  switch (pageInfo.type) {
    case PreviewLeafType.PAGE:
      return (
        <MiniPage
          height={height}
          width={width}
          pattern={pageInfo.pattern}
          pageNumber={pageInfo.pageNumber}
          pageStyle={pageStyle}
          hasAlternateAlign={pageInfo.hasAlternateAlign}
          color={color}
          opacity={opacity}
          pageNumberingEnabled={pageNumberingEnabled}
        ></MiniPage>
      );

    case PreviewLeafType.OUTER_COVER:
      return (
        <StyledBlankMiniPage
          height={height}
          width={width}
          color="muted"
          showShadow={true}
        ></StyledBlankMiniPage>
      );
    case PreviewLeafType.INNER_COVER:
      return (
        <StyledBlankMiniPage
          height={height}
          width={width}
          color="muted"
        ></StyledBlankMiniPage>
      );

    case PreviewLeafType.FLYLEAF:
      return (
        <StyledBlankMiniPage
          height={height}
          width={width}
          color="white"
          border="muted"
        ></StyledBlankMiniPage>
      );

    case PreviewLeafType.VOID:
      return <VoidPage height={height} width={width}></VoidPage>;

    default:
      return null;
  }
};

const PageDescription = ({ useTopLayout, pageInfo, reverse }) => {
  let pageType = '';
  let doesNotPrint = false;

  switch (pageInfo.type) {
    case PreviewLeafType.PAGE:
      pageType = `Page ${pageInfo.pageNumber}`;
      break;
    case PreviewLeafType.OUTER_COVER:
      pageType =
        pageInfo.location === Location.FRONT ? 'Front Cover' : 'Back Cover';
      doesNotPrint = true;
      break;
    case PreviewLeafType.INNER_COVER:
      pageType =
        pageInfo.location === Location.FRONT
          ? 'Front Inside Cover'
          : 'Back Inside Cover';
      doesNotPrint = true;
      break;
    case PreviewLeafType.FLYLEAF:
      pageType = 'Flyleaf';
      break;
    case PreviewLeafType.VOID:
      break;
    default:
      break;
  }

  return (
    <div
      sx={{
        mb: useTopLayout ? '0.5rem' : '0',
        textAlign: useTopLayout ? 'right' : 'left',
        width: useTopLayout ? '15em' : 'unset',
        color: doesNotPrint ? 'gray' : 'primary',
        display: 'flex',
        flexDirection: reverse ? 'row-reverse' : 'row',
      }}
    >
      <span sx={{ ml: reverse ? '0.5rem' : 0, mr: reverse ? 0 : '0.5rem' }}>
        {pageType}
      </span>{' '}
      {doesNotPrint && <Icon name="no-print" color="silver"></Icon>}
    </div>
  );
};

const MiniBookSpread = (props) => {
  const { bindingEdge, showToScale, miniBookModel, currentPage } = props;

  const [left, right] = miniBookModel.spread(currentPage);

  const leftPage = getPage(left, props);
  const rightPage = getPage(right, props);

  const useTopLayout = bindingEdge === BindingEdge.TOP;

  return (
    <div>
      <div
        sx={{
          display: 'flex',
          flexDirection: useTopLayout ? 'row' : 'column',
          pointerEvents: 'none',
        }}
      >
        <div
          sx={{
            display: 'flex',
            flexDirection: useTopLayout ? 'column' : 'row',
            justifyContent: useTopLayout ? 'center' : 'space-between',
            mb: useTopLayout ? '0' : '0.5rem',
            mr: useTopLayout ? '1rem' : '0',
            variant: 'text.body',
            color: 'green',
          }}
        >
          <PageDescription
            useTopLayout={useTopLayout}
            pageInfo={left}
          ></PageDescription>
          <PageDescription
            useTopLayout={useTopLayout}
            pageInfo={right}
            reverse={true}
          ></PageDescription>
        </div>
        <div
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {/* <RingsOverlay
          height={height}
          width={width}
          showToScale={showToScale}
          bindingEdge={bindingEdge}
        /> */}
          <MiniBookSpreadStyle
            showToScale={showToScale}
            bindingEdge={bindingEdge}
            showShadow={
              left.type !== PreviewLeafType.VOID &&
              right.type !== PreviewLeafType.VOID
            }
          >
            {leftPage}
            {rightPage}
          </MiniBookSpreadStyle>
        </div>
      </div>
      <MiniBookNav {...props}></MiniBookNav>
    </div>
  );
};

export default MiniBookSpread;
