/** @jsx jsx */
import { jsx } from 'theme-ui';
import React, { useState } from 'react';

import { Modal, ModalTitle, ModalContent, ModalFooter } from './';

import Select from '../Input/Select';
import { ColorOptions, PatternOptions } from '../../../constants';
import Square from '../Sidebar/Square';
import PatternGallery from '../Gallery/PatternGallery';
import PatternTile from '../Sidebar/PatternTile';

const PatternDialog = (props) => {
  const [open, setOpen] = useState(props.open);
  const [color, setColor] = useState(props.color || ColorOptions.BLACK);
  const [pattern, setPattern] = useState(props.pattern || PatternOptions.NONE);

  const [selectedItem, setSelectedItem] = useState(props.selectedItem);

  return (
    <>
      <div onClick={() => setOpen(true)} width={218}>
        {props.children}
      </div>
      <Modal open={open} onClose={() => setOpen(false)}>
        {({ onClose }) => (
          <>
            <ModalTitle title="Select Pattern">
              <Select
                options={ColorOptions}
                value={color}
                onChange={(e) => {
                  setColor(e.target.value);
                }}
              >
                Color
              </Select>
            </ModalTitle>
            <ModalContent>
              <PatternGallery
                color={color}
                onSelectItem={setSelectedItem}
                selectedItem={selectedItem}
              ></PatternGallery>
            </ModalContent>
            <ModalFooter
              onCommit={() => {
                setColor(selectedItem.color);
                setPattern(selectedItem.pattern);
                props.onSelectItem(selectedItem);
                onClose();
              }}
              onClose={onClose}
            ></ModalFooter>
          </>
        )}
      </Modal>
    </>
  );
};
export default PatternDialog;
