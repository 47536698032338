import React from 'react';
import { BindingModes } from '../../../../constants';

function ThumbnailLetterTallPortrait({
  bindingMode,
  color = '#000',
  colorOpacity = '0.02',
  bindingColor = '#F25546',
  strokeColorOpacity = '0.2',
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="321"
      height="322"
      viewBox="0 0 321 322"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g transform="translate(128 77)">
          <path
            fill={color}
            fillOpacity={colorOpacity}
            stroke={color}
            strokeOpacity={strokeColorOpacity}
            strokeWidth="2"
            d="M65 167L1 167 1 1 65 1z"
          ></path>
          <g fill={bindingColor} transform="translate(-7)">
            {bindingMode === BindingModes.SIGNATURE && (
              <g>
                <path d="M7 15H14V17H7z"></path>
                <path d="M7 115H14V117H7z"></path>
                <path d="M7 33H14V35H7z"></path>
                <path d="M7 133H14V135H7z"></path>
                <path d="M7 51H14V53H7z"></path>
                <path d="M7 151H14V153H7z"></path>
              </g>
            )}
            {bindingMode === BindingModes.DISC && (
              <g>
                <rect width="14" height="4" x="0" y="154" rx="2"></rect>
                <rect width="14" height="4" x="0" y="138" rx="2"></rect>
                <rect width="14" height="4" x="0" y="122" rx="2"></rect>
                <rect width="14" height="4" x="0" y="106" rx="2"></rect>
                <rect width="14" height="4" x="0" y="90" rx="2"></rect>
                <rect width="14" height="4" x="0" y="74" rx="2"></rect>
                <rect width="14" height="4" x="0" y="58" rx="2"></rect>
                <rect width="14" height="4" x="0" y="42" rx="2"></rect>
                <rect width="14" height="4" x="0" y="26" rx="2"></rect>
                <rect width="14" height="4" x="0" y="10" rx="2"></rect>
              </g>
            )}
            {bindingMode === BindingModes.JAPANESE && (
              <g>
                <path d="M7 13H14V15H7z"></path>
                <path d="M7 59H14V61H7z"></path>
                <path d="M7 105H14V107H7z"></path>
                <path d="M7 151H14V153H7z"></path>
                <path d="M12 0H14V168H12z"></path>
              </g>
            )}
            {bindingMode === BindingModes.SADDLE && (
              <g>
                <path d="M6 4H9V164H6z"></path>
              </g>
            )}
            {bindingMode === BindingModes.SPIRAL && (
              <g>
                <path d="M4 163H10V165H4z"></path>
                <path d="M4 155H10V157H4z"></path>
                <path d="M4 147H10V149H4z"></path>
                <path d="M4 139H10V141H4z"></path>
                <path d="M4 131H10V133H4z"></path>
                <path d="M4 123H10V125H4z"></path>
                <path d="M4 115H10V117H4z"></path>
                <path d="M4 107H10V109H4z"></path>
                <path d="M4 99H10V101H4z"></path>
                <path d="M4 91H10V93H4z"></path>
                <path d="M4 83H10V85H4z"></path>
                <path d="M4 75H10V77H4z"></path>
                <path d="M4 67H10V69H4z"></path>
                <path d="M4 59H10V61H4z"></path>
                <path d="M4 51H10V53H4z"></path>
                <path d="M4 43H10V45H4z"></path>
                <path d="M4 35H10V37H4z"></path>
                <path d="M4 27H10V29H4z"></path>
                <path d="M4 19H10V21H4z"></path>
                <path d="M4 11H10V13H4z"></path>
                <path d="M4 3H10V5H4z"></path>
              </g>
            )}
          </g>
        </g>
      </g>
    </svg>
  );
}

export default ThumbnailLetterTallPortrait;
