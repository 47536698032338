import { CutOrientation, PagesPerSheet, SheetSizeOptions } from '../constants';

import { capitalize } from 'humanize-plus';

export const sizeDescription = ({
  pagesPerSheet,
  sheetSize,
  cutOrientation,
}) => {
  if (
    pagesPerSheet === PagesPerSheet.TWO &&
    sheetSize === SheetSizeOptions.A4 &&
    cutOrientation === CutOrientation.SHORT
  ) {
    return 'A5';
  }

  return `${pagesPerSheet === PagesPerSheet.TWO ? 'Half' : ''} ${capitalize(
    sheetSize
  )} ${
    pagesPerSheet === PagesPerSheet.TWO &&
    cutOrientation === CutOrientation.LONG
      ? '(long)'
      : ''
  }`;
};
