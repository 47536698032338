/** @jsx jsx */
import { jsx, Styled, useThemeUI } from 'theme-ui';

import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import NotebookTab from './NotebookTab';
import PageNumberingTab from './PageNumberingTab';
import PatternTab from './PatternTab';
import FinishesTab from './FinishesTab';
import Icon from '../../components/IconFactory';
import ReactTooltip from 'react-tooltip';
import PrintPreviewTab from './PrintPreviewTab';
import { BindingModes } from '../../../constants';

import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
  bindingModeState,
  selectedTabState,
  showPrintPreviewState,
} from '../../recoil';

const MyTab = ({ title, icon, children, ...otherProps }) => {
  const { theme } = useThemeUI();

  return (
    <Tab {...otherProps}>
      <a data-tip data-for={title}>
        {icon}
      </a>
      <ReactTooltip
        place="left"
        type="dark"
        effect="solid"
        id={title}
        delayShow={200}
        backgroundColor={theme.colors.darkGreen}
      >
        {title}
      </ReactTooltip>
    </Tab>
  );
};

MyTab.tabsRole = 'Tab';

const MyTabList = ({ children, ...otherProps }) => (
  <TabList {...otherProps}>{children}</TabList>
);

MyTabList.tabsRole = 'TabsList';

const tabWidth = 44;

const Sidebar = ({ width }) => {
  const bindingMode = useRecoilValue(bindingModeState);
  const [selectedTab, setSelectedTab] = useRecoilState(selectedTabState);
  const setShowPrintPreview = useSetRecoilState(showPrintPreviewState);

  return (
    <div
      sx={{
        flexGrow: 0,
        position: 'fixed',
        right: 0,
        height: '100%',
        variant: 'printHide',
        '.react-tabs': {
          display: 'flex',
          alignItems: 'stretch',
          height: '100%',
        },

        '.react-tabs__tab-list': {
          position: 'absolute',
          left: `-${tabWidth}px`,
        },

        '.react-tabs__tab': {
          bg: 'white',
          height: tabWidth,
          width: tabWidth,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          cursor: 'pointer',
          svg: {
            fill: 'darkGreen',
          },
        },
        '.react-tabs__tab--selected': {
          bg: 'primary',
          svg: {
            fill: 'white',
          },
        },

        '.react-tabs__tab-panel.react-tabs__tab-panel--selected': {
          width: width,
        },
      }}
    >
      <Tabs
        selectedIndex={selectedTab}
        onSelect={(index) => {
          // TODO: refactor this code. no magic numbers
          if (bindingMode === BindingModes.NONE) {
            setShowPrintPreview(index === 2);
          } else {
            setShowPrintPreview(index === 4);
          }
          setSelectedTab(index);
        }}
      >
        <div
          sx={{
            position: 'relative',
          }}
        >
          <TabList>
            <MyTab title="Notebook" icon={<Icon name="notebook" />}></MyTab>
            <MyTab title="Pattern" icon={<Icon name="pattern" />}></MyTab>
            {bindingMode !== BindingModes.NONE && (
              <MyTab
                title="Page Numbering"
                icon={<Icon name="pageNumbering" />}
              ></MyTab>
            )}
            {bindingMode !== BindingModes.NONE && (
              <MyTab title="Finishes" icon={<Icon name="finishes" />}></MyTab>
            )}
            <MyTab title="Print Preview" icon={<Icon name="print" />}></MyTab>
          </TabList>
        </div>
        <TabPanel>
          <NotebookTab></NotebookTab>
        </TabPanel>
        <TabPanel>
          <PatternTab></PatternTab>
        </TabPanel>
        {bindingMode !== BindingModes.NONE && (
          <TabPanel>
            <PageNumberingTab></PageNumberingTab>
          </TabPanel>
        )}
        {bindingMode !== BindingModes.NONE && (
          <TabPanel>
            <FinishesTab></FinishesTab>
          </TabPanel>
        )}
        <TabPanel>
          <PrintPreviewTab></PrintPreviewTab>
        </TabPanel>
      </Tabs>
    </div>
  );
};

export default Sidebar;
