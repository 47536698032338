/** @jsx jsx */
import { jsx } from 'theme-ui';

import { ModalContent as MC } from '@mattjennings/react-modal';

const ModalContent = ({ children }) => (
  <MC>
    <div
      sx={{
        overflowY: 'auto',
      }}
    >
      {children}
    </div>
  </MC>
);

export default ModalContent;
