import React from 'react';

import styled from 'styled-components';

import ContainerDimensions from 'react-container-dimensions';

import {
  PatternOptions,
  ColorOptions,
  DefaultOpacityByColor,
} from '../../../constants';

/**
 * The following approaches do NOT work when trying to create a printable page pattern:

    - Inline SVG: with and without <pattern>
    - CSS SVG background
    - CSS background: linear-gradient
    - CSS background: small, repeating tiles
 * @type {[type]}
 */
const StyledRegion = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  border: 0;
  margin: 0;
  ${(props) =>
    props.pattern && props.pattern !== PatternOptions.NONE
      ? `background-image: url(${process.env.PUBLIC_URL}/patterns/${
          props.isRotated
            ? `${props.pattern}_landscape${
                props.color !== ColorOptions.BLACK ? `_${props.color}` : ''
              }.png`
            : `${props.pattern}_portrait${
                props.color !== ColorOptions.BLACK ? `_${props.color}` : ''
              }.png`
        });`
      : ''}

  background-size: ${(props) =>
    props.shouldFillPage
      ? `${(1169 * 72) / 75}px}`
      : 'unset'}; /* TODO: assumes max size of A4, scale down from 75 artboaord to 72 dpi */
  opacity: ${(props) => (props.shouldFillPage ? props.opacity : 1)};
  background-position-y: ${(props) =>
    props.shouldFillPage ? (props.isRotated ? 'bottom' : 'top') : 'center'};
  background-position-x: ${(props) =>
    props.shouldFillPage
      ? props.isRotated
        ? 'left'
        : 'left'
      : props.isRotated
      ? 'left'
      : 'center'};
  background-repeat: ${(props) =>
    props.shouldFillPage ? 'unset' : 'no-repeat'}

  /* https://stackoverflow.com/questions/6670151/how-can-i-force-browsers-to-print-background-images-in-css */
  -webkit-print-color-adjust: exact !important; /* Chrome, Safari */
  color-adjust: exact !important; /*Firefox*/

  @media print {
    break-before: page;
  }
`;

const Region = ({
  pattern,
  isRotated,
  color = ColorOptions.BLACK,
  opacity = DefaultOpacityByColor[color],
  shouldFillPage = true,
}) => (
  <ContainerDimensions>
    <StyledRegion
      pattern={pattern}
      isRotated={isRotated}
      opacity={opacity}
      color={color}
      shouldFillPage={shouldFillPage}
    ></StyledRegion>
  </ContainerDimensions>
);
export default Region;
