import React from 'react';
import styled from 'styled-components';

import {
  SheetSizeOptions,
  SheetSizeDimensions,
  PageOrientation,
  PagesPerSheet,
  BindingEdge,
} from '../../../constants';

const ring_length = 24;
const ring_width = 6;

const Ring = styled.div`
  border: 1px darkgray solid;
  background: gray;
  height: ${(props) =>
    props.bindingEdge === BindingEdge.LEFT
      ? `${ring_width}px`
      : `${ring_length}px`};
  width: ${(props) =>
    props.bindingEdge === BindingEdge.LEFT
      ? `${ring_length}px`
      : `${ring_width}px`};
  border-radius: 4px;
`;

const Rings = styled.div`
  display: flex;
  flex-direction: ${(props) =>
    props.bindingEdge === BindingEdge.LEFT ? 'column' : 'row'};
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: space-around;
`;

const RingsContainer = styled.div`
  height: ${(props) =>
    props.bindingEdge === BindingEdge.LEFT ? props.height : props.height * 2}px;
  width: ${(props) =>
    props.bindingEdge === BindingEdge.LEFT ? props.width * 2 : props.width}px;
  position: absolute;
  transform: ${(props) => (props.showToScale ? 'scale(0.50)' : 'none')};
  z-index: 2;
`;

const RingsControl = ({ height, width, bindingEdge, showToScale }) => (
  <RingsContainer
    height={height}
    width={width}
    bindingEdge={bindingEdge}
    showToScale={showToScale}
  >
    <Rings bindingEdge={bindingEdge}>
      <Ring bindingEdge={bindingEdge}></Ring>
      <Ring bindingEdge={bindingEdge}></Ring>
      <Ring bindingEdge={bindingEdge}></Ring>
    </Rings>
  </RingsContainer>
);

export default RingsControl;
