import {
  DEFAULT_PATTERN,
  DEFAULT_COLOR,
  DefaultOpacityByColor,
} from '../../../constants';

export default class BookDescriptor {
  // todo: probably really dirty coding: always setting the first item to default/null/unused.
  #patterns = [DEFAULT_PATTERN]; // null just so that page numbers can line up with array index
  #colors = [DEFAULT_COLOR]; // will be ignored
  #opacities = [DefaultOpacityByColor[DEFAULT_COLOR]]; // first will be ignored
  #titles = [null]; // first will be ignored
  #arePatternsCustom = [null]; // first will be ignored

  #descriptor = [];
  constructor(descriptor) {
    this.#descriptor = descriptor;

    descriptor.forEach((item) => {
      let chunkLength = item.count ? item.count : item.to - item.from + 1;

      let patternChunk = Array.from({ length: chunkLength }, (_, i) =>
        item.hasOwnProperty('pattern') ? item.pattern : DEFAULT_PATTERN
      );
      this.#patterns = this.#patterns.concat(patternChunk);

      let colorChunk = Array.from({ length: chunkLength }, (_, i) =>
        item.hasOwnProperty('color') ? item.color : DEFAULT_COLOR
      );
      this.#colors = this.#colors.concat(colorChunk);

      let opacityChunk = Array.from({ length: chunkLength }, (_, i) =>
        item.hasOwnProperty('opacity')
          ? item.opacity
          : DefaultOpacityByColor[
              item.hasOwnProperty('color') ? item.color : DEFAULT_COLOR
            ]
      );
      this.#opacities = this.#opacities.concat(opacityChunk);

      let titleChunk = Array.from({ length: chunkLength }, (_, i) =>
        item.hasOwnProperty('title') ? item.title : null
      );
      this.#titles = this.#titles.concat(titleChunk);

      let arePatternsCustomChunk = Array.from({ length: chunkLength }, (_, i) =>
        item.hasOwnProperty('customPattern') ? item.customPattern : false
      );

      this.#arePatternsCustom = this.#arePatternsCustom.concat(
        arePatternsCustomChunk
      );
    });
  }

  /**
   * Get a look-up table of patterns
   * @return {Array} Note: first item is meant to be ignored, so index can match up with page numbers
   */
  get patterns() {
    return this.#patterns;
  }

  /**
   * Get a look-up table of colors
   * @return {Array} Note: first item is meant to be ignored, so index can match up with page numbers
   */
  get colors() {
    return this.#colors;
  }

  /**
   * get a look-up table of opacities
   * @return {Array} Note: first item is meant to be ignored, so index can match up with page numbers
   */
  get opacities() {
    return this.#opacities;
  }

  /**
   * get a look-up table of titles
   * @return {Array} Note: first item is meant to be ignored, so index can match up with page numbers
   */
  get titles() {
    return this.#titles;
  }

  /**
   * get whether patterns are custom
   * @return {Array} Note: first item is meant to be ignored, so index can match up with page numbers
   */
  get arePatternsCustom() {
    return this.#arePatternsCustom;
  }

  get items() {
    return this.#descriptor;
  }

  get count() {
    return this.#patterns.length;
  }
}
