/** @jsx jsx */
import { jsx, Styled } from 'theme-ui';
import React from 'react';

import gridEuropean from './images/sample-grid-european.svg';
import gridIsometric from './images/sample-grid-isometric.svg';
import gridSketcherDot from './images/sample-grid-sketcher-dot.svg';
import gridWriter from './images/sample-grid-writer.svg';
import lineGraph from './images/sample-line-graph.svg';
import lineIsometric from './images/sample-line-isometric.svg';
import line from './images/sample-line.svg';

import { PatternOptions } from '../../constants';

const PatternSwatch = ({ patternUrl, width }) => (
  <div
    sx={{
      height: width,
      width: width,
      background: `url(${patternUrl})`,
      backgroundSize: '200%',
    }}
  />
);

const PatternFactory = (patterns) => {
  const Wrapper = ({ name, width }) => {
    return <PatternSwatch patternUrl={patterns[name]} width={width} />;
  };
  return Wrapper;
};

const PatternUrls = {
  [PatternOptions.LINE]: line,
  [PatternOptions.GRID_SKETCHER]: gridSketcherDot,
  [PatternOptions.GRID_WRITER]: gridWriter,
  [PatternOptions.GRID_EUROPEAN]: gridEuropean,
  [PatternOptions.LINE_ISOMETRIC]: lineIsometric,
  [PatternOptions.GRID_ISOMETRIC]: gridIsometric,
  [PatternOptions.LINE_GRAPH]: lineGraph,
};

export { PatternUrls };

const Pattern = PatternFactory(PatternUrls);

export default Pattern;
