/** @jsx jsx */
import { jsx, Label, Styled } from 'theme-ui';
import React from 'react';

// import { keyframes } from '@emotion/react';

import {
  PagesPerSheet,
  PageOrientation,
  BindingEdge,
  CutOrientation,
  SheetSizeOptions,
  BindingModes,
} from '../../../constants';

import ThumbnailA4FullLandscape from './variants/ThumbnailA4FullLandscape';
import ThumbnailA4FullPortrait from './variants/ThumbnailA4FullPortrait';
import ThumbnailA4HalfLandscape from './variants/ThumbnailA4HalfLandscape';
import ThumbnailA4HalfPortrait from './variants/ThumbnailA4HalfPortrait';
import ThumbnailA4TallLandscape from './variants/ThumbnailA4TallLandscape';
import ThumbnailA4TallPortrait from './variants/ThumbnailA4TallPortrait';

import ThumbnailLegalFullLandscape from './variants/ThumbnailLegalFullLandscape';
import ThumbnailLegalFullPortrait from './variants/ThumbnailLegalFullPortrait';
import ThumbnailLegalHalfLandscape from './variants/ThumbnailLegalHalfLandscape';
import ThumbnailLegalHalfPortrait from './variants/ThumbnailLegalHalfPortrait';
import ThumbnailLegalTallLandscape from './variants/ThumbnailLegalTallLandscape';
import ThumbnailLegalTallPortrait from './variants/ThumbnailLegalTallPortrait';

import ThumbnailLetterFullLandscape from './variants/ThumbnailLetterFullLandscape';
import ThumbnailLetterFullPortrait from './variants/ThumbnailLetterFullPortrait';
import ThumbnailLetterHalfLandscape from './variants/ThumbnailLetterHalfLandscape';
import ThumbnailLetterHalfPortrait from './variants/ThumbnailLetterHalfPortrait';
import ThumbnailLetterTallLandscape from './variants/ThumbnailLetterTallLandscape';
import ThumbnailLetterTallPortrait from './variants/ThumbnailLetterTallPortrait';

// const turnClockwise = keyframes({ from: { transform: 'rotateY(0deg)' }, to: { transform: 'rotateY(90deg)' } })

// const turnCounterClockwise = keyframes({ from: { transform: 'rotateY(180deg)' }, to: { transform: 'rotateY(90deg)' } })

// const thumbnailRotation = (bindingEdge) =>
//   bindingEdge === BindingEdge.TOP
//     ? 'rotateY(0,0,1,90deg)'
//     : bindingEdge === BindingEdge.RIGHT
//       ? 'rotate3d(0,1,0,180deg)'
//       : 'rotate3d(0,0,0,360deg)'

const thumbnailRotation = (bindingEdge) =>
  bindingEdge === BindingEdge.TOP
    ? 'rotate(90deg)'
    : bindingEdge === BindingEdge.RIGHT
    ? 'rotate(180deg)'
    : 'rotate(0deg)';

const Thumbnail = (props) => {
  const {
    sheetSize,
    pagesPerSheet,
    pageOrientation,
    bindingEdge,
    bindingMode,
    cutOrientation,
    active,
  } = props;

  if (active) {
    props = {
      ...props,
      colorOpacity: bindingMode === BindingModes.NONE ? 0 : 0.075,
      strokeColorOpacity: 0.33,
    };
  }

  let chosenThumbnail;

  switch (sheetSize) {
    case SheetSizeOptions.A4:
      switch (pagesPerSheet) {
        case PagesPerSheet.ONE:
          switch (bindingEdge) {
            case BindingEdge.TOP:
              switch (pageOrientation) {
                case PageOrientation.PORTRAIT:
                  chosenThumbnail = <ThumbnailA4FullLandscape {...props} />;
                  break;
                case PageOrientation.LANDSCAPE:
                  chosenThumbnail = <ThumbnailA4FullPortrait {...props} />;
                  break;
              }
              break;

            case BindingEdge.LEFT:
              switch (pageOrientation) {
                case PageOrientation.PORTRAIT:
                  chosenThumbnail = <ThumbnailA4FullPortrait {...props} />;
                  break;
                case PageOrientation.LANDSCAPE:
                  chosenThumbnail = <ThumbnailA4FullLandscape {...props} />;
                  break;
              }
              break;

            default:
              break;
          }
          break;
        case PagesPerSheet.TWO:
          switch (cutOrientation) {
            case CutOrientation.SHORT:
              switch (bindingEdge) {
                case BindingEdge.TOP:
                  switch (pageOrientation) {
                    case PageOrientation.PORTRAIT:
                      chosenThumbnail = <ThumbnailA4HalfLandscape {...props} />;
                      break;
                    case PageOrientation.LANDSCAPE:
                      chosenThumbnail = <ThumbnailA4HalfPortrait {...props} />;
                      break;
                  }
                  break;

                case BindingEdge.LEFT:
                case BindingEdge.RIGHT:
                  switch (pageOrientation) {
                    case PageOrientation.PORTRAIT:
                      chosenThumbnail = <ThumbnailA4HalfPortrait {...props} />;
                      break;
                    case PageOrientation.LANDSCAPE:
                      chosenThumbnail = <ThumbnailA4HalfLandscape {...props} />;
                      break;
                  }
                  break;

                default:
                  break;
              }

              break;

            case CutOrientation.LONG:
              switch (bindingEdge) {
                case BindingEdge.TOP:
                  switch (pageOrientation) {
                    case PageOrientation.PORTRAIT:
                      chosenThumbnail = <ThumbnailA4TallLandscape {...props} />;
                      break;
                    case PageOrientation.LANDSCAPE:
                      chosenThumbnail = <ThumbnailA4TallPortrait {...props} />;
                      break;
                  }
                  break;

                case BindingEdge.LEFT:
                case BindingEdge.RIGHT:
                  switch (pageOrientation) {
                    case PageOrientation.PORTRAIT:
                      chosenThumbnail = <ThumbnailA4TallPortrait {...props} />;
                      break;
                    case PageOrientation.LANDSCAPE:
                      chosenThumbnail = <ThumbnailA4TallLandscape {...props} />;
                      break;
                  }
                  break;

                default:
                  break;
              }
              break;

            default:
              break;
          }
          break;
        default:
          break;
      }
      break;

    case SheetSizeOptions.LETTER:
      switch (pagesPerSheet) {
        case PagesPerSheet.ONE:
          switch (bindingEdge) {
            case BindingEdge.TOP:
              switch (pageOrientation) {
                case PageOrientation.PORTRAIT:
                  chosenThumbnail = <ThumbnailLetterFullLandscape {...props} />;
                  break;
                case PageOrientation.LANDSCAPE:
                  chosenThumbnail = <ThumbnailLetterFullPortrait {...props} />;
                  break;
              }
              break;

            case BindingEdge.LEFT:
            case BindingEdge.RIGHT:
              switch (pageOrientation) {
                case PageOrientation.PORTRAIT:
                  chosenThumbnail = <ThumbnailLetterFullPortrait {...props} />;
                  break;
                case PageOrientation.LANDSCAPE:
                  chosenThumbnail = <ThumbnailLetterFullLandscape {...props} />;
                  break;
              }
              break;

            default:
              break;
          }
          break;
        case PagesPerSheet.TWO:
          switch (cutOrientation) {
            case CutOrientation.SHORT:
              switch (bindingEdge) {
                case BindingEdge.TOP:
                  switch (pageOrientation) {
                    case PageOrientation.PORTRAIT:
                      chosenThumbnail = (
                        <ThumbnailLetterHalfLandscape {...props} />
                      );
                      break;
                    case PageOrientation.LANDSCAPE:
                      chosenThumbnail = (
                        <ThumbnailLetterHalfPortrait {...props} />
                      );
                      break;
                  }
                  break;

                case BindingEdge.LEFT:
                case BindingEdge.RIGHT:
                  switch (pageOrientation) {
                    case PageOrientation.PORTRAIT:
                      chosenThumbnail = (
                        <ThumbnailLetterHalfPortrait {...props} />
                      );
                      break;
                    case PageOrientation.LANDSCAPE:
                      chosenThumbnail = (
                        <ThumbnailLetterHalfLandscape {...props} />
                      );
                      break;
                  }
                  break;

                default:
                  break;
              }

              break;

            case CutOrientation.LONG:
              switch (bindingEdge) {
                case BindingEdge.TOP:
                  switch (pageOrientation) {
                    case PageOrientation.PORTRAIT:
                      chosenThumbnail = (
                        <ThumbnailLetterTallLandscape {...props} />
                      );
                      break;
                    case PageOrientation.LANDSCAPE:
                      chosenThumbnail = (
                        <ThumbnailLetterTallPortrait {...props} />
                      );
                      break;
                  }
                  break;

                case BindingEdge.LEFT:
                case BindingEdge.RIGHT:
                  switch (pageOrientation) {
                    case PageOrientation.PORTRAIT:
                      chosenThumbnail = (
                        <ThumbnailLetterTallPortrait {...props} />
                      );
                      break;
                    case PageOrientation.LANDSCAPE:
                      chosenThumbnail = (
                        <ThumbnailLetterTallLandscape {...props} />
                      );
                      break;
                  }
                  break;

                default:
                  break;
              }
              break;

            default:
              break;
          }
          break;
        default:
          break;
      }
      break;

    case SheetSizeOptions.LEGAL:
      switch (pagesPerSheet) {
        case PagesPerSheet.ONE:
          switch (bindingEdge) {
            case BindingEdge.TOP:
              switch (pageOrientation) {
                case PageOrientation.PORTRAIT:
                  chosenThumbnail = <ThumbnailLegalFullLandscape {...props} />;
                  break;
                case PageOrientation.LANDSCAPE:
                  chosenThumbnail = <ThumbnailLegalFullPortrait {...props} />;
                  break;
              }
              break;

            case BindingEdge.LEFT:
            case BindingEdge.RIGHT:
              switch (pageOrientation) {
                case PageOrientation.PORTRAIT:
                  chosenThumbnail = <ThumbnailLegalFullPortrait {...props} />;
                  break;
                case PageOrientation.LANDSCAPE:
                  chosenThumbnail = <ThumbnailLegalFullLandscape {...props} />;
                  break;
              }
              break;

            default:
              break;
          }
          break;
        case PagesPerSheet.TWO:
          switch (cutOrientation) {
            case CutOrientation.SHORT:
              switch (bindingEdge) {
                case BindingEdge.TOP:
                  switch (pageOrientation) {
                    case PageOrientation.PORTRAIT:
                      chosenThumbnail = (
                        <ThumbnailLegalHalfLandscape {...props} />
                      );
                      break;
                    case PageOrientation.LANDSCAPE:
                      chosenThumbnail = (
                        <ThumbnailLegalHalfPortrait {...props} />
                      );
                      break;
                  }
                  break;

                case BindingEdge.LEFT:
                case BindingEdge.RIGHT:
                  switch (pageOrientation) {
                    case PageOrientation.PORTRAIT:
                      chosenThumbnail = (
                        <ThumbnailLegalHalfPortrait {...props} />
                      );
                      break;
                    case PageOrientation.LANDSCAPE:
                      chosenThumbnail = (
                        <ThumbnailLegalHalfLandscape {...props} />
                      );
                      break;
                  }
                  break;

                default:
                  break;
              }

              break;

            case CutOrientation.LONG:
              switch (bindingEdge) {
                case BindingEdge.TOP:
                  switch (pageOrientation) {
                    case PageOrientation.PORTRAIT:
                      chosenThumbnail = (
                        <ThumbnailLegalTallLandscape {...props} />
                      );
                      break;
                    case PageOrientation.LANDSCAPE:
                      chosenThumbnail = (
                        <ThumbnailLegalTallPortrait {...props} />
                      );
                      break;
                  }
                  break;

                case BindingEdge.LEFT:
                case BindingEdge.RIGHT:
                  switch (pageOrientation) {
                    case PageOrientation.PORTRAIT:
                      chosenThumbnail = (
                        <ThumbnailLegalTallPortrait {...props} />
                      );
                      break;
                    case PageOrientation.LANDSCAPE:
                      chosenThumbnail = (
                        <ThumbnailLegalTallLandscape {...props} />
                      );
                      break;
                  }
                  break;

                default:
                  break;
              }
              break;

            default:
              break;
          }
          break;
        default:
          break;
      }
      break;
    default:
      break;
  }

  return (
    <div
      sx={{
        transition: 'transform 400ms',
        transform: `${thumbnailRotation(bindingEdge)}`,
        // animationName: bindingEdge === BindingEdge.RIGHT ? turnCounterClockwise : turnClockwise,
        maxWidth: '100%',
        svg: {
          width: '100%',
        },
      }}
    >
      {chosenThumbnail}
    </div>
  );
};

export default Thumbnail;
