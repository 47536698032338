import React from 'react';
import { useRecoilValue } from 'recoil';
import { getPageDimensions } from '.';
import { calculatePageSize } from '../../../utils/page';
import {
  colorState,
  cutOrientationState,
  opacityState,
  pageOrientationState,
  pagesPerSheetState,
  pageStyleState,
  patternState,
  sheetSizeState,
} from '../../recoil';
import MiniPage from './MiniPage';

// height,
//   width,
//   pageNumber,
//   pattern,
//   pageStyle,
//   hasAlternateAlign,
//   opacity,
//   color,
//   pageNumberingEnabled,

const MiniPageStandalone = ({ showToScale }) => {
  const pageOrientation = useRecoilValue(pageOrientationState);
  const pagesPerSheet = useRecoilValue(pagesPerSheetState);
  const cutOrientation = useRecoilValue(cutOrientationState);

  const sheetSize = useRecoilValue(sheetSizeState);

  const pattern = useRecoilValue(patternState);
  const color = useRecoilValue(colorState);
  const opacity = useRecoilValue(opacityState);

  const pageStyle = useRecoilValue(pageStyleState);

  const hasAlternateAlign = false;
  const pageNumberingEnabled = false;
  const pageNumber = 1;

  const sheetDimensions = calculatePageSize(
    sheetSize,
    pageOrientation,
    pagesPerSheet,
    cutOrientation
  );

  const { height, width } = getPageDimensions(
    sheetSize,
    sheetDimensions,
    showToScale
  );

  return (
    <MiniPage
      {...{
        height,
        width,
        pageNumber,
        pattern,
        pageStyle,
        hasAlternateAlign,
        opacity,
        color,
        pageNumberingEnabled,
      }}
    ></MiniPage>
  );
};

export default MiniPageStandalone;
