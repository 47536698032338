import React from 'react';
import { BindingModes } from '../../../../constants';

function ThumbnailLetterFullLandscape({
  bindingMode,
  color = '#000',
  colorOpacity = '0.02',
  bindingColor = '#F25546',
  strokeColorOpacity = '0.2',
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="321"
      height="322"
      viewBox="0 0 321 322"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g transform="translate(77 96)">
          <path
            fill={color}
            fillOpacity={colorOpacity}
            stroke={color}
            strokeOpacity={strokeColorOpacity}
            strokeWidth="2"
            d="M20 -18H148V148H20z"
            transform="rotate(90 84 65)"
          ></path>
          <g fill={bindingColor} transform="translate(-7)">
            {bindingMode === BindingModes.SIGNATURE && (
              <g>
                <path d="M7 11.381H14V13.381H7z"></path>
                <path d="M7 88.762H14V90.762H7z"></path>
                <path d="M7 25.31H14V27.31H7z"></path>
                <path d="M7 102.69H14V104.69H7z"></path>
                <path d="M7 39.238H14V41.238H7z"></path>
                <path d="M7 116.619H14V118.619H7z"></path>
              </g>
            )}
            {bindingMode === BindingModes.DISC && (
              <g>
                <rect width="14" height="4" x="0" y="86.214" rx="2"></rect>
                <rect width="14" height="4" x="0" y="70.738" rx="2"></rect>
                <rect width="14" height="4" x="0" y="55.262" rx="2"></rect>
                <rect width="14" height="4" x="0" y="39.786" rx="2"></rect>
                <rect width="14" height="4" x="0" y="24.31" rx="2"></rect>
                <rect width="14" height="4" x="0" y="117.167" rx="2"></rect>
                <rect width="14" height="4" x="0" y="101.69" rx="2"></rect>
                <rect width="14" height="4" x="0" y="8.833" rx="2"></rect>
              </g>
            )}
            {bindingMode === BindingModes.JAPANESE && (
              <g>
                <path d="M7 9.833H14V11.833H7z"></path>
                <path d="M7 45.429H14V47.429H7z"></path>
                <path d="M7 81.024H14V83.024H7z"></path>
                <path d="M7 116.619H14V118.619H7z"></path>
                <path d="M12 0H14V130H12z"></path>
              </g>
            )}
            {bindingMode === BindingModes.SADDLE && (
              <g>
                <path d="M6 3.095H9V126.905H6z"></path>
              </g>
            )}
            {bindingMode === BindingModes.SPIRAL && (
              <g>
                <path d="M4 125.905H10V127.905H4z"></path>
                <path d="M4 119.714H10V121.714H4z"></path>
                <path d="M4 113.524H10V115.524H4z"></path>
                <path d="M4 107.333H10V109.333H4z"></path>
                <path d="M4 101.143H10V103.143H4z"></path>
                <path d="M4 94.952H10V96.952H4z"></path>
                <path d="M4 88.762H10V90.762H4z"></path>
                <path d="M4 82.571H10V84.571H4z"></path>
                <path d="M4 76.381H10V78.381H4z"></path>
                <path d="M4 70.19H10V72.19H4z"></path>
                <path d="M4 64H10V66H4z"></path>
                <path d="M4 57.81H10V59.81H4z"></path>
                <path d="M4 51.619H10V53.619H4z"></path>
                <path d="M4 45.429H10V47.429H4z"></path>
                <path d="M4 39.238H10V41.238H4z"></path>
                <path d="M4 33.048H10V35.048H4z"></path>
                <path d="M4 26.857H10V28.857H4z"></path>
                <path d="M4 20.667H10V22.667H4z"></path>
                <path d="M4 14.476H10V16.476H4z"></path>
                <path d="M4 8.286H10V10.286H4z"></path>
                <path d="M4 2.095H10V4.095000000000001H4z"></path>
              </g>
            )}
          </g>
        </g>
      </g>
    </svg>
  );
}

export default ThumbnailLetterFullLandscape;
