import React from 'react';
import styled from 'styled-components';

const Helmet = (
  <link
    href="https://fonts.googleapis.com/css?family=Fira+Sans:400,700&display=swap"
    rel="stylesheet"
  />
);

const PageContent = styled.div`
  flex: auto;
  margin-top: ${(props) => props.paddingTop}pt;
  margin-right: ${(props) => props.paddingRight}pt;
  margin-bottom: ${(props) => props.paddingBottom}pt;
  margin-left: ${(props) => props.paddingLeft}pt;
`;

const PageNumber = styled.div`
  font-family: 'Fira Sans', sans-serif;
  color: rgba(0, 0, 0, 0.2);
  font-size: 24pt;
  font-weight: 700;
  flex: 0 0;
  padding-top: ${(props) => props.padding * (props.isRotated ? 1 : 0.5)}pt;
  padding-bottom: ${(props) => props.padding * (props.isRotated ? 1 : 0.5)}pt;
  padding-left: ${(props) => props.padding * (props.isRotated ? 0.5 : 1)}pt;
  padding-right: ${(props) => props.padding * (props.isRotated ? 0.5 : 1)}pt;
  text-align: ${(props) => (props.hasAlternateAlign ? 'left' : 'right')};
  transform: ${(props) => (props.isRotated ? 'rotate(180deg)' : 'initial')};
`;

const StyledPageMeta = styled.div`
  font-family: 'Fira Sans', sans-serif;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  padding: 16pt;
  flex-direction: column;
  justify-content: space-between;
`;

const PageMeta = ({
  pageTitle,
  pageNumber,
  padding,
  isRotated,
  hasAlternateAlign,
  pageNumberingEnabled,
}) => (
  <StyledPageMeta>
    {pageTitle}
    {pageNumberingEnabled && (
      <PageNumber
        padding={padding}
        isRotated={isRotated}
        hasAlternateAlign={hasAlternateAlign}
      >
        {pageNumber}
      </PageNumber>
    )}
  </StyledPageMeta>
);

export const Components = {
  Helmet,
  PageMeta,
  PageContent,
};

export default Components;
