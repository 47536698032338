import React from 'react';
import { BindingModes } from '../../../../constants';

function ThumbnailLegalHalfLandscape({
  bindingMode,
  color = '#000',
  colorOpacity = '0.02',
  bindingColor = '#F25546',
  strokeColorOpacity = '0.2',
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="321"
      height="322"
      viewBox="0 0 321 322"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g transform="translate(96 108)">
          <path
            fill={color}
            fillOpacity={colorOpacity}
            stroke={color}
            strokeOpacity={strokeColorOpacity}
            strokeWidth="2"
            d="M-1.42108547e-14 1.42108547e-14L129 1.42108547e-14 129 106 -1.42108547e-14 106 -1.42108547e-14 19.1667683z"
          ></path>
          <g fill={bindingColor} transform="translate(-8)">
            {bindingMode === BindingModes.SIGNATURE && (
              <g>
                <path d="M8 9.095H16V11.095H8z"></path>
                <path d="M8 72.19H16V74.19H8z"></path>
                <path d="M8 20.452H16V22.452H8z"></path>
                <path d="M8 83.548H16V85.548H8z"></path>
                <path d="M8 31.81H16V33.81H8z"></path>
                <path d="M8 94.905H16V96.905H8z"></path>
              </g>
            )}
            {bindingMode === BindingModes.DISC && (
              <g>
                <rect width="16" height="4" x="0" y="95.167" rx="2"></rect>
                <rect width="16" height="4" x="0" y="77.5" rx="2"></rect>
                <rect width="16" height="4" x="0" y="59.833" rx="2"></rect>
                <rect width="16" height="4" x="0" y="42.167" rx="2"></rect>
                <rect width="16" height="4" x="0" y="24.5" rx="2"></rect>
                <rect width="16" height="4" x="0" y="6.833" rx="2"></rect>
              </g>
            )}
            {bindingMode === BindingModes.JAPANESE && (
              <g>
                <path d="M8 7.833H16V9.833H8z"></path>
                <path d="M8 36.857H16V38.857H8z"></path>
                <path d="M8 65.881H16V67.881H8z"></path>
                <path d="M8 94.905H16V96.905H8z"></path>
                <path d="M13.857 0H15.857V106H13.857z"></path>
              </g>
            )}
            {bindingMode === BindingModes.SADDLE && (
              <g>
                <path d="M7.071 2.524H10.071V103.476H7.071z"></path>
              </g>
            )}
            {bindingMode === BindingModes.SPIRAL && (
              <g>
                <path d="M4.571 102.476H11.428V104.476H4.571z"></path>
                <path d="M4.571 92.381H11.428V94.381H4.571z"></path>
                <path d="M4.571 82.286H11.428V84.286H4.571z"></path>
                <path d="M4.571 72.19H11.428V74.19H4.571z"></path>
                <path d="M4.571 62.095H11.428V64.095H4.571z"></path>
                <path d="M4.571 52H11.428V54H4.571z"></path>
                <path d="M4.571 41.905H11.428V43.905H4.571z"></path>
                <path d="M4.571 31.81H11.428V33.81H4.571z"></path>
                <path d="M4.571 21.714H11.428V23.714H4.571z"></path>
                <path d="M4.571 11.619H11.428V13.619H4.571z"></path>
                <path d="M4.571 1.524H11.428V3.524H4.571z"></path>
              </g>
            )}
          </g>
        </g>
      </g>
    </svg>
  );
}

export default ThumbnailLegalHalfLandscape;
