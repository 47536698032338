import { ColorOptions } from '../constants';

const colors = {
  text: '#3b3841',
  background: '#ededed',
  primary: '#0e9594',
  secondary: '#127475',
  highlight: '#efefef',
  muted: '#d8d8d8',
  darkRed: '#a52727',
  brownRed: '#562c2c',
  red: '#f2542d',
  wheat: '#f5dfbb',
  green: '#0e9594',
  darkGreen: '#127475',
  pattern: {
    [ColorOptions.BLACK]: '#000000',
    [ColorOptions.BLUE]: '#0099ff',
    [ColorOptions.RED]: '#ff0000',
  },
};

export default colors;
