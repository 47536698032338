/** @jsx jsx */
import { jsx, Styled } from 'theme-ui';
import React, { useState } from 'react';

import { Modal, ModalContent, ModalFooter } from './';

export const GreetingModal = (props) => (
  <Modal open={props.open} onClose={() => props.setOpen(false)}>
    {({ onClose }) => (
      <div
        sx={{
          maxWidth: '54rem',
        }}
      >
        <ModalContent>
          <Styled.h2>Why hello!</Styled.h2>
          <Styled.p>
            First of all, please let me thank you &#128151; for stopping by and
            checking out this app. I've been using it for my own notebook needs,
            and I do hope it is useful to you too.
          </Styled.p>
          <Styled.h3>Current Requirements</Styled.h3>
          <Styled.p>I've assumed that you will have the following:</Styled.p>
          <Styled.ul>
            <Styled.li>
              <Styled.strong>Double-sided printer.</Styled.strong> You might be
              able to get by without, if you're just printing loose pages. This
              app won't be of much help in getting everything set up, if you're
              only able to print one side at a time.
            </Styled.li>
            <Styled.li>
              <Styled.strong>Desktop or Laptop.</Styled.strong> First, the app
              as it currently stands is optimized for bigger (non-touch)
              screens. Second, if you are actually able to print using your
              mobile device, kudos to you, since I know it can be finicky.
            </Styled.li>
          </Styled.ul>
        </ModalContent>
        <ModalFooter
          onClose={() => {
            props.onClose();
            onClose();
          }}
          primaryText="Let's get started!"
        ></ModalFooter>
      </div>
    )}
  </Modal>
);

const GreetingDialog = (props) => {
  const [open, setOpen] = useState(props.open);
  return (
    <>
      <div onClick={() => setOpen(true)}>{props.children}</div>
      <GreetingModal
        {...props}
        open={open}
        setOpen={setOpen}
      ></GreetingModal>{' '}
    </>
  );
};

export default GreetingDialog;
