/** @jsx jsx */
import { jsx, Styled } from 'theme-ui';
import React from 'react';

import SidebarTab from './SidebarTab';
import { InputContainer } from '../Input';
import Checkbox from '../Input/Checkbox';
import { flyleafState } from '../../recoil';
import { useRecoilState } from 'recoil';

const FinishesTab = (props) => {
  const [flyleaf, setFlyleaf] = useRecoilState(flyleafState);
  return (
    <SidebarTab title="Finishes">
      <InputContainer title="">
        <Checkbox
          checked={flyleaf}
          onChange={(e) => setFlyleaf(e.target.checked)}
          hint="A Flyleaf is a blank page at the beginning or end of a book. Add them if you don't want inner pages pressed against the cover."
        >
          Add Flyleaves
        </Checkbox>
      </InputContainer>
    </SidebarTab>
  );
};

export default FinishesTab;
