import React from 'react';
import { BindingModes } from '../../../../constants';

function ThumbnailLetterFullPortrait({
  bindingMode,
  color = '#000',
  colorOpacity = '0.02',
  bindingColor = '#F25546',
  strokeColorOpacity = '0.2',
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="321"
      height="322"
      viewBox="0 0 321 322"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g transform="translate(96 77)">
          <path
            fill={color}
            fillOpacity={colorOpacity}
            stroke={color}
            strokeOpacity={strokeColorOpacity}
            strokeWidth="2"
            d="M1 1L129 1 129 167 1 167 1 31.0158824z"
          ></path>
          <g fill={bindingColor} transform="translate(-8)">
            {bindingMode === BindingModes.SIGNATURE && (
              <g>
                <path d="M8 15H16V17H8z"></path>
                <path d="M8 115H16V117H8z"></path>
                <path d="M8 33H16V35H8z"></path>
                <path d="M8 133H16V135H8z"></path>
                <path d="M8 51H16V53H8z"></path>
                <path d="M8 151H16V153H8z"></path>
              </g>
            )}
            {bindingMode === BindingModes.DISC && (
              <g>
                <rect width="16" height="4" x="0" y="154" rx="2"></rect>
                <rect width="16" height="4" x="0" y="138" rx="2"></rect>
                <rect width="16" height="4" x="0" y="122" rx="2"></rect>
                <rect width="16" height="4" x="0" y="106" rx="2"></rect>
                <rect width="16" height="4" x="0" y="90" rx="2"></rect>
                <rect width="16" height="4" x="0" y="74" rx="2"></rect>
                <rect width="16" height="4" x="0" y="58" rx="2"></rect>
                <rect width="16" height="4" x="0" y="42" rx="2"></rect>
                <rect width="16" height="4" x="0" y="26" rx="2"></rect>
                <rect width="16" height="4" x="0" y="10" rx="2"></rect>
              </g>
            )}
            {bindingMode === BindingModes.JAPANESE && (
              <g>
                <path d="M8 13H16V15H8z"></path>
                <path d="M8 59H16V61H8z"></path>
                <path d="M8 105H16V107H8z"></path>
                <path d="M8 151H16V153H8z"></path>
                <path d="M13.857 0H15.857V168H13.857z"></path>
              </g>
            )}
            {bindingMode === BindingModes.SADDLE && (
              <g>
                <path d="M7.071 4H10.071V164H7.071z"></path>
              </g>
            )}
            {bindingMode === BindingModes.SPIRAL && (
              <g>
                <path d="M4.571 163H11.428V165H4.571z"></path>
                <path d="M4.571 155H11.428V157H4.571z"></path>
                <path d="M4.571 147H11.428V149H4.571z"></path>
                <path d="M4.571 139H11.428V141H4.571z"></path>
                <path d="M4.571 131H11.428V133H4.571z"></path>
                <path d="M4.571 123H11.428V125H4.571z"></path>
                <path d="M4.571 115H11.428V117H4.571z"></path>
                <path d="M4.571 107H11.428V109H4.571z"></path>
                <path d="M4.571 99H11.428V101H4.571z"></path>
                <path d="M4.571 91H11.428V93H4.571z"></path>
                <path d="M4.571 83H11.428V85H4.571z"></path>
                <path d="M4.571 75H11.428V77H4.571z"></path>
                <path d="M4.571 67H11.428V69H4.571z"></path>
                <path d="M4.571 59H11.428V61H4.571z"></path>
                <path d="M4.571 51H11.428V53H4.571z"></path>
                <path d="M4.571 43H11.428V45H4.571z"></path>
                <path d="M4.571 35H11.428V37H4.571z"></path>
                <path d="M4.571 27H11.428V29H4.571z"></path>
                <path d="M4.571 19H11.428V21H4.571z"></path>
                <path d="M4.571 11H11.428V13H4.571z"></path>
                <path d="M4.571 3H11.428V5H4.571z"></path>
              </g>
            )}
          </g>
        </g>
      </g>
    </svg>
  );
}

export default ThumbnailLetterFullPortrait;
