/** @jsx jsx */
import { jsx, Styled } from 'theme-ui';
import React from 'react';

import SidebarTab from './SidebarTab';
import { InputContainer } from '../Input';
import Field from '../Input/Field';
import {
  BindingEdge,
  BindingEdgeDescriptions,
  BindingModes,
  SheetsPerSignature,
  SidesPerSheet,
} from '../../../constants';
import Select from '../Input/Select';

import { calculatePageSize } from '../../../utils/page';

import { sizeDescription } from '../../../utils/thumbnail';

import { useRecoilState, useRecoilValue } from 'recoil';
import {
  bindingEdgeState,
  cutOrientationState,
  pageOrientationState,
  pagesPerSheetState,
  pagesState,
  bindingModeState,
  sheetSizeState,
  currentPageState,
  flyleafState,
  sheetsPerSignatureState,
} from '../../recoil';
import NotebookDialog from '../Dialogs/NotebookDialog';
import Square from './Square';
import Thumbnail from '../Thumbnail';
import RadioGroup from '../Input/RadioGroup';
import { calculateMessage } from './calculateMessage';
import experimentalFeatures from '../../experimentalFeatures';
import { adjustPagesForFlyleaf } from '../MiniBook/MiniBookModel';

export const textDimensions = ({
  pagesPerSheet,
  pageOrientation,
  sheetSize,
  cutOrientation,
}) => {
  const dims = calculatePageSize(
    sheetSize,
    pageOrientation,
    pagesPerSheet,
    cutOrientation
  );

  return (
    <>
      <Styled.strong>{dims.width}</Styled.strong> &times;{' '}
      <Styled.strong>{dims.height}</Styled.strong>
      {dims.units}
    </>
  );
};

const convertSignaturesToPages = (
  signatures,
  pagesPerSheet,
  sidesPerSheet,
  sheetsPerSignature,
  flyleaf
) => Math.ceil(signatures * pagesPerSheet * sidesPerSheet * sheetsPerSignature); // TODO: handle flyfleaf

const convertPagesToSignatures = (
  pages,
  pagesPerSheet,
  sidesPerSheet,
  sheetsPerSignature,
  flyleaf
) => Math.ceil(pages / (sidesPerSheet * pagesPerSheet * sheetsPerSignature)); // TODO: handle flyleaf

const NotebookTab = (props) => {
  // const {
  //   // bindingMode,
  //   // bindingEdge
  // } = props;
  const [pages, setPages] = useRecoilState(pagesState);
  const [currentPage, setCurrentPage] = useRecoilState(currentPageState);
  const [sheetSize, setSheetSize] = useRecoilState(sheetSizeState);
  const [pagesPerSheet, setPagesPerSheet] = useRecoilState(pagesPerSheetState);
  const [pageOrientation, setPageOrientation] =
    useRecoilState(pageOrientationState);
  const [bindingEdge, setBindingEdge] = useRecoilState(bindingEdgeState);
  const [cutOrientation, setCutOrientation] =
    useRecoilState(cutOrientationState);

  const [bindingMode, setBindingMode] = useRecoilState(bindingModeState);
  const flyleaf = useRecoilValue(flyleafState);
  const [sheetsPerSignature, setSheetsPerSignature] = useRecoilState(
    sheetsPerSignatureState
  );

  const setSelectedItem = (item) => {
    setSheetSize(item.sheetSize);
    setPageOrientation(item.pageOrientation);
    setPagesPerSheet(item.pagesPerSheet);
    setBindingEdge(item.bindingEdge);
    setCutOrientation(item.cutOrientation);
  };

  const selectedItem = {
    sheetSize,
    pagesPerSheet,
    pageOrientation,
    bindingEdge,
    cutOrientation,
  };

  const noBinding = bindingMode === BindingModes.NONE;

  const { RIGHT, ...supportedBindingEdge } = BindingEdge;

  return (
    <SidebarTab title="Notebook">
      <InputContainer
        title={`${
          bindingMode === BindingModes.NONE ? 'Page' : 'Notebook'
        } Size`}
      >
        <div
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            mb: '0.5rem',
            variant: 'text.label',
          }}
        >
          <div>{sizeDescription(selectedItem)}</div>
          <div
            sx={{
              color: 'primary',
              fontWeight: 600,
            }}
          >
            {textDimensions(selectedItem)}
          </div>
        </div>
        <NotebookDialog
          {...props}
          selectedItem={selectedItem}
          onSelectItem={(item) => setSelectedItem(item)}
        >
          <Square width={218}>
            <Thumbnail
              {...selectedItem}
              bindingMode={bindingMode}
              active={true}
            ></Thumbnail>
          </Square>
        </NotebookDialog>
      </InputContainer>

      <InputContainer title="Binding Type">
        <Select
          options={BindingModes}
          selected={bindingMode}
          value={bindingMode}
          onChange={(e) => setBindingMode(e.target.value)}
        ></Select>
      </InputContainer>

      {!noBinding && (
        <InputContainer title="Page Count">
          <Field
            type="number"
            name="pages"
            value={pages}
            min={flyleaf ? 6 : 2}
            onChange={(event) => {
              let val = parseInt(event.target.value);

              if (isNaN(val) || val < 0) {
                val = 0;
              }
              // TODO: I think this is working as intended, but it does expose #130
              if (val < currentPage) {
                setCurrentPage(val); // TODO: consider using a recoil selector
              }

              setPages(val); // don't nudge the value yet, to offer the user a bit of flexibility on input
            }}
            onBlur={(event) => {
              let val = parseInt(event.target.value);

              // Force page number to be even, so that #130 isn't exposed.
              // TODO: hope this isn't the wrong way to do it! should it be val % pagesPerSheet?
              if (val % 2) {
                // infer user's intent
                if (val < pages) {
                  val--;
                } else {
                  val++;
                }
              }

              setPages(val);
            }}
            hint={
              flyleaf
                ? `* Max page number will be ${adjustPagesForFlyleaf(
                    pages,
                    flyleaf
                  )} to account for flyleaves`
                : null
            }
          ></Field>
        </InputContainer>
      )}

      {!noBinding && bindingMode === BindingModes.SIGNATURE && (
        <>
          <InputContainer title="Signatures">
            <Field
              type="number"
              name="signatures"
              value={convertPagesToSignatures(
                pages,
                pagesPerSheet,
                SidesPerSheet.TWO,
                sheetsPerSignature,
                flyleaf
              )}
              min={1}
              onChange={(event) => {
                let val = parseInt(event.target.value);

                if (isNaN(val) || val < 0) {
                  val = 0;
                }

                let pages = convertSignaturesToPages(
                  val,
                  pagesPerSheet,
                  SidesPerSheet.TWO,
                  sheetsPerSignature,
                  flyleaf
                );

                // TODO: resolve duplicated code with page count onChange
                if (pages < currentPage) {
                  setCurrentPage(pages);
                }

                setPages(pages);
              }}
            ></Field>
          </InputContainer>
          <InputContainer title="Sheets per signature">
            <Select
              options={SheetsPerSignature}
              selected={sheetsPerSignature}
              value={sheetsPerSignature}
              onChange={(e) => setSheetsPerSignature(e.target.value)}
            ></Select>
          </InputContainer>
        </>
      )}

      {!noBinding && (
        <InputContainer title="Page Turn Direction">
          <RadioGroup
            name="bindingEdge"
            options={BindingEdge}
            labels={BindingEdgeDescriptions}
            onChange={(value) => setBindingEdge(value)}
            currentValue={bindingEdge}
            comingSoon={
              !experimentalFeatures.enableRightBinding && BindingEdge.RIGHT
            }
          ></RadioGroup>
        </InputContainer>
      )}

      <div
        sx={{
          fontWeight: 500,
          fontSize: ['1rem', '1rem', '1rem', '1rem'],
        }}
      >
        {!noBinding && calculateMessage({ pages, pagesPerSheet, sheetSize })}
      </div>
    </SidebarTab>
  );
};

export default NotebookTab;
