import React from 'react';
import Gallery from '.';
import PatternTile from '../Sidebar/PatternTile';
import Thumbnail from '../Thumbnail';
import GalleryItem from './GalleryItem';

import templates from './templates';

const TemplateGallery = ({
  itemWidth = 260,
  sheetSize,
  onSelectItem,
  selectedItem,
}) => {
  return (
    <Gallery>
      {templates.map((item, index) => {
        let {
          bindingMode,
          pagesPerSheet,
          pageOrientation,
          bindingEdge,
          cutOrientation,
          color,
          pattern,
        } = item.config;

        // localize the settings
        item.config.sheetSize = sheetSize;

        return (
          <GalleryItem
            key={index}
            title={item.title}
            description={item.description}
            width={itemWidth}
            selected={item.title === selectedItem.title}
            onClick={() => (onSelectItem ? onSelectItem(item) : null)}
          >
            {item.isNotebook ? (
              <Thumbnail
                bindingMode={bindingMode}
                sheetSize={sheetSize}
                pagesPerSheet={pagesPerSheet}
                pageOrientation={pageOrientation}
                bindingEdge={bindingEdge}
                cutOrientation={cutOrientation}
              ></Thumbnail>
            ) : (
              <PatternTile color={color} pattern={pattern}></PatternTile>
            )}
          </GalleryItem>
        );
      })}
    </Gallery>
  );
};

export default TemplateGallery;
