import React, { Fragment } from 'react';

import Sheet from '../Sheet';
import Region from '../Region';

import {
  BindingModes,
  ColorOptions,
  DefaultOpacityByColor,
  PagesPerSheet,
} from '../../../constants';

import {
  shouldRotatePrint,
  shouldHaveAlternateAlign,
  flyleafPageNumber,
} from '../../../utils/page';

import PageFactory from '../Page';

const Section = ({
  pageHeight,
  pageWidth,
  pagesPerSheet,
  pageOrientation,
  bindingEdge,
  bindingMode,
  pageStyle,
  pattern, // todo: get rid of this?
  bookDescriptor,
  paginator,
  color = ColorOptions.BLACK, // todo: get rid of this?
  opacity, // todo: get rid of this?
  flyleaf,
  pageNumberingEnabled,
  ...rest
}) => {
  const pages = Array.from({ length: pagesPerSheet }, (_, i) => i);

  const isRotated = shouldRotatePrint(pageOrientation, pagesPerSheet);

  const looseLeaf = (
    <PageFactory pageStyle={pageStyle} isRotated={isRotated}>
      <Region
        pattern={bookDescriptor ? bookDescriptor.patterns[1] : pattern}
        isRotated={isRotated}
        color={bookDescriptor ? bookDescriptor.colors[1] : color}
        opacity={
          bookDescriptor
            ? bookDescriptor.opacities[1]
            : opacity || DefaultOpacityByColor[color]
        }
        shouldFillPage={
          bookDescriptor ? !bookDescriptor.arePatternsCustom[1] : false
        }
      ></Region>
    </PageFactory>
  );

  return (
    <>
      {bindingMode === BindingModes.NONE ? (
        <Fragment>
          <Sheet height={pageHeight} width={pageWidth} {...rest}>
            {looseLeaf}
            {pagesPerSheet === PagesPerSheet.TWO ? looseLeaf : null}
          </Sheet>
        </Fragment>
      ) : (
        <Fragment>
          {paginator.pages.map((page, i) => (
            <Sheet key={i} height={pageHeight} width={pageWidth} {...rest}>
              {pages.map((j) => (
                <PageFactory
                  pageStyle={pageStyle}
                  key={j}
                  pageNumber={
                    flyleaf
                      ? flyleafPageNumber(
                          page[j],
                          paginator.count,
                          pagesPerSheet
                        )
                      : page[j]
                  }
                  isRotated={isRotated}
                  hasAlternateAlign={shouldHaveAlternateAlign(
                    bindingEdge,
                    page[j]
                  )}
                  titleLabel={
                    bookDescriptor ? bookDescriptor.titles[page[j]] : null
                  }
                  showTitle={
                    bookDescriptor ? bookDescriptor.titles[page[j]] : null
                  }
                  pageNumberingEnabled={pageNumberingEnabled}
                >
                  <Region
                    pattern={
                      bookDescriptor
                        ? bookDescriptor.patterns[page[j]]
                        : pattern
                    }
                    isRotated={isRotated}
                    color={
                      bookDescriptor ? bookDescriptor.colors[page[j]] : color
                    }
                    opacity={
                      bookDescriptor
                        ? bookDescriptor.opacities[page[j]]
                        : opacity || DefaultOpacityByColor[color]
                    }
                    shouldFillPage={
                      bookDescriptor
                        ? !bookDescriptor.arePatternsCustom[page[j]]
                        : false
                    }
                  ></Region>
                </PageFactory>
              ))}
            </Sheet>
          ))}
        </Fragment>
      )}
    </>
  );
};

export default Section;
