/** @jsx jsx */
import { jsx, Styled } from 'theme-ui';

const Gallery = ({ children }) => (
  <div
    sx={{
      display: 'grid',
      gridTemplateColumns: 'repeat(4, 1fr)',
      width: '100%',
      height: '100%',
      alignItems: 'start', // setting this to center will result in wonkiness
    }}
  >
    {children}
  </div>
);

export default Gallery;
