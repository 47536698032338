import forms from './forms';
import buttons from './buttons';
import modals from './modals';
import colors from './colors';
import tags from './tags';

const theme = {
  breakpoints: ['28em', '64em', '108em'],
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
  fonts: {
    body: 'Quicksand',
    heading: 'inherit',
  },
  // fontSizes: [12, 14, 16, 20, 24, 32, 48, 64, 72],
  fontWeights: {
    body: 400,
    heading: 700,
    bold: 700,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.25,
  },
  colors,
  text: {
    heading: {
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
      mb: '0.5rem',
      fontSize: ['30px', '36px', '40px', '44px'],
      mb: '0.5rem',
    },
    body: {
      fontSize: ['16px', '20px', '16px', '20px'],
      color: 'text',
      lineHeight: 1.5,
      mb: '1.5rem',
    },
  },
  styles: {
    ...tags,
    h1: {
      variant: 'text.heading',
      fontSize: ['30px', '36px', '40px', '44px'],
    },
    h2: {
      variant: 'text.heading',
      fontSize: ['24px', '36px', '36px', '36px'],
    },
    h3: {
      variant: 'text.heading',
      fontSize: ['20px', '24px', '20px', '24px'],
    },
    h4: {
      variant: 'text.heading',
      fontSize: ['20px', '20px', '16px', '20px'],
    },
    h5: {
      variant: 'text.heading',
      fontSize: 1,
    },
    h6: {
      variant: 'text.heading',
      fontSize: 0,
    },
    p: {
      variant: 'text.body',
    },
  },
  ...forms,
  ...buttons,
  ...modals,
};

export default theme;
