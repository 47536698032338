import React from 'react';
import { useThemeUI } from 'theme-ui';

const IconFactory = (icons) => {
  const Wrapper = ({ name, size = 24, color, style }) => {
    const styles = {
      verticalAlign: 'middle',
      // # Use CSS instead of the width attr to support non- pixel units
      width: size,
      // # Prevents scaling issue in IE
      height: size,
    };

    const { theme } = useThemeUI();

    return (
      <svg
        viewBox="0 0 24 24"
        preserveAspectRatio="xMidYMid meet"
        fit
        style={Object.assign(
          styles,
          style,
          color
            ? {
                fill: theme.colors.hasOwnProperty(color)
                  ? theme.colors[color]
                  : color,
              }
            : {}
        )}
      >
        {icons[name]}
      </svg>
    );
  };
  return Wrapper;
};

const Icon = IconFactory({
  'page-first': (
    <g>
      <path d="M18.41,16.59L13.82,12L18.41,7.41L17,6L11,12L17,18L18.41,16.59M6,6H8V18H6V6Z" />
    </g>
  ),
  'chevron-left': (
    <g>
      <path d="M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z" />
    </g>
  ),
  'chevron-right': (
    <g>
      <path d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z" />
    </g>
  ),
  'page-last': (
    <g>
      <path d="M5.59,7.41L10.18,12L5.59,16.59L7,18L13,12L7,6L5.59,7.41M16,6H18V18H16V6Z" />
    </g>
  ),
  // Material Design Icons: notebook
  notebook: (
    <g>
      <path d="M3,7V5H5V4C5,2.89 5.9,2 7,2H13V9L15.5,7.5L18,9V2H19C20.05,2 21,2.95 21,4V20C21,21.05 20.05,22 19,22H7C5.95,22 5,21.05 5,20V19H3V17H5V13H3V11H5V7H3M7,11H5V13H7V11M7,7V5H5V7H7M7,19V17H5V19H7Z" />
    </g>
  ),
  // Material Design Icons: file-settings-outline
  pattern: (
    <g>
      <path d="M6 0A2 2 0 0 0 4 2V18A2 2 0 0 0 6 20H18A2 2 0 0 0 20 18V6L14 0H6M6 2H13V7H18V18H6V2M7 22V24H9V22H7M11 22V24H13V22H11M15 22V24H17V22H15Z" />
    </g>
  ),
  // Material Design Icons: numeric
  pageNumbering: (
    <g>
      <path d="M4,17V9H2V7H6V17H4M22,15C22,16.11 21.1,17 20,17H16V15H20V13H18V11H20V9H16V7H20A2,2 0 0,1 22,9V10.5A1.5,1.5 0 0,1 20.5,12A1.5,1.5 0 0,1 22,13.5V15M14,15V17H8V13C8,11.89 8.9,11 10,11H12V9H8V7H12A2,2 0 0,1 14,9V11C14,12.11 13.1,13 12,13H10V15H14Z" />
    </g>
  ),
  // Material Design Icons: book-open-page-variant
  finishes: (
    <path d="M19 2L14 6.5V17.5L19 13V2M6.5 5C4.55 5 2.45 5.4 1 6.5V21.16C1 21.41 1.25 21.66 1.5 21.66C1.6 21.66 1.65 21.59 1.75 21.59C3.1 20.94 5.05 20.5 6.5 20.5C8.45 20.5 10.55 20.9 12 22C13.35 21.15 15.8 20.5 17.5 20.5C19.15 20.5 20.85 20.81 22.25 21.56C22.35 21.61 22.4 21.59 22.5 21.59C22.75 21.59 23 21.34 23 21.09V6.5C22.4 6.05 21.75 5.75 21 5.5V19C19.9 18.65 18.7 18.5 17.5 18.5C15.8 18.5 13.35 19.15 12 20V6.5C10.55 5.4 8.45 5 6.5 5Z" />
  ),
  // https://materialdesignicons.com/icon/print-previewer
  print: (
    <path d="M18,3H6V7H18M19,12A1,1 0 0,1 18,11A1,1 0 0,1 19,10A1,1 0 0,1 20,11A1,1 0 0,1 19,12M16,19H8V14H16M19,8H5A3,3 0 0,0 2,11V17H6V21H18V17H22V11A3,3 0 0,0 19,8Z" />
  ),
  'no-print': (
    <path d="M6,3V4.18L8.82,7H18V3H6M2.28,3L1,4.27L4.75,8C3.19,8.15 2,9.44 2,11V17H6V21H17.73L19.73,23L21,21.72L2.28,3M9.82,8L18.82,17H22V11A3,3 0 0,0 19,8H9.82M19,10A1,1 0 0,1 20,11A1,1 0 0,1 19,12A1,1 0 0,1 18,11A1,1 0 0,1 19,10M8,14H10.73L15.73,19H8V14Z" />
  ),
  // https://materialdesignicons.com/icon/notebook-plus-outline
  new: (
    <path d="M17 4V10L15 8L13 10V4H9V20H12.1C12.2 20.7 12.5 21.4 12.8 22H7C5.9 22 5 21 5 20V19H3V17H5V13H3V11H5V7H3V5H5V4C5 2.9 5.9 2 7 2H19C20 2 21 3 21 4V13.8C20.4 13.4 19.7 13.2 19 13.1V4H17M5 19H7V17H5V19M5 13H7V11H5V13M5 7H7V5H5V7M17 15V18H14V20H17V23H19V20H22V18H19V15H17Z" />
  ),

  // https://materialdesignicons.com/icon/heart
  heart: (
    <path d="M12,21.35L10.55,20.03C5.4,15.36 2,12.27 2,8.5C2,5.41 4.42,3 7.5,3C9.24,3 10.91,3.81 12,5.08C13.09,3.81 14.76,3 16.5,3C19.58,3 22,5.41 22,8.5C22,12.27 18.6,15.36 13.45,20.03L12,21.35Z" />
  ),
  // https://materialdesignicons.com/icon/message
  message: (
    <path d="M20,2H4A2,2 0 0,0 2,4V22L6,18H20A2,2 0 0,0 22,16V4C22,2.89 21.1,2 20,2Z" />
  ),
  // https://materialdesignicons.com/icon/information
  about: (
    <path d="M13,9H11V7H13M13,17H11V11H13M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z" />
  ),
  'drag-handle': (
    <g transform="translate(5.000000, 12.000000)">
      <g transform="translate(7.000000, 5.000000)">
        <path
          d="M0,14 L0,12 L2,12 L2,14 L0,14 M4,14 L4,12 L6,12 L6,14 L4,14 M8,14 L8,12 L10,12 L10,14 L8,14 M0,10 L0,8 L2,8 L2,10 L0,10 M4,10 L4,8 L6,8 L6,10 L4,10 M8,10 L8,8 L10,8 L10,10 L8,10 M0,6 L0,4 L2,4 L2,6 L0,6 M4,6 L4,4 L6,4 L6,6 L4,6 M8,6 L8,4 L10,4 L10,6 L8,6 M0,2 L0,0 L2,0 L2,2 L0,2 M4,2 L4,0 L6,0 L6,2 L4,2 M8,2 L8,0 L10,0 L10,2 L8,2 Z"
          fill-rule="nonzero"
        ></path>
      </g>
    </g>
  ),
  section: (
    <g
      id="icon/section"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <path
        d="M18,15 L15,15 L15,17 L20,17 L20,10 L18,10 L18,15 Z M9,6 L4,6 L4,13 L9,13 L9,6 Z M16,6 L11,6 L11,13 L16,13 L16,6 Z M8,15 L8,17 L13,17 L13,15 L8,15 Z M2,15 L2,4 L18,4 L18,8 L22,8 L22,19 L6,19 L6,15 L2,15 Z"
        id="Shape"
        fill="#000000"
        fill-rule="nonzero"
      ></path>
    </g>
  ),
  'special-page': (
    <g
      id="icon/special-page"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <path
        d="M5,3 L5,21 L19,21 L19,3 L5,3 Z"
        id="Rectangle"
        stroke="#000000"
        stroke-width="2"
      ></path>
    </g>
  ),
  'binding-left': (
    <g
      id="icon/binding/left"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <path
        d="M20,2 L20,22 L5,22 L5,20 L3,20 C2.44771525,20 2,19.5522847 2,19 C2,18.4477153 2.44771525,18 3,18 L5,18 L5,13 L3,13 C2.44771525,13 2,12.5522847 2,12 C2,11.4477153 2.44771525,11 3,11 L5,11 L5,6 L3,6 C2.44771525,6 2,5.55228475 2,5 C2,4.44771525 2.44771525,4 3,4 L5,4 L5,2 L20,2 Z"
        id="Path"
        fill="#000000"
      ></path>
    </g>
  ),
  'binding-top': (
    <g
      id="icon/binding/top"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <path
        d="M21,1 L21,21 L6,21 L6,19 L4,19 C3.44771525,19 3,18.5522847 3,18 C3,17.4477153 3.44771525,17 4,17 L6,17 L6,12 L4,12 C3.44771525,12 3,11.5522847 3,11 C3,10.4477153 3.44771525,10 4,10 L6,10 L6,5 L4,5 C3.44771525,5 3,4.55228475 3,4 C3,3.44771525 3.44771525,3 4,3 L6,3 L6,1 L21,1 Z"
        id="Combined-Shape"
        fill="#000000"
        transform="translate(12.000000, 11.000000) rotate(-270.000000) translate(-12.000000, -11.000000) "
      ></path>
    </g>
  ),
});

export default Icon;
