/** @jsx jsx */
import { jsx } from 'theme-ui';
import React, { forwardRef } from 'react';
import { Link as L } from 'react-router-dom';
import { Link as ThemedLink } from 'theme-ui';

export const Link = ({ to, children, ...rest }) => (
  <L
    to={to}
    component={forwardRef((linkProps, ref) => (
      <ThemedLink ref={ref} {...linkProps}></ThemedLink>
    ))}
    {...rest}
  >
    {children}
  </L>
);

export default Link;
