import experimentalFeatures from '../App/experimentalFeatures';
import {
  BindingEdge,
  CutOrientation,
  NullOption,
  PageOrientation,
  PagesPerSheet,
  SheetSizeOptions,
} from '../constants';

export const filterByOptions = (
  sheetSize,
  pagesPerSheet,
  pageOrientation,
  cutOrientation
) => {
  let items = [];
  if (
    cutOrientation === NullOption ||
    cutOrientation === CutOrientation.SHORT
  ) {
    Object.values(SheetSizeOptions)
      .filter((key) => (sheetSize !== NullOption ? key === sheetSize : true))
      .forEach((_sheetSize) => {
        Object.values(PagesPerSheet)
          .filter((key) =>
            pagesPerSheet !== NullOption ? key === pagesPerSheet : true
          )
          .forEach((_pagesPerSheet) => {
            Object.values(PageOrientation)
              .filter((key) =>
                pageOrientation !== NullOption ? key === pageOrientation : true
              )
              .forEach((_pageOrientation) => {
                items.push({
                  sheetSize: _sheetSize,
                  pagesPerSheet: _pagesPerSheet,
                  pageOrientation: _pageOrientation,
                  cutOrientation: CutOrientation.SHORT,
                  bindingEdge: BindingEdge.LEFT, // without the binding visible, it doesn't matter
                });
              });
          });
      });
  }
  if (experimentalFeatures.enableLongCutOrientation) {
    if (
      cutOrientation === NullOption ||
      cutOrientation === CutOrientation.LONG
    ) {
      if (pagesPerSheet === NullOption || pagesPerSheet === PagesPerSheet.TWO) {
        Object.values(SheetSizeOptions)
          .filter((key) =>
            sheetSize !== NullOption ? key === sheetSize : true
          )
          .forEach((_sheetSize) => {
            Object.values(PageOrientation)
              .filter((key) =>
                pageOrientation !== NullOption ? key === pageOrientation : true
              )
              .forEach((_pageOrientation) => {
                items.push({
                  sheetSize: _sheetSize,
                  pagesPerSheet: PagesPerSheet.TWO,
                  pageOrientation: _pageOrientation,
                  cutOrientation: CutOrientation.LONG,
                  bindingEdge: BindingEdge.LEFT, // without the binding visible, it doesn't matter
                });
              });
          });
      }
    }
  }

  return items;
};
