/** @jsx jsx */
import { Button, jsx, Styled } from 'theme-ui';
import React from 'react';

import SidebarTab from './SidebarTab';
import { InputContainer } from '../Input';
import PrintInstructions from '../PrintInstructions';
import {
  bindingModeState,
  colorState,
  pageOrientationState,
  pagesPerSheetState,
  pagesState,
  sheetSizeState,
  sheetsPerSignatureState,
} from '../../recoil';
import { useRecoilValue } from 'recoil';
import Icon from '../IconFactory';

const PrintPreviewTab = (props) => {
  const pages = useRecoilValue(pagesState);
  const bindingMode = useRecoilValue(bindingModeState);
  const isCustomPattern = false;

  const sheetSize = useRecoilValue(sheetSizeState);
  const pageOrientation = useRecoilValue(pageOrientationState);
  const pagesPerSheet = useRecoilValue(pagesPerSheetState);
  const color = useRecoilValue(colorState);
  const sheetsPerSignature = useRecoilValue(sheetsPerSignatureState);
  return (
    <SidebarTab title="Print Preview">
      <InputContainer title="">
        <div
          sx={{
            display: 'flex',
            justifyContent: 'center',
            mt: '1rem',
            flexDirection: 'column',
          }}
        >
          <PrintInstructions
            {...{
              color,
              pagesPerSheet,
              bindingMode,
              pages,
              sheetSize,
              pageOrientation,
              sheetsPerSignature,
            }}
            showPrintLink={true}
            printButton={
              <Button
                sx={{ variant: 'primary' }}
                onClick={() => window.print()}
              >
                <Icon name="print" color="white"></Icon> Print
              </Button>
            }
            includeContext={false}
          ></PrintInstructions>
        </div>
      </InputContainer>
    </SidebarTab>
  );
};

export default PrintPreviewTab;
