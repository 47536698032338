/** @jsx jsx */
import { jsx, Styled } from 'theme-ui';
import React, { useState } from 'react';

import { Modal, ModalContent, ModalFooter } from './';

const AboutDialog = (props) => {
  const [open, setOpen] = useState(props.open);
  return (
    <>
      <div onClick={() => setOpen(true)}>{props.children}</div>
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        sx={{ overflowY: 'auto' }}
      >
        {({ onClose }) => (
          <>
            <ModalContent>
              <Styled.h2>About Folio Forge</Styled.h2>
              <Styled.p>
                Designed and built by{' '}
                <Styled.a href="https://designbyjayliu.com" target="_blank">
                  Jay Liu
                </Styled.a>
              </Styled.p>
              <br></br>
              <Styled.p>
                Note that this is approved for{' '}
                <Styled.strong>
                  personal or non-commercial use only
                </Styled.strong>
                .{' '}
              </Styled.p>
              <Styled.p>
                For all other usage, please contact me first at{' '}
                <Styled.a href="mailto:jay@folioforge.app">
                  jay@FolioForge.app
                </Styled.a>
                .
              </Styled.p>
              <Styled.p>&nbsp;</Styled.p>
              <Styled.h3>Credits</Styled.h3>
              <Styled.p>
                Icons from{' '}
                <Styled.a
                  href="https://materialdesignicons.com/"
                  target="_blank"
                >
                  Material Design Icons
                </Styled.a>
                .
              </Styled.p>
              <Styled.p>
                Typeface is{' '}
                <Styled.a
                  href="https://fonts.google.com/specimen/Quicksand"
                  target="_blank"
                >
                  Quicksand
                </Styled.a>{' '}
                by Andrew Paglinawan
              </Styled.p>
              <br />
              <Styled.p>Javascript libraries:</Styled.p>
              <Styled.ul>
                <Styled.li>
                  <Styled.a href="https://reactjs.org/" target="_blank">
                    React, Recoil, React Tabs
                  </Styled.a>{' '}
                  by Facebook
                </Styled.li>
                <Styled.li>
                  <Styled.a
                    href="https://github.com/system-ui/theme-ui#readme"
                    target="_blank"
                  >
                    Theme UI
                  </Styled.a>
                </Styled.li>
                <Styled.li>
                  <Styled.a
                    href="https://styled-components.com/"
                    target="_blank"
                  >
                    Styled Components
                  </Styled.a>
                </Styled.li>
                <Styled.li>
                  <Styled.a
                    href="https://github.com/mattjennings/react-modal#readme"
                    target="_blank"
                  >
                    React Modal
                  </Styled.a>{' '}
                  by Matt Jennings
                </Styled.li>
                <Styled.li>
                  <Styled.a
                    href="https://github.com/wwayne/react-tooltip#readme"
                    target="_blank"
                  >
                    React Tooltip
                  </Styled.a>
                </Styled.li>
                <Styled.li>
                  <Styled.a
                    href="https://github.com/okonet/react-container-dimensions#readme"
                    target="_blank"
                  >
                    React Container Dimensions
                  </Styled.a>
                </Styled.li>
                <Styled.li>
                  <Styled.a
                    href="https://github.com/nfl/react-helmet#readme"
                    target="_blank"
                  >
                    React Helmet
                  </Styled.a>
                </Styled.li>
                <Styled.li>
                  <Styled.a
                    href="https://revolunet.github.io/react-mailchimp-subscribe/"
                    target="_blank"
                  >
                    React Mailchimp Subscribe
                  </Styled.a>
                </Styled.li>
                <Styled.li>
                  <Styled.a
                    href="https://github.com/HubSpot/humanize"
                    target="_blank"
                  >
                    Humanize Plus
                  </Styled.a>
                </Styled.li>
              </Styled.ul>
            </ModalContent>
            <ModalFooter onClose={onClose}></ModalFooter>
          </>
        )}
      </Modal>
    </>
  );
};

export default AboutDialog;
