import React from 'react';
import { hydrate, render } from 'react-dom';

import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

import GA4React from 'ga-4-react';

const ga4react = new GA4React('G-E1ZY7235ED');

ga4react
  .initialize()
  .finally(() => {
    const rootElement = document.getElementById('root');
    if (rootElement.hasChildNodes()) {
      hydrate(<App />, rootElement);
    } else {
      render(<App />, rootElement);
    }
  })
  .catch((error) => {
    // probably failed because of ad-blocker
    console.error(error);
  });

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
