/** @jsx jsx */
import { Button, jsx, Styled } from 'theme-ui';
import React, { Fragment } from 'react';
import {
  BindingModes,
  ColorOptions,
  PageOrientation,
  PagesPerSheet,
} from '../../constants';
import { calculateMessage } from './Sidebar/calculateMessage';
import { capitalize } from 'humanize-plus';
import { Link } from './Input/Link';
import Icon from './IconFactory';

const ImportantSetting = ({ children }) => (
  <span
    sx={{
      fontWeight: '700',
      color: 'primary',
    }}
  >
    {children}
  </span>
);

/**
 * Gets the two-sided printing instruction, whether to flip on short or long edge.
 * @param {settings object} param0 Settings
 * @returns `true` if on short edge, `false` if on long edge
 */
export const getFlipSetting = ({ pageOrientation, pagesPerSheet }) =>
  pagesPerSheet === PagesPerSheet.TWO
    ? pageOrientation === PageOrientation.PORTRAIT
    : pageOrientation === PageOrientation.LANDSCAPE;

const PrintInstructions = ({
  color,
  pagesPerSheet,
  bindingMode,
  pages,
  sheetSize,
  pageOrientation,
  showPrintLink,
  printButton,
  includeContext,
  sheetsPerSignature,
}) => {
  const colorSetting =
    color === ColorOptions.BLACK ? 'Black and White' : 'Color';

  const flipSetting = getFlipSetting({ pageOrientation, pagesPerSheet })
    ? 'Short Edge'
    : 'Long Edge';

  let afterPrintSteps = null;

  if (pagesPerSheet === PagesPerSheet.TWO) {
    switch (bindingMode) {
      case BindingModes.DISC:
      case BindingModes.JAPANESE:
      case BindingModes.SPIRAL:
      case BindingModes.SADDLE:
        afterPrintSteps = ['Fold pages in half', 'Binding pages together'];
        break;

      case BindingModes.SIGNATURE:
        afterPrintSteps = [
          `Separate sheets into signatures of ${sheetsPerSignature}`,
          'Fold each signature in half',
          'Binding pages together',
        ];
        break;
      case BindingModes.NONE:
        afterPrintSteps = ['Cut pages in half'];
        break;
    }
  } else {
    switch (bindingMode) {
      case BindingModes.NONE:
        // do nothing, no steps
        break;
    }
  }

  const pageSizeSetting = capitalize(sheetSize);

  const noBinding = bindingMode === BindingModes.NONE;

  return (
    <Fragment>
      {includeContext && (
        <Styled.h3
          sx={{
            fontSize: '1rem',
          }}
        >
          Print Instructions
        </Styled.h3>
      )}

      {includeContext && (
        <Styled.p>Use this customized guide to print your notebook.</Styled.p>
      )}

      {showPrintLink && (
        <Styled.p
          sx={{
            textAlign: 'center',
            borderColor: 'muted',
            borderWidth: 1,
            borderStyle: 'solid',
            px: '0.5rem',
            py: '0.75rem',
            lineHeight: 1.25,
            fontSize: '88%',
          }}
        >
          Want a printed copy of these instructions?
          <Link
            // target="_blank" // TODO: address #101
            to="/print-instructions"
            sx={{
              py: '0.5rem',
              mt: '0.5rem',
              display: 'block',
              bg: 'wheat',
            }}
          >
            » {<Icon name="print" size={20}></Icon>} Print these instructions «
          </Link>
        </Styled.p>
      )}

      <Styled.h4
        sx={{
          fontSize: '1.25rem',
        }}
      >
        1. Apply these custom browser print settings for your project
      </Styled.h4>

      <Styled.table>
        <tbody>
          <Styled.tr>
            <Styled.td>Layout</Styled.td>
            <Styled.td>
              <ImportantSetting>Portrait</ImportantSetting> (Important!)
            </Styled.td>
          </Styled.tr>

          <Styled.tr>
            <Styled.td>Color Setting / Mode</Styled.td>
            <Styled.td>
              <ImportantSetting>{colorSetting}</ImportantSetting>
            </Styled.td>
          </Styled.tr>

          <Styled.tr>
            <Styled.td>Paper Size</Styled.td>
            <Styled.td>
              <ImportantSetting>{pageSizeSetting}</ImportantSetting>
            </Styled.td>
          </Styled.tr>

          <Styled.tr>
            <Styled.td>Pages per sheet</Styled.td>
            <Styled.td>
              <ImportantSetting>1</ImportantSetting>
            </Styled.td>
          </Styled.tr>

          <Styled.tr>
            <Styled.td>Margins</Styled.td>
            <Styled.td>
              <ImportantSetting>None</ImportantSetting>
            </Styled.td>
          </Styled.tr>

          <Styled.tr>
            <Styled.td>Scale</Styled.td>
            <Styled.td>
              <ImportantSetting>Default / 100%</ImportantSetting>
            </Styled.td>
          </Styled.tr>

          <Styled.tr>
            <Styled.td>Pages Per Sheet</Styled.td>
            <Styled.td>
              <ImportantSetting>1</ImportantSetting> (Important!)
            </Styled.td>
          </Styled.tr>

          {!noBinding && (
            <Styled.tr>
              <Styled.td>Two Sided</Styled.td>
              <Styled.td>
                <ImportantSetting>Print on Both Sides</ImportantSetting> and{' '}
                <br /> choose to{' '}
                <ImportantSetting>flip on {flipSetting}</ImportantSetting>
              </Styled.td>
            </Styled.tr>
          )}

          <Styled.tr>
            <Styled.td>Options: Background Graphics</Styled.td>
            <Styled.td>
              <ImportantSetting>ON</ImportantSetting>
            </Styled.td>
          </Styled.tr>

          <Styled.tr>
            <Styled.td>Options: Print Header and Footer</Styled.td>
            <Styled.td>
              <ImportantSetting>OFF</ImportantSetting>
            </Styled.td>
          </Styled.tr>
        </tbody>{' '}
      </Styled.table>

      <Styled.h4>2. Print your notebook!</Styled.h4>
      {printButton}

      {!noBinding && calculateMessage({ pages, pagesPerSheet, sheetSize })}

      {afterPrintSteps && (
        <Styled.h4
          sx={{
            variant: 'text.heading',
            mt: '1.5rem',
            fontSize: '1.25rem',
          }}
        >
          3. After Printing
        </Styled.h4>
      )}
      {afterPrintSteps && (
        <Styled.ol>
          {afterPrintSteps.map((item, i) => (
            <Styled.li key={i}>{item}</Styled.li>
          ))}
        </Styled.ol>
      )}
    </Fragment>
  );
};

export default PrintInstructions;
