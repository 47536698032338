/** @jsx jsx */
import { jsx, Styled } from 'theme-ui';
import Square from '../Sidebar/Square';

const GalleryItem = ({
  title,
  description,
  selected,
  width,
  onClick,
  children,
}) => (
  <div
    sx={{
      textAlign: 'center',
      userSelect: 'none',
      mb: '2rem',
      mx: '1rem',
      maxWidth: width,
    }}
  >
    <Square
      active={selected}
      onClick={onClick}
      interactive={true}
      width={width}
    >
      {children}
    </Square>
    <div
      sx={{
        fontSize: '1.25rem',
        mt: '0.75rem',
        fontWeight: selected ? '700' : 'inherit',
      }}
    >
      {title}
    </div>
    <div
      sx={{
        fontSize: '1rem',
        mt: '0.25rem',
        lineHeight: '1.25',
      }}
    >
      {description}
    </div>
  </div>
);

export default GalleryItem;
