/** @jsx jsx */
import { jsx, Label, Styled } from 'theme-ui';
import React from 'react';

import { Select as S } from 'theme-ui';
import { capitalize } from 'humanize-plus';
import { NullOption } from '../../../constants';

const Select = ({ options, nullOption, disabled, children, ...rest }) => {
  const optionKeys = Object.keys(options);

  const select = (
    <S
      sx={{
        minWidth: '6rem',
      }}
      disabled={disabled}
      {...rest}
    >
      {nullOption && (
        <option key={-1} value={NullOption}>
          {nullOption}
        </option>
      )}
      {optionKeys.map((item, i) => (
        <option key={i} value={options[item]}>
          {capitalize(item, true)}
        </option>
      ))}
    </S>
  );

  if (children) {
    return (
      <div
        sx={{
          display: 'flex',
          mr: '2rem',
          alignItems: 'center',
        }}
      >
        <Label
          sx={{
            width: 'unset',
            mr: '1rem',
          }}
        >
          {children}
        </Label>
        {select}
      </div>
    );
  } else {
    return select;
  }
};

export default Select;
