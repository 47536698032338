/** @jsx jsx */
import { jsx, Styled } from 'theme-ui';
import React from 'react';

import { PageFactory, Region } from '../../core';

const miniPageBase = {
  borderWidth: 1,
  borderStyle: 'solid',
  borderColor: 'muted',
  display: 'flex',
  userSelect: 'none',
};

export const StyledMiniPage = ({ height, width, children }) => (
  <Styled.div
    sx={{
      ...miniPageBase,
      height: `${height}px`,
      width: `${width}px`,
    }}
  >
    {children}
  </Styled.div>
);

export const StyledBlankMiniPage = ({
  height,
  width,
  color,
  border,
  showShadow,
}) => (
  <Styled.div
    sx={{
      ...miniPageBase,
      height: `${height}px`,
      width: `${width}px`,
      borderColor: border || 'muted',
      backgroundColor: color,
      boxShadow: showShadow
        ? '0 4px 8px rgba(0, 0, 0, 0.16), 0 4px 8px rgba(0, 0, 0, 0.23)'
        : 'none',
    }}
  ></Styled.div>
);

export const VoidPage = ({ height, width }) => (
  <Styled.div
    sx={{
      ...miniPageBase,
      height: `${height}px`,
      width: `${width}px`,
      borderColor: 'transparent',
      backgroundColor: 'transparent',
    }}
  ></Styled.div>
);

const MiniPage = ({
  height,
  width,
  pageNumber,
  pattern,
  pageStyle,
  hasAlternateAlign,
  opacity,
  color,
  pageNumberingEnabled,
}) => {
  return (
    <StyledMiniPage height={height} width={width}>
      <PageFactory
        pageStyle={pageStyle}
        pageNumber={pageNumber}
        hasAlternateAlign={hasAlternateAlign}
        padding={16}
        pageNumberingEnabled={pageNumberingEnabled}
      >
        <Region pattern={pattern} opacity={opacity} color={color}></Region>
      </PageFactory>
    </StyledMiniPage>
  );
};

export default MiniPage;
