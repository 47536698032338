import React from 'react';
import { BindingModes } from '../../../../constants';

function ThumbnailLetterHalfPortrait({
  bindingMode,
  color = '#000',
  colorOpacity = '0.02',
  bindingColor = '#F25546',
  strokeColorOpacity = '0.2',
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="321"
      height="322"
      viewBox="0 0 321 322"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g transform="translate(118.5 96.5)">
          <path
            fill={color}
            fillOpacity={colorOpacity}
            stroke={color}
            strokeOpacity={strokeColorOpacity}
            strokeWidth="2"
            d="M-22 23H106V106H-22z"
            transform="rotate(90 42 64.5)"
          ></path>
          <g fill={bindingColor} transform="translate(-7)">
            {bindingMode === BindingModes.SIGNATURE && (
              <g>
                <path d="M7 11.286H14V13.286H7z"></path>
                <path d="M7 88.071H14V90.071H7z"></path>
                <path d="M7 25.107H14V27.107H7z"></path>
                <path d="M7 101.893H14V103.893H7z"></path>
                <path d="M7 38.929H14V40.929H7z"></path>
                <path d="M7 115.714H14V117.714H7z"></path>
              </g>
            )}
            {bindingMode === BindingModes.DISC && (
              <g>
                <rect width="14" height="4" x="0" y="85.536" rx="2"></rect>
                <rect width="14" height="4" x="0" y="70.179" rx="2"></rect>
                <rect width="14" height="4" x="0" y="54.821" rx="2"></rect>
                <rect width="14" height="4" x="0" y="39.464" rx="2"></rect>
                <rect width="14" height="4" x="0" y="24.107" rx="2"></rect>
                <rect width="14" height="4" x="0" y="116.25" rx="2"></rect>
                <rect width="14" height="4" x="0" y="100.893" rx="2"></rect>
                <rect width="14" height="4" x="0" y="8.75" rx="2"></rect>
              </g>
            )}

            {bindingMode === BindingModes.JAPANESE && (
              <g>
                <path d="M7 9.75H14V11.75H7z"></path>
                <path d="M7 45.071H14V47.071H7z"></path>
                <path d="M7 80.393H14V82.393H7z"></path>
                <path d="M7 115.714H14V117.714H7z"></path>
                <path d="M12 0H14V129H12z"></path>
              </g>
            )}
            {bindingMode === BindingModes.SADDLE && (
              <g>
                <path d="M6 3.071H9V125.928H6z"></path>
              </g>
            )}
            {bindingMode === BindingModes.SPIRAL && (
              <g>
                <path d="M4 124.929H10V126.929H4z"></path>
                <path d="M4 118.786H10V120.786H4z"></path>
                <path d="M4 112.643H10V114.643H4z"></path>
                <path d="M4 106.5H10V108.5H4z"></path>
                <path d="M4 100.357H10V102.357H4z"></path>
                <path d="M4 94.214H10V96.214H4z"></path>
                <path d="M4 88.071H10V90.071H4z"></path>
                <path d="M4 81.929H10V83.929H4z"></path>
                <path d="M4 75.786H10V77.786H4z"></path>
                <path d="M4 69.643H10V71.643H4z"></path>
                <path d="M4 63.5H10V65.5H4z"></path>
                <path d="M4 57.357H10V59.357H4z"></path>
                <path d="M4 51.214H10V53.214H4z"></path>
                <path d="M4 45.071H10V47.071H4z"></path>
                <path d="M4 38.929H10V40.929H4z"></path>
                <path d="M4 32.786H10V34.786H4z"></path>
                <path d="M4 26.643H10V28.643H4z"></path>
                <path d="M4 20.5H10V22.5H4z"></path>
                <path d="M4 14.357H10V16.357H4z"></path>
                <path d="M4 8.214H10V10.214H4z"></path>
                <path d="M4 2.071H10V4.071H4z"></path>
              </g>
            )}
          </g>
        </g>
      </g>
    </svg>
  );
}

export default ThumbnailLetterHalfPortrait;
