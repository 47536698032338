import React from 'react';
import { BindingModes } from '../../../../constants';

function ThumbnailA4HalfPortrait({
  bindingMode,
  color = '#000',
  colorOpacity = '0.02',
  bindingColor = '#F25546',
  strokeColorOpacity = '0.2',
}) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 321 322">
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g transform="translate(116 98.5)">
          <path
            fill={color}
            fillOpacity={colorOpacity}
            stroke={color}
            strokeOpacity={strokeColorOpacity}
            strokeWidth="2"
            d="M89 0L89 125 0 125 0 0z"
          ></path>
          <g fill={bindingColor} transform="translate(-8)">
            {bindingMode === BindingModes.SIGNATURE && (
              <g>
                <path d="M8 10.905H16V12.905H8z"></path>
                <path d="M8 85.31H16V87.31H8z"></path>
                <path d="M8 24.298H16V26.298H8z"></path>
                <path d="M8 98.702H16V100.702H8z"></path>
                <path d="M8 37.69H16V39.69H8z"></path>
                <path d="M8 112.095H16V114.095H8z"></path>
              </g>
            )}
            {bindingMode === BindingModes.DISC && (
              <g>
                <rect width="16" height="4" x="0" y="82.821" rx="2"></rect>
                <rect width="16" height="4" x="0" y="67.94" rx="2"></rect>
                <rect width="16" height="4" x="0" y="53.06" rx="2"></rect>
                <rect width="16" height="4" x="0" y="38.179" rx="2"></rect>
                <rect width="16" height="4" x="0" y="23.298" rx="2"></rect>
                <rect width="16" height="4" x="0" y="112.583" rx="2"></rect>
                <rect width="16" height="4" x="0" y="97.702" rx="2"></rect>
                <rect width="16" height="4" x="0" y="8.417" rx="2"></rect>
              </g>
            )}

            {bindingMode === BindingModes.JAPANESE && (
              <g>
                <path d="M8 9.417H16V11.417H8z"></path>
                <path d="M8 43.643H16V45.643H8z"></path>
                <path d="M8 77.869H16V79.869H8z"></path>
                <path d="M8 112.095H16V114.095H8z"></path>
                <path d="M13.857 0H15.857V125H13.857z"></path>
              </g>
            )}
            {bindingMode === BindingModes.SADDLE && (
              <g>
                <path d="M7.071 2.976H10.071V122.024H7.071z"></path>
              </g>
            )}
            {bindingMode === BindingModes.SPIRAL && (
              <g>
                <path d="M4.571 121.024H11.428V123.024H4.571z"></path>
                <path d="M4.571 115.071H11.428V117.071H4.571z"></path>
                <path d="M4.571 109.119H11.428V111.119H4.571z"></path>
                <path d="M4.571 103.167H11.428V105.167H4.571z"></path>
                <path d="M4.571 97.214H11.428V99.214H4.571z"></path>
                <path d="M4.571 91.262H11.428V93.262H4.571z"></path>
                <path d="M4.571 85.31H11.428V87.31H4.571z"></path>
                <path d="M4.571 79.357H11.428V81.357H4.571z"></path>
                <path d="M4.571 73.405H11.428V75.405H4.571z"></path>
                <path d="M4.571 67.452H11.428V69.452H4.571z"></path>
                <path d="M4.571 61.5H11.428V63.5H4.571z"></path>
                <path d="M4.571 55.548H11.428V57.548H4.571z"></path>
                <path d="M4.571 49.595H11.428V51.595H4.571z"></path>
                <path d="M4.571 43.643H11.428V45.643H4.571z"></path>
                <path d="M4.571 37.69H11.428V39.69H4.571z"></path>
                <path d="M4.571 31.738H11.428V33.738H4.571z"></path>
                <path d="M4.571 25.786H11.428V27.786H4.571z"></path>
                <path d="M4.571 19.833H11.428V21.833H4.571z"></path>
                <path d="M4.571 13.881H11.428V15.881H4.571z"></path>
                <path d="M4.571 7.929H11.428V9.929H4.571z"></path>
                <path d="M4.571 1.976H11.428V3.976H4.571z"></path>
              </g>
            )}
          </g>
        </g>
      </g>
    </svg>
  );
}

export default ThumbnailA4HalfPortrait;
