import React from 'react';

import MiniBookSpread from './MiniBookSpread';

import { calculatePageSize } from '../../../utils/page';
import { SheetSizeOptions } from '../../../constants';
import {
  bindingEdgeState,
  colorState,
  cutOrientationState,
  pageOrientationState,
  pagesPerSheetState,
  patternState,
  sheetSizeState,
  opacityState,
  pageNumberingEnabledState,
  pageStyleState,
} from '../../recoil';
import { useRecoilValue } from 'recoil';
import MiniBookModel from './MiniBookModel';

export const getPageDimensions = (sheetSize, sheetDimensions, showToScale) => {
  let height, width;

  const mmToIn = 0.0393701;
  const scale = showToScale ? 80 : 40;

  height =
    sheetSize === SheetSizeOptions.A4
      ? sheetDimensions.height * mmToIn * scale
      : sheetDimensions.height * scale;
  width =
    sheetSize === SheetSizeOptions.A4
      ? sheetDimensions.width * mmToIn * scale
      : sheetDimensions.width * scale;
  return { height, width };
};

const MiniBook = (props) => {
  const {
    // pattern,
    isCustomPattern,
    // color,
    flyleaf,
    pages,
    // pagesPerSheet,
    // pageOrientation,
    // sheetSize,
    // cutOrientation,
    // bindingEdge,
    bindingMode,
    showToScale,
    currentPage,
    setCurrentPage,
  } = props;

  const sheetSize = useRecoilValue(sheetSizeState);
  const pageOrientation = useRecoilValue(pageOrientationState);
  const pagesPerSheet = useRecoilValue(pagesPerSheetState);
  const cutOrientation = useRecoilValue(cutOrientationState);
  const bindingEdge = useRecoilValue(bindingEdgeState);

  const pattern = useRecoilValue(patternState);
  const color = useRecoilValue(colorState);
  const opacity = useRecoilValue(opacityState);

  const pageNumberingEnabled = useRecoilValue(pageNumberingEnabledState);
  const pageStyle = useRecoilValue(pageStyleState);

  const miniBookModel = new MiniBookModel({
    pattern,
    isCustomPattern,
    color,
    flyleaf,
    pages,
    bindingMode,
    bindingEdge,
  });

  const sheetDimensions = calculatePageSize(
    sheetSize,
    pageOrientation,
    pagesPerSheet,
    cutOrientation
  );

  let { height, width } = getPageDimensions(
    sheetSize,
    sheetDimensions,
    showToScale
  );

  // TODO: prevent bad currentPage from happening
  // TODO: this should probably live in the recoil.js file

  return (
    <>
      <MiniBookSpread
        {...props}
        height={height}
        width={width}
        showToScale={showToScale}
        bindingEdge={bindingEdge}
        miniBookModel={miniBookModel}
        currentPage={currentPage}
        color={color}
        opacity={opacity}
        setCurrentPage={setCurrentPage}
        pageNumberingEnabled={pageNumberingEnabled}
        pageStyle={pageStyle}
      />
    </>
  );
};

export default MiniBook;
