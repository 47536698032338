import React from 'react';

import GlobalStyle from './styles/global';

import LandingPage from './LandingPage';
import AppMain from './App/';

import { useGA4React } from 'ga-4-react';
import { HashRouter as Router, Route, Switch } from 'react-router-dom';
import PrintInstructionsView from './App/views/PrintInstructionsView';
import { RecoilRoot } from 'recoil';

function App() {
  try {
    useGA4React();
  } catch (err) {
    // probably had an ad blocker on
    console.err(err);
  }

  return (
    <RecoilRoot>
      <Router>
        <GlobalStyle />
        <Switch>
          <Route exact path="/" component={LandingPage} />
          <Route path="/app" component={AppMain} />
          <Route path="/print-instructions" component={PrintInstructionsView} />
        </Switch>
      </Router>
    </RecoilRoot>
  );
}

export default App;
