import React from 'react';

import defaultValues, {
  SheetSizeDimensions,
  DefaultOpacityByColor,
} from '../../../constants';

import { getBasicBookDescriptorObj } from '../../../utils/book';
import ComplexBook from '../ComplexBook';

const SimpleBook = (props) => {
  const {
    pages,
    sheetSize,
    pageStyle,
    pagesPerSheet,
    pageOrientation,
    bindingEdge,
    bindingMode,
    flyleaf,
    pattern,
    isCustomPattern,
    color,
    opacity,
    pageNumberingEnabled,
    cutOrientation,
    sheetsPerSignature,
  } = Object.assign(defaultValues, props);

  return (
    <ComplexBook
      pageCount={pages}
      pageHeight={`${SheetSizeDimensions[sheetSize].height}${SheetSizeDimensions[sheetSize].units}`}
      pageWidth={`${SheetSizeDimensions[sheetSize].width}${SheetSizeDimensions[sheetSize].units}`}
      pageStyle={pageStyle}
      pagesPerSheet={pagesPerSheet}
      pageOrientation={pageOrientation}
      bindingEdge={bindingEdge}
      bindingMode={bindingMode}
      flyleaf={flyleaf}
      bookDescriptorObj={getBasicBookDescriptorObj(
        pattern,
        isCustomPattern,
        color,
        flyleaf,
        pages,
        bindingMode,
        opacity
      )}
      opacity={opacity || DefaultOpacityByColor[color]}
      pageNumberingEnabled={pageNumberingEnabled}
      cutOrientation={cutOrientation}
      sheetsPerSignature={sheetsPerSignature}
    ></ComplexBook>
  );
};

export default SimpleBook;
