/** @jsx jsx */
import { jsx, Styled } from 'theme-ui';
import React, { useState } from 'react';

import { Modal, ModalTitle, ModalContent, ModalFooter } from './';

const ContributeDialog = (props) => {
  const [open, setOpen] = useState(props.open);
  return (
    <>
      <div onClick={() => setOpen(true)}>{props.children}</div>
      <Modal open={open} onClose={() => setOpen(false)}>
        {({ onClose }) => (
          <>
            <ModalContent>
              <Styled.h2>&#128151; How to Contribute</Styled.h2>
              <Styled.ul>
                <Styled.li>
                  <Styled.strong>Report bugs</Styled.strong> or{' '}
                  <Styled.strong>suggest features</Styled.strong> on{' '}
                  <Styled.a
                    href="https://discord.gg/2u6Ek4qYHp"
                    target="_blank"
                  >
                    Discord
                  </Styled.a>{' '}
                  or{' '}
                  <Styled.a href="mailto:incoming+jayliu50-folio-forge-18639461-issue-@incoming.gitlab.com">
                    E-Mail
                  </Styled.a>
                  .
                </Styled.li>
                <Styled.li>
                  <Styled.strong>Share photos of your work</Styled.strong> with
                  us on
                  <Styled.a
                    href="https://twitter.com/FolioForge"
                    target="_blank"
                  >
                    {' '}
                    Twitter
                  </Styled.a>
                  ,
                  <Styled.a
                    href="https://www.facebook.com/FolioForge"
                    target="_blank"
                  >
                    {' '}
                    Facebook
                  </Styled.a>
                  , or
                  <Styled.a
                    href="https://www.instagram.com/FolioForge"
                    target="_blank"
                  >
                    {' '}
                    Instagram
                  </Styled.a>
                  .
                </Styled.li>
                <Styled.li>
                  Buy me a{' '}
                  <Styled.a
                    href="https://www.buymeacoffee.com/jayliu"
                    target="_blank"
                  >
                    drink. 🍹
                  </Styled.a>
                </Styled.li>
              </Styled.ul>
              <Styled.p>&nbsp;</Styled.p>
              <Styled.p>Thank you for your support!</Styled.p>
              <Styled.p>Forge on ahead! &#128212;</Styled.p>
            </ModalContent>
            <ModalFooter onClose={onClose}></ModalFooter>
          </>
        )}
      </Modal>
    </>
  );
};

export default ContributeDialog;
