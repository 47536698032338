import forms from './forms';
import buttons from './buttons';
import modals from './modals';
import colors from './colors';
import tags from './tags';

const theme = {
  breakpoints: ['28em', '64em', '108em'],
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
  fonts: {
    body: 'Quicksand',
    heading: 'inherit',
  },
  // fontSizes: [12, 14, 16, 20, 24, 32, 48, 64, 72],
  fontWeights: {
    body: 400,
    heading: 700,
    bold: 700,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.25,
  },
  colors,
  text: {
    heading: {
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
      mb: '0.75rem',
      mt: '0.75rem',
    },
    sidebar: {
      header: {
        fontSize: ['16px', '16px', '20px', '20px'],
        fontWeight: 'heading',
        color: 'primary',
        mb: '0.5rem',
      },
    },
    body: {
      fontFamily: 'body',
      fontWeight: 500,
      lineHeight: 'body',
    },
    label: {
      fontSize: '0.9em',
    },
    hint: {
      fontSize: '0.9rem',
      mt: '0.2rem',
      lineHeight: 'normal',
      fontWeight: '400',
    },
  },
  styles: {
    ...tags,
    h1: {
      variant: 'text.heading',
      fontSize: ['30px', '36px', '40px', '44px'],
    },
    h2: {
      variant: 'text.heading',
      fontSize: ['24px', '36px', '36px', '36px'],
    },
    h3: {
      variant: 'text.heading',
      fontSize: ['20px', '24px', '20px', '24px'],
    },
    h4: {
      variant: 'text.heading',
      fontSize: ['20px', '20px', '16px', '20px'],
    },
    h5: {
      variant: 'text.heading',
      fontSize: 1,
    },
    h6: {
      variant: 'text.heading',
      fontSize: 0,
    },
    p: {
      variant: 'text.body',
      mb: '0.5rem',
    },
  },
  printHide: {
    '@media print': {
      display: 'none',
    },
  },
  ...forms,
  ...buttons,
  ...modals,
};

export default theme;
