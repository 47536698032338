import React from 'react';

import styled from 'styled-components';

const StyledSheet = styled.div`
  height: ${(props) => props.height};
  width: ${(props) => props.width};
  display: flex;
  flex-direction: ${(props) => (props.isRotated ? 'row' : 'column')};
  @media screen {
    border: 1px solid lightgray;
  }

  /* Not very well supported. Safari shows no support */
  @media print {
    margin: 0;
    page-break-after: always; /* doesn't seem to work but this may have clues: https://stackoverflow.com/questions/4884380/css-page-break-not-working-in-all-browsers*/
    page-break-inside: avoid;
  }

  /* Not very well supported. Safari shows no support. Fallback to this is above. */
  @page {
    margin: 0;
    page-break-after: always; /* doesn't seem to work but this may have clues: https://stackoverflow.com/questions/4884380/css-page-break-not-working-in-all-browsers*/
    page-break-inside: avoid;
  }
`;

const PageContainer = styled.div`
  padding: ${(props) => props.padding};
  display: flex;
  flex: 1;
`;

const Sheet = ({ children, padding, ...rest }) => (
  <StyledSheet {...rest}>
    {children && Array.isArray(children) ? (
      children
        .filter((x) => !!x)
        .map((page, i) => (
          <PageContainer key={i} padding={padding}>
            {page}
          </PageContainer>
        ))
    ) : (
      <PageContainer padding={padding}>{children}</PageContainer>
    )}
  </StyledSheet>
);

export default Sheet;
