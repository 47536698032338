import React from 'react';
import styled from 'styled-components';

import Paginator from '../Paginator';
import BookDescriptor from '../BookDescriptor';
import Section from '../Section';

const StyledBook = styled.div`
  height: 100%;
  width: 100%;
  @media print {
  }
  @page {
  }
`;

const Book = ({
  pageCount,
  pageHeight,
  pageWidth,
  pageStyle,
  pagesPerSheet,
  pageOrientation,
  bindingEdge,
  bindingMode,
  bookDescriptorObj,
  color,
  opacity,
  flyleaf,
  pageNumberingEnabled,
  sheetsPerSignature,
}) => {
  const paginator = new Paginator(pageCount, {
    pagesPerSheet,
    pageOrientation,
    bindingEdge,
    bindingMode,
    sheetsPerSignature,
    // flyleaf,
  });

  const bookDescriptor = new BookDescriptor(bookDescriptorObj);

  return (
    <StyledBook>
      <Section
        pageHeight={pageHeight}
        pageWidth={pageWidth}
        pageStyle={pageStyle}
        pagesPerSheet={pagesPerSheet}
        pageOrientation={pageOrientation}
        bindingEdge={bindingEdge}
        bindingMode={bindingMode}
        bookDescriptor={bookDescriptor}
        paginator={paginator}
        color={color}
        opacity={opacity}
        flyleaf={flyleaf}
        pageNumberingEnabled={pageNumberingEnabled}
      ></Section>
    </StyledBook>
  );
};

export default Book;
