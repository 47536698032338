/** @jsx jsx */
import { jsx, Styled } from 'theme-ui';
import React from 'react';

import { Label, Radio as R } from 'theme-ui';

const Radio = ({ hint, children, disabled, ...rest }) => (
  <div>
    <Label>
      <R
        {...rest}
        sx={{
          flexGrow: 0,
          opacity: disabled ? 0.5 : 'unset',
        }}
        disabled={disabled}
      />
      <div sx={{ flex: 1 }}>
        <div
          sx={{
            opacity: disabled ? 0.5 : 'unset',
          }}
        >
          {children}
        </div>
        {hint && (
          <aside
            sx={{
              variant: 'text.hint',
            }}
          >
            {hint}
          </aside>
        )}
      </div>
    </Label>
  </div>
);

export default Radio;
