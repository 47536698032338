/** @jsx jsx */
import { jsx, Styled, IconButton, useThemeUI } from 'theme-ui';

import React from 'react';

import Field from '../Input/Field';
import Slider from '../Input/Slider';

import Icon from '../IconFactory';
import { BindingEdge } from '../../../constants';
import { adjustPagesForFlyleaf } from './MiniBookModel';

const MiniBookNav = (props) => {
  const { currentPage, setCurrentPage, bindingEdge, miniBookModel, flyleaf } =
    props;
  const { theme } = useThemeUI();
  const pageNumber = currentPage;
  const isActualPage = miniBookModel.isActualPage(pageNumber);

  const maxPages = miniBookModel.max;
  const step = 2;
  const minPages = miniBookModel.min;

  return (
    <div
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        alignItems: 'center',
      }}
    >
      <div
        sx={{
          display: 'flex',
          width: '100%',
          justifyContent: 'space-around',
        }}
      >
        <div
          sx={{
            display: 'flex',
            mt: '1.5em',
            width: '66%',
            transition: 'transform 400ms',
            transform:
              bindingEdge === BindingEdge.RIGHT
                ? 'rotateY(180deg)'
                : 'rotateY(0)',
          }}
        >
          <IconButton
            style={{ flexGrow: '0' }}
            onClick={() => setCurrentPage(minPages)}
            disabled={currentPage <= minPages}
          >
            <Icon
              name="page-first"
              color={theme.colors.darkGreen}
              size={32}
            ></Icon>
          </IconButton>

          <IconButton
            style={{ flexGrow: '0' }}
            onClick={() => setCurrentPage(currentPage - step)}
            disabled={currentPage <= minPages}
          >
            <Icon
              name="chevron-left"
              color={theme.colors.darkGreen}
              size={32}
            ></Icon>
          </IconButton>
          <div style={{ flex: 'auto' }}>
            <Slider
              title=""
              value={currentPage}
              min={minPages}
              max={maxPages}
              step={step}
              onChange={(event) => setCurrentPage(parseInt(event.target.value))}
            ></Slider>
          </div>
          <IconButton
            style={{ flexGrow: '0' }}
            onClick={() => setCurrentPage(currentPage + step)}
            disabled={currentPage >= maxPages}
          >
            <Icon
              name="chevron-right"
              color={theme.colors.darkGreen}
              size={32}
            ></Icon>
          </IconButton>

          <IconButton
            style={{ flexGrow: '0' }}
            onClick={() => setCurrentPage(maxPages)}
            disabled={currentPage >= maxPages}
          >
            <Icon
              name="page-last"
              color={theme.colors.darkGreen}
              size={32}
            ></Icon>
          </IconButton>
        </div>
      </div>
      <div
        sx={{
          display: 'flex',
          maxWidth: '9rem',
          margin: 'auto',
        }}
      >
        <Field
          value={isActualPage ? (pageNumber === 0 ? 1 : pageNumber) : ''}
          // onChange={(e) => {
          //   translatePageNumber(e.target.value, maxPages, setCurrentPage);
          // }}
          disabled
        ></Field>
        <Field value="/" disabled></Field>
        <Field
          value={`${adjustPagesForFlyleaf(props.pages, flyleaf)}${
            flyleaf ? '*' : ''
          }`}
          disabled
        ></Field>
      </div>
    </div>
  );
};

export default MiniBookNav;

// The following is for if we want to make the current page field editable

// /**
//  * A clumsy way to display the inner and outer covers, flyleafs, and all the pages of the text block etc., while using the same slider.
//  * @param {int} pageNumber the page number the user thinks they want
//  * @returns the page number the rest of the system will understand
//  */
// const translateCurrentPage = (pageNumber, flyleaf) => {
//   let page = pageNumber - 2; // not sure why, just works
//   page += 6; // for front and back covers, 2 each for inner and outer cover
//   if (flyleaf) {
//     page += 2; // 2 each for inner and outer cover, 2 for flyleafs
//   }

//   return page;
// };

// function translatePageNumber(str, maxPages, setCurrentPage) {
//   if (str === '') {
//     return;
//   }

//   let page = parseInt(str);
//   if (!isNaN(page)) {
//     page = translateCurrentPage(page);
//     if (page > maxPages) {
//     }
//   }

//   if (page <= maxPages) {
//     setCurrentPage(page);
//   } else {
//     // maybe do this thing where we're chopping off the significant digit
//     translatePageNumber(str.slice(1), maxPages, setCurrentPage);
//   }
// }
