import React from 'react';
import { BindingModes } from '../../../../constants';

function ThumbnailLetterTallLandscape({
  bindingMode,
  color = '#000',
  colorOpacity = '0.02',
  bindingColor = '#F25546',
  strokeColorOpacity = '0.2',
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="321"
      height="322"
      viewBox="0 0 321 322"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g transform="translate(77.5 128)">
          <path
            fill={color}
            fillOpacity={colorOpacity}
            stroke={color}
            strokeOpacity={strokeColorOpacity}
            strokeWidth="2"
            d="M115 116L51 116 51 -50 115 -50z"
            transform="rotate(90 83 33)"
          ></path>
          <g fill={bindingColor} transform="translate(-7)">
            {bindingMode === BindingModes.SIGNATURE && (
              <g>
                <path d="M7 5.286H14V7.286H7z"></path>
                <path d="M7 44.571H14V46.571H7z"></path>
                <path d="M7 12.357H14V14.357H7z"></path>
                <path d="M7 51.643H14V53.643H7z"></path>
                <path d="M7 19.429H14V21.429H7z"></path>
                <path d="M7 58.714H14V60.714H7z"></path>
              </g>
            )}
            {bindingMode === BindingModes.DISC && (
              <g>
                <rect width="14" height="4" x="0" y="58.5" rx="2"></rect>
                <rect width="14" height="4" x="0" y="47.5" rx="2"></rect>
                <rect width="14" height="4" x="0" y="36.5" rx="2"></rect>
                <rect width="14" height="4" x="0" y="25.5" rx="2"></rect>
                <rect width="14" height="4" x="0" y="14.5" rx="2"></rect>
                <rect width="14" height="4" x="0" y="3.5" rx="2"></rect>
              </g>
            )}

            {bindingMode === BindingModes.JAPANESE && (
              <g>
                <path d="M7 4.5H14V6.5H7z"></path>
                <path d="M7 22.571H14V24.571H7z"></path>
                <path d="M7 40.643H14V42.643H7z"></path>
                <path d="M7 58.714H14V60.714H7z"></path>
                <path d="M12 0H14V66H12z"></path>
              </g>
            )}
            {bindingMode === BindingModes.SADDLE && (
              <g>
                <path d="M6 1.571H9V64.428H6z"></path>
              </g>
            )}
            {bindingMode === BindingModes.SPIRAL && (
              <g>
                <path d="M4 63.429H10V65.429H4z"></path>
                <path d="M4 57.143H10V59.143H4z"></path>
                <path d="M4 50.857H10V52.857H4z"></path>
                <path d="M4 44.571H10V46.571H4z"></path>
                <path d="M4 38.286H10V40.286H4z"></path>
                <path d="M4 32H10V34H4z"></path>
                <path d="M4 25.714H10V27.714H4z"></path>
                <path d="M4 19.429H10V21.429H4z"></path>
                <path d="M4 13.143H10V15.143H4z"></path>
                <path d="M4 6.857H10V8.857H4z"></path>
                <path d="M4 0.571H10V2.5709999999999997H4z"></path>
              </g>
            )}
          </g>
        </g>
      </g>
    </svg>
  );
}

export default ThumbnailLetterTallLandscape;
