/** @jsx jsx */
import { jsx, Styled } from 'theme-ui';
import React from 'react';
import Radio from './Radio';
import { capitalize } from 'humanize-plus';

const RadioGroup = ({
  options,
  onChange,
  name,
  currentValue,
  labels,
  comingSoon,
}) => {
  return (
    <>
      {Object.keys(options).map((option) => (
        <Radio
          key={option}
          name={name}
          value={option}
          checked={currentValue === options[option]}
          onClick={(e) => onChange(options[e.target.value])}
          disabled={comingSoon === options[option]}
        >
          {labels ? labels[options[option]] : capitalize(option, true)}
          {comingSoon === options[option] && ' (Coming Soon!)'}
        </Radio>
      ))}
    </>
  );
};

export default RadioGroup;
