/** @jsx jsx */
import { jsx } from 'theme-ui';
import React, { useState } from 'react';

import Sidebar from './Sidebar';

import PrintPreview from '../PrintPreviewMain';
import MainCanvas from './MainCanvas';
import { showPrintPreviewState } from '../recoil';
import { useRecoilValue } from 'recoil';

const sidebarWidth = 250;

const Main = ({}) => {
  const showPrintPreview = useRecoilValue(showPrintPreviewState);

  return (
    <main
      sx={{
        height: '100%',
        width: '100%',
        display: 'flex',
        '*::-moz-selection': {
          background: 'transparent',
        },
        '*::selection': {
          background: 'transparent',
        },
        '.__react_component_tooltip': {
          fontSize: 16,
          fontWeight: 700,
        },
      }}
    >
      {showPrintPreview ? (
        <div
          sx={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <PrintPreview></PrintPreview>
        </div>
      ) : (
        <MainCanvas sidebarWidth={sidebarWidth}></MainCanvas>
      )}
      <Sidebar width={sidebarWidth}></Sidebar>
    </main>
  );
};

export default Main;
