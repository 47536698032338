/** @jsx jsx */
import { Button, jsx, Styled } from 'theme-ui';
import React from 'react';
import { BindingModes, SheetSizeDimensions } from '../constants';
import { getBasicBookDescriptorObj } from '../utils/book';
import {
  bindingEdgeState,
  bindingModeState,
  colorState,
  cutOrientationState,
  flyleafState,
  opacityState,
  pageNumberingEnabledState,
  pageOrientationState,
  pagesPerSheetState,
  pagesState,
  pageStyleState,
  patternState,
  sheetSizeState,
  sheetsPerSignatureState,
} from './recoil';
import { useRecoilValue } from 'recoil';
import SimpleBook from './core/SimpleBook';

const PrintPreview = ({}) => {
  const pages = useRecoilValue(pagesState);
  const bindingMode = useRecoilValue(bindingModeState);
  const isCustomPattern = false;

  const sheetSize = useRecoilValue(sheetSizeState);
  const pageOrientation = useRecoilValue(pageOrientationState);
  const pagesPerSheet = useRecoilValue(pagesPerSheetState);
  const cutOrientation = useRecoilValue(cutOrientationState);
  const bindingEdge = useRecoilValue(bindingEdgeState);

  const pattern = useRecoilValue(patternState);
  const color = useRecoilValue(colorState);
  const opacity = useRecoilValue(opacityState);

  const pageNumberingEnabled = useRecoilValue(pageNumberingEnabledState);
  const pageStyle = useRecoilValue(pageStyleState);

  const flyleaf = useRecoilValue(flyleafState);

  const sheetsPerSignature = useRecoilValue(sheetsPerSignatureState);

  const noBinding = bindingMode === BindingModes.NONE;

  return (
    <>
      <SimpleBook
        pages={noBinding ? pagesPerSheet : pages}
        pageHeight={`${SheetSizeDimensions[sheetSize].height}${SheetSizeDimensions[sheetSize].units}`}
        pageWidth={`${SheetSizeDimensions[sheetSize].width}${SheetSizeDimensions[sheetSize].units}`}
        pattern={pattern}
        color={color}
        pageStyle={pageStyle}
        pagesPerSheet={pagesPerSheet}
        pageOrientation={pageOrientation}
        bindingEdge={bindingEdge}
        bindingMode={bindingMode}
        flyleaf={noBinding ? false : flyleaf}
        opacity={opacity}
        pageNumberingEnabled={noBinding ? false : pageNumberingEnabled}
        cutOrientation={cutOrientation}
        sheetsPerSignature={sheetsPerSignature}
      ></SimpleBook>
    </>
  );
};

export default PrintPreview;
