/** @jsx jsx */
import { jsx, Button } from 'theme-ui';
import React from 'react';

import { ModalFooter as MF } from '@mattjennings/react-modal';

const ModalFooter = ({ onClose, onCommit, primaryText }) => (
  <MF>
    {onCommit ? (
      <>
        {' '}
        <Button onClick={onClose} variant="secondary">
          Cancel
        </Button>
        <Button onClick={onCommit} variant="primary">
          {primaryText || 'Confirm'}
        </Button>{' '}
      </>
    ) : (
      <Button onClick={onClose} variant="primary">
        {primaryText || 'Close'}
      </Button>
    )}
  </MF>
);

export default ModalFooter;
