import React from 'react';
import { BindingModes } from '../../../../constants';

function ThumbnailA4HalfLandscape({
  bindingMode,
  color = '#000',
  colorOpacity = '0.02',
  bindingColor = '#F25546',
  strokeColorOpacity = '0.2',
}) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 321 322">
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g transform="translate(98 116.5)">
          <path
            fill={color}
            fillOpacity={colorOpacity}
            stroke={color}
            strokeOpacity={strokeColorOpacity}
            strokeWidth="2"
            d="M107 -18L107 107 18 107 18 -18z"
            transform="rotate(90 62.5 44.5)"
          ></path>
          <g fill={bindingColor} transform="translate(-8)">
            {bindingMode === BindingModes.SIGNATURE && (
              <g>
                <path d="M8 7.476H16V9.475999999999999H8z"></path>
                <path d="M8 60.452H16V62.452H8z"></path>
                <path d="M8 17.012H16V19.012H8z"></path>
                <path d="M8 69.988H16V71.988H8z"></path>
                <path d="M8 26.548H16V28.548H8z"></path>
                <path d="M8 79.524H16V81.524H8z"></path>
              </g>
            )}
            {bindingMode === BindingModes.DISC && (
              <g>
                <rect width="16" height="4" x="0" y="79.583" rx="2"></rect>
                <rect width="16" height="4" x="0" y="64.75" rx="2"></rect>
                <rect width="16" height="4" x="0" y="49.917" rx="2"></rect>
                <rect width="16" height="4" x="0" y="35.083" rx="2"></rect>
                <rect width="16" height="4" x="0" y="20.25" rx="2"></rect>
                <rect width="16" height="4" x="0" y="5.417" rx="2"></rect>
              </g>
            )}
            {bindingMode === BindingModes.JAPANESE && (
              <g>
                <path d="M8 6.417H16V8.417H8z"></path>
                <path d="M8 30.786H16V32.786H8z"></path>
                <path d="M8 55.155H16V57.155H8z"></path>
                <path d="M8 79.524H16V81.524H8z"></path>
                <path d="M13.857 0H15.857V89H13.857z"></path>
              </g>
            )}
            {bindingMode === BindingModes.SADDLE && (
              <g>
                <path d="M7.071 2.119H10.071V86.881H7.071z"></path>
              </g>
            )}
            {bindingMode === BindingModes.SPIRAL && (
              <g>
                <path d="M4.571 85.881H11.428V87.881H4.571z"></path>
                <path d="M4.571 77.405H11.428V79.405H4.571z"></path>
                <path d="M4.571 68.929H11.428V70.929H4.571z"></path>
                <path d="M4.571 60.452H11.428V62.452H4.571z"></path>
                <path d="M4.571 51.976H11.428V53.976H4.571z"></path>
                <path d="M4.571 43.5H11.428V45.5H4.571z"></path>
                <path d="M4.571 35.024H11.428V37.024H4.571z"></path>
                <path d="M4.571 26.548H11.428V28.548H4.571z"></path>
                <path d="M4.571 18.071H11.428V20.071H4.571z"></path>
                <path d="M4.571 9.595H11.428V11.595H4.571z"></path>
                <path d="M4.571 1.119H11.428V3.1189999999999998H4.571z"></path>
              </g>
            )}
          </g>
        </g>
      </g>
    </svg>
  );
}

export default ThumbnailA4HalfLandscape;
