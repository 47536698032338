/** @jsx jsx */
import { IconButton, jsx, Styled, useThemeUI } from 'theme-ui';
import React, { useState } from 'react';
import ReactTooltip from 'react-tooltip';

import MiniBook from './MiniBook';

import { BindingModes } from '../../constants';

import { ReactComponent as Logo } from '../images/logo.svg';

import { useRecoilState, useSetRecoilState } from 'recoil';
import {
  bindingEdgeState,
  bindingModeState,
  colorState,
  currentPageState,
  cutOrientationState,
  flyleafState,
  pageOrientationState,
  pagesPerSheetState,
  pagesState,
  pageStyleState,
  patternState,
  selectedTabState,
  sheetSizeState,
  showPrintPreviewState,
  userGreetingState,
  userSuggestTemplateState,
} from '../recoil';
import MiniPageStandalone from './MiniBook/MiniPageStandalone';
import Icon from './IconFactory';
import TemplateDialog from './Dialogs/TemplateDialog';
import ContributeDialog from './Dialogs/ContributeDialog';
import GreetingDialog from './Dialogs/GreetingDialog';

import packageJson from '../../../package.json';
import AboutDialog from './Dialogs/AboutDialog';

const MyIconButton = ({ title, children, color, ...rest }) => {
  const { theme } = useThemeUI();

  return (
    <IconButton {...rest}>
      <a data-tip data-for={title}>
        {children}
      </a>
      <ReactTooltip
        place="right"
        type="dark"
        effect="solid"
        id={title}
        delayShow={200}
        backgroundColor={color || theme.colors.darkGreen}
      >
        {title}
      </ReactTooltip>
    </IconButton>
  );
};

const PrintPreviewMain = ({ sidebarWidth }) => {
  const [pages, setPages] = useRecoilState(pagesState);
  const [currentPage, setCurrentPage] = useRecoilState(currentPageState);

  const [flyleaf, setFlyleaf] = useRecoilState(flyleafState);
  const [bindingMode, setBindingMode] = useRecoilState(bindingModeState);

  const setPattern = useSetRecoilState(patternState);
  const setColor = useSetRecoilState(colorState);
  const setPageStyle = useSetRecoilState(pageStyleState);
  const setPageOrientation = useSetRecoilState(pageOrientationState);
  const setPagesPerSheet = useSetRecoilState(pagesPerSheetState);
  const setBindingEdge = useSetRecoilState(bindingEdgeState);
  const setSheetSize = useSetRecoilState(sheetSizeState);
  const setCutOrientation = useSetRecoilState(cutOrientationState);

  const [showToScale, setShowToScale] = useState(false);

  const [userGreeting, setUserGreeting] = useRecoilState(userGreetingState);
  const [userSuggestTemplate, setUserSuggestTemplate] = useRecoilState(
    userSuggestTemplateState
  );
  const setSelectedTab = useSetRecoilState(selectedTabState);
  const setShowPrintPreview = useSetRecoilState(showPrintPreviewState);

  const setTemplate = (template) => {
    setPages(template.config.pages);
    setColor(template.config.color);
    setPattern(template.config.pattern);
    setFlyleaf(template.config.flyleaf);
    setPageStyle(template.config.pageStyle);
    setPageOrientation(template.config.pageOrientation);
    setPagesPerSheet(template.config.pagesPerSheet);
    setBindingEdge(template.config.bindingEdge);
    setBindingMode(template.config.bindingMode);
    setSheetSize(template.config.sheetSize);
    setCutOrientation(template.config.cutOrientation);
  };

  // const history = useHistory();
  // const openPrintPreview = useCallback(
  //   () => history.push('/print-preview'),
  //   [history]
  // );

  return (
    <div
      sx={{
        flex: 1,
        pr: sidebarWidth,
      }}
    >
      <div>
        <div
          sx={{
            p: '1rem',
            position: 'absolute',
          }}
        >
          <Logo />
          <span
            sx={{
              ml: '1rem',
              color: 'primary',
            }}
          >
            {packageJson.version}
          </span>
        </div>
        <div
          sx={{
            position: 'absolute',
            display: 'flex',
            flexDirection: 'column',
            mt: '6rem',
            width: 54,
            alignItems: 'center',
            button: {
              mb: '1rem',
            },
          }}
        >
          <TemplateDialog onSelectItem={setTemplate} open={userSuggestTemplate}>
            <MyIconButton title="New Project">
              <Icon name="new" color="primary"></Icon>
            </MyIconButton>
          </TemplateDialog>
          {/* <MyIconButton title="Print Preview" onClick={openPrintPreview}>
          <Icon name="print" color="primary"></Icon>
        </MyIconButton> */}
          <ContributeDialog>
            <GreetingDialog
              open={!userGreeting}
              onClose={() => {
                setUserGreeting(true);
                setUserSuggestTemplate(true);
              }}
            >
              {/* <MyIconButton title="Notes!">
                  <Icon name="message" color="primary"></Icon>
                </MyIconButton> */}
            </GreetingDialog>
            <MyIconButton title="Contribute" color="red">
              <Icon name="heart" color="red"></Icon>
            </MyIconButton>
          </ContributeDialog>
          <AboutDialog>
            <MyIconButton title="About Folio Forge">
              <Icon name="about" color="primary"></Icon>
            </MyIconButton>
          </AboutDialog>
        </div>

        {/* <div sx={{
          }}>      <Checkbox checked={showToScale} onChange={(e) => setShowToScale(e.target.value)}>Show to Scale</Checkbox>
          </div> */}
      </div>
      <div
        sx={{
          maxWidth: '30vw',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          height: '100%',
          margin: '0 auto',
        }}
      >
        <div
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          {bindingMode === BindingModes.NONE ? (
            <MiniPageStandalone {...{ showToScale }}></MiniPageStandalone>
          ) : (
            <MiniBook
              {...{
                pages,
                currentPage,
                showToScale,
                flyleaf,
                setCurrentPage,
                setShowToScale,
              }}
            ></MiniBook>
          )}
          <p
            sx={{
              mt: '2rem',
              opacity: 0.5,
              lineHeight: '1.5',
              textAlign: 'center',
            }}
          >
            Note: Page content is NOT shown to scale.
            <br /> Open{' '}
            <button
              sx={{
                variant: 'styles.a',
                color: 'primary',
              }}
              onClick={(e) => {
                // TODO: refactor this code. No magic numbers
                // TODO: should probably establish a selector relationship between showPrintPreview and selectedTab
                if (bindingMode === BindingModes.NONE) {
                  setSelectedTab(2);
                } else {
                  setSelectedTab(4);
                }
                setShowPrintPreview(true);
              }}
            >
              Print Preview Tab
            </button>{' '}
            for a better look.
          </p>
        </div>
      </div>
    </div>
  );
};

export default PrintPreviewMain;
