/** @jsx jsx */
import { jsx, Styled } from 'theme-ui';
import React from 'react';

import SidebarTab from './SidebarTab';
import { InputContainer } from '../Input';
import { PageStyleDescriptions, PageStyles } from '../../../constants';

import Checkbox from '../Input/Checkbox';
import { pageNumberingEnabledState, pageStyleState } from '../../recoil';
import { useRecoilState } from 'recoil';
import RadioGroup from '../Input/RadioGroup';

const PageNumberingTab = (props) => {
  const [pageNumberingEnabled, setPageNumberingEnabled] = useRecoilState(
    pageNumberingEnabledState
  );
  const [pageStyle, setPageStyle] = useRecoilState(pageStyleState);

  return (
    <SidebarTab title="Page Numbering">
      <InputContainer title="">
        <Checkbox
          checked={pageNumberingEnabled}
          onChange={(e) => setPageNumberingEnabled(e.target.checked)}
        >
          Enable Page Numbering
        </Checkbox>
      </InputContainer>{' '}
      {pageNumberingEnabled && (
        <InputContainer title="Page Numbering">
          <RadioGroup
            currentValue={pageStyle}
            options={PageStyles}
            labels={PageStyleDescriptions}
            onChange={(value) => setPageStyle(value)}
          ></RadioGroup>
        </InputContainer>
      )}
    </SidebarTab>
  );
};

export default PageNumberingTab;
