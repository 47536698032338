import { ColorOptions } from '../constants';
import { transparentize } from '@theme-ui/color';

const styles = {
  root: {
    fontFamily: 'body',
    lineHeight: 'body',
    fontWeight: 'body',
    // backgroundColor: ['pink', 'yellow', 'lightgreen', 'lightblue'],
  },
  strong: {
    fontWeight: 700,
    color: 'darkGreen',
  },
  ul: {
    variant: 'text.body',
    '& > li': {
      variant: 'text.body',
      mb: '0.5rem',
      listStyle: 'disc',
      ml: '2rem',
    },
  },
  ol: {
    variant: 'text.body',
    '& > li': {
      variant: 'text.body',
      listStyle: 'decimal',
      mb: '0.5rem',
      ml: '2rem',
    },
  },
  aside: {
    variant: 'text.body',
  },
  pre: {
    fontFamily: 'monospace',
    overflowX: 'auto',
    code: {
      color: 'inherit',
    },
  },
  code: {
    fontFamily: 'monospace',
    fontSize: 'inherit',
  },
  table: {
    variant: 'text.body',
    fontSize: '92%',
    width: '100%',
    borderCollapse: 'collapse',
    borderSpacing: 0,
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: 'muted',
  },
  tr: {
    borderColor: transparentize('black', 0.8),
    borderStyle: 'solid',
    borderWidth: 1,
  },
  th: {
    textAlign: 'left',
    borderBottomStyle: 'solid',
  },
  td: {
    variant: 'text.body',
    textAlign: 'left',
    borderBottomStyle: 'solid',
    px: '0.5rem',
    py: '0.5rem',
    lineHeight: 1.25,
    verticalAlign: 'baseline',
  },
  a: {
    textDecoration: 'none',
    color: 'darkRed',
    fontWeight: 700,
    transition: 'color 400ms',
    cursor: 'pointer',
    '&:hover': {
      color: 'red',
      transition: 'color 400ms',
    },
  },
};

export default styles;
