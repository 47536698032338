/** @jsx jsx */
import { jsx, Styled } from 'theme-ui';
import React from 'react';

import SidebarTab from './SidebarTab';
import { InputContainer } from '../Input';
import Field from '../Input/Field';
import {
  ColorOptions,
  PatternDescriptions,
  DefaultOpacityByColor,
  PatternOptions,
} from '../../../constants';

import { capitalize } from 'humanize-plus';

import Slider from '../Input/Slider';
import PatternTile from './PatternTile';
import Radio from '../Input/Radio';
import Square from './Square';
import PatternDialog from '../Dialogs/PatternDialog';
import { colorState, patternState, opacityState } from '../../recoil';
import { useRecoilState } from 'recoil';

const PatternTab = (props) => {
  const [pattern, setPattern] = useRecoilState(patternState);
  const [color, setColor] = useRecoilState(colorState);
  const [opacity, setOpacity] = useRecoilState(opacityState);

  const selectedItem = {
    pattern,
    color,
  };

  const setSelectedItem = (item) => {
    setPattern(item.pattern);
    setColor(item.color);
  };

  const setOpacityFn = (e) => {
    // https://www.jacklmoore.com/notes/rounding-in-javascript/
    setOpacity(Number(`${Math.round(`${e.target.value / 100}e2`)}e-2`));
  };

  const onChangeColor = (color) => {
    setColor(color);
    setOpacity(DefaultOpacityByColor[color]);
  };

  return (
    <SidebarTab title="Pattern">
      <InputContainer title="Pattern">
        <div
          sx={{
            variant: 'text.label',
            mb: '0.5rem',
          }}
        >
          {PatternDescriptions[pattern]}
        </div>
        <PatternDialog
          selectedItem={selectedItem}
          onSelectItem={(item) => setSelectedItem(item)}
        >
          <Square>
            <PatternTile color={color} pattern={pattern} />
          </Square>
        </PatternDialog>
      </InputContainer>
      {pattern !== PatternOptions.NONE && (
        <InputContainer title="Color">
          {Object.keys(ColorOptions).map((option) => (
            <Radio
              key={option}
              name="color"
              value={option}
              checked={color === ColorOptions[option]}
              onClick={(e) => onChangeColor(ColorOptions[e.target.value])}
            >
              <div
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <div
                  sx={{
                    backgroundColor: (theme) =>
                      theme.colors.pattern[ColorOptions[option]],
                    height: '1rem',
                    width: '1.5rem',
                    borderRadius: 2,
                    mr: '0.5rem',
                  }}
                ></div>{' '}
                {capitalize(option, true)}
              </div>
            </Radio>
          ))}
        </InputContainer>
      )}
      {pattern !== PatternOptions.NONE && (
        <InputContainer title="Opacity">
          <Slider
            value={Math.round(opacity * 100)}
            min={0}
            max={100}
            step={5}
            onChange={setOpacityFn}
          >
            <Field
              value={Math.round(opacity * 100)}
              type="number"
              onChange={setOpacityFn}
            ></Field>
          </Slider>
        </InputContainer>
      )}
    </SidebarTab>
  );
};

export default PatternTab;
