import defaultValues, {
  BindingEdge,
  BindingModes,
  ColorOptions,
  CutOrientation,
  DefaultOpacityByColor,
  PageNumberPosition,
  PageOrientation,
  PagesPerSheet,
  PageStyles,
  PatternOptions,
  SheetSizeOptions,
} from '../../../constants';

export default [
  {
    title: 'Journal',
    description:
      'Signature-bound, grid-patterned journal for writing or planning',
    isNotebook: true,
    config: {
      pages: 160,
      color: ColorOptions.BLACK,
      pattern: PatternOptions.GRID_WRITER,
      flyleaf: true,
      pageStyle: PageStyles.OUTSIDE_TOP,
      pageOrientation: PageOrientation.PORTRAIT,
      pagesPerSheet: PagesPerSheet.TWO,
      bindingEdge: BindingEdge.LEFT,
      bindingMode: BindingModes.SIGNATURE,
      opacity: DefaultOpacityByColor[ColorOptions.BLACK],
      isCustomPattern: false,
      sheetSize: SheetSizeOptions.LETTER,
      cutOrientation: CutOrientation.SHORT,
      pageNumberingEnabled: true,
      pageNumberPosition: PageNumberPosition.OUTSIDE,
    },
  },
  {
    title: 'Sketchbook',
    description: 'For designing interfaces or technical drawing',
    isNotebook: true,
    config: {
      pages: 160,
      color: ColorOptions.BLACK,
      pattern: PatternOptions.GRID_SKETCHER,
      flyleaf: false,
      pageStyle: PageStyles.INSIDE,
      pageOrientation: PageOrientation.PORTRAIT,
      pagesPerSheet: PagesPerSheet.TWO,
      bindingEdge: BindingEdge.LEFT,
      bindingMode: BindingModes.DISC,
      opacity: DefaultOpacityByColor[ColorOptions.BLACK],
      isCustomPattern: false,
      sheetSize: SheetSizeOptions.LETTER,
      cutOrientation: CutOrientation.SHORT,
      pageNumberingEnabled: true,
      pageNumberPosition: PageNumberPosition.OUTSIDE,
    },
  },
  {
    title: 'Patterned Loose Sheets',
    description: 'Versatile, loose sheets',
    isNotebook: false,
    config: {
      pages: 1,
      color: ColorOptions.BLUE,
      pattern: PatternOptions.GRID_ISOMETRIC,
      flyleaf: false,
      pageStyle: PageStyles.OUTSIDE_TOP,
      pageOrientation: PageOrientation.LANDSCAPE,
      pagesPerSheet: PagesPerSheet.ONE,
      bindingEdge: BindingEdge.LEFT,
      bindingMode: BindingModes.NONE,
      opacity: DefaultOpacityByColor[ColorOptions.BLUE],
      isCustomPattern: false,
      sheetSize: SheetSizeOptions.LETTER,
      cutOrientation: CutOrientation.SHORT,
      pageNumberingEnabled: true,
      pageNumberPosition: PageNumberPosition.OUTSIDE,
    },
  },

  {
    title: 'Start from Scratch',
    description: 'Build your notebook from the ground up',
    isNotebook: true,
    config: defaultValues,
  },
];
